import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Grid,  Typography, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import BonusSliderShow from '@layouts/BonusSliderShow';
import { getUrl } from '@utils/ApiAction';
import { storePaths } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
// import { FaChevronDown } from "react-icons/fa";
import BonusTable from '@layouts/BonusTable';
// import moment from 'moment';

import AllButton from '@components/AllButton';
import VscSquareButton from '@components/VscSquareButton';
import DateDisplay from '@components/DateDisplay';

const Bonus = () => {
    const { addAlert, setLoading } = useNotificationLoading();
    const { t } = useTranslation();

    const theme = useTheme();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    const navigate = useNavigate();
    let location = useLocation();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [bonusList, setBonusList] = useState([]);
    const [searchParams] = useSearchParams();
    const [bonus, setBonus] = useState(parseInt(atob(searchParams.get("b"))));
    const [bonusTitle, setBonusTitle] = useState({});
    const [selectedId, setSelectedId] = useState(1);
    const [selectedBonus, setSelectedBonus] = useState([]);
    const [filter, setFilter] = useState('all');
    const [startDate, setStartDate] = useState(null); 
    const [endDate, setEndDate] = useState(null);
    const [searchDate, setSearchDate] = useState(false);

    const filters = [
        {title: t('bonus.option.all'), value: 'all'},
        {title: t('bonus.option.week'), value: 'week'},
        {title: t('bonus.option.month'), value: 'month'},
        {title: t('bonus.option.year'), value: 'year'},
        {title: t('bonus.option.custom'), value: 'custom'},
    ];

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [page, searchParams, bonus, filter]);

    useEffect(() => {
        getUrl(`/member-bonuses`).then(response => {
            if (response.status) {
                setBonusTitle(response.data);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let current_bonus = _.find(bonusTitle, (e)=>{
            return (
                e.id === parseInt(selectedId)
            );
        });
        if(current_bonus){
            setBonus(selectedId);
            setSelectedBonus(current_bonus);
        }
        // eslint-disable-next-line
    }, [selectedId,bonusTitle]);

    useEffect(() => {
        if (filter === 'custom') {
            callApi();
        }
        // eslint-disable-next-line
    }, [searchDate]);

    // const handleScroll = () => {
    //     const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    //     if (bottom ) {
    //         setPage(page + 1);
    //     }
    // }

    const callApi = () => {
            setLoading(true);
            isMountedRef.current = true;
            if (typeof (searchParams.get("b")) === 'string') {
                try {
                    let params = {
                        id: bonus,
                        per_page: 10,
                        page: page,
                        date: filter,//filter
                        start_date: startDate != null ? (startDate).format('YYYY-MM-DD') : null,//startDate
                        end_date: endDate != null ? (endDate).format('YYYY-MM-DD') : null//endDate
                    }
                    getUrl(`/commission-records`, params).then((response) => {
                        setLoading(false);
                        if (response.status && isMountedRef.current) {
                            let { data, current_page, last_page } = response.listing;
                            setTotal(last_page);
                            if (_.size(data) > 0)
                                setCurrPage(current_page);
                            setTotal(last_page);
                            setBonusList(data);
                            // if (page < last_page) { window.addEventListener('scroll', handleScroll); }
                            // if (page > 1) { setBonusList(_.concat(bonusList, data)); }
                            // else { setBonusList(data); }
                        } else {
                            setLoading(false);
                            setBonusList([]);
                            addAlert('', response.message || t('error.contactSupport'), 'error', '');
                        }
                    }).catch((error) => {
                        setLoading(false);
                        addAlert('', error.message || t('error.contactSupport'), 'error', '');
                    });
                } catch (error) {
                    setLoading(false);
                    navigate(`/`);
                }
            } else {
                setLoading(false);
                navigate(`/`);
            }

            // return () => { isMountedRef.current = false; window.removeEventListener('scroll', handleScroll);}
    }

    const handleChange = (event) => {
        setBonus(event.target.value);
        navigate(`/bonus?b=${btoa(event.target.value)}`, { state: { from: storePaths(location) } });
    };

    return (
        <div className='dashboard-container'>
            <div className='flex-sb-m w-full p-b-10'>
                <Typography variant='h6' style={{fontWeight: 'bold', color: theme.palette.lightGray.main}}>
                {t('bonus.select')}                    
                </Typography>
            </div>
            <Grid container spacing={3} className='p-b-30'>
                <BonusSliderShow bonusTitle={bonusTitle} setSelectedId={setSelectedId} selectedId={selectedId}></BonusSliderShow>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} container spacing={2}>
                    <Grid item xs={12} sm={7} md={5}>
                        <Typography variant='h6' className='text-gold-short'>
                            <b>{t(`bonus.history`, { 'type': selectedBonus?.name?.en })}</b>
                        </Typography>
                        {mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                    </Grid>
                    <Grid container item xs={12} sm={5} md={7} className='flex-r-m p-r-20' spacing={3}>
                        {
                            filter == 'custom' &&
                            <Grid item  sx={{ justifyContent:{ xs: 'flex-end'} , display:'flex'}}>
                                <div style={{ padding: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    {!mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                                    <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                                </div>
                            </Grid>
                        }

                        <Grid item sx={{ justifyContent:{ xs: 'flex-end'} , display:'flex' }} style={{ paddingLeft:10}}>
                            <AllButton filters={filters} handleChange={setFilter} />
                        </Grid>
                    </Grid>

                </Grid>
                {
                    bonusList?.length > 0 ? (
                        
                        // bonusList.map((history, key) => (
                            <Grid item xs={12} style={{ padding: '10px 0' }}>
                                <Box>
                                    <BonusTable history={bonusList} total={total} setPageApi={setPage} pageApi={page}/>
                                </Box>
                            </Grid>
                        // ))
                    ) : (
                        <Grid item xs={12} className={styles.endFontStyle} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src="/images/general/filter-search-empty-data.png" alt="Transaction Icon" style={{ marginBottom: '10px', width: 145 }} loading='lazy' />
                            <p className='txt-empty'>
                                {t('table.noTransactionYet')}
                            </p>
                        </Grid>
                    )
                }

                {/* {
                    bonusList?.length > 0 && (
                        <Grid item xs={12} className={styles.endFontStyle}>
                            <Typography style={{ color: '#B5B5B5' }}>
                                {currPage === total ? t('table.endOfTheList') : t('table.loadingList') }
                            </Typography>
                        </Grid>
                    )
                } */}
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    endFontStyle: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
    sliderShow: {
        width: '1000px',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        flexDirection: 'column',
        padding: '0 30px'
    },
    listRoot: {
        backgroundColor: '#fff',
    },
    selectBox: {
        fontSize: 12,
        height: 40,
        boxShadow: 'none',
        textAlign: 'end',
        color: '#F0F0F0',
        fontWeight: 700
    },
}));

export default Bonus;