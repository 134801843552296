import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography, Box, Button, OutlinedInput, MenuItem, InputAdornment, TextField, IconButton, Dialog, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { FiEye, FiEyeOff } from "react-icons/fi";
import { FaChevronDown } from "react-icons/fa";

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { currencyFormat, storePaths } from '@utils/Tools';

import "@css/tree.css";
import Receipt from '@layouts/Receipt';

export default function Payment() {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();

    const [inputErrors, setInputErrors] = useState();
    const [selectedPlan, setSelectedPlan] = useState({});
    const [result, setResult] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [wallets, setWallets] = useState({});
    const [paymentMethods, setPaymentMethods] = useState({});
    const [state, setState] = useState({
        username: '',
        paymentMethod: 0,
        securityPassword: '',
        showSecurityPassword: false,
        // tradeType: 'lock_trade',
    });
    // const tradeList = [{ value: "auto_sell", label: t('plan.autoSell') }, { value: "lock_trade", label: t('plan.lockTrade') }];

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`/active-plan/${id}`).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error, data } = response;

                if (status) {
                    setSelectedPlan(data);
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });

        getUrl(`/payment-methods`).then(response => {
            if (isMountedRef.current) {
                let { status, message, error, data } = response;

                if (status) {
                    setWallets(data.wallets);
                    setPaymentMethods(data.payment_methods);
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                addAlert(JSON.stringify(error.message));
            }
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showSecurityPassword: !state.showSecurityPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = (event) => {
        let { name, value } = event.target;
        setState({ ...state, [name]: value });
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
        navigate('/plan-histories', { state: { from: storePaths(location) } });
    };

    const purchasePlan = () => {
        setLoading(true);
        setInputErrors();
        let apiData = {
            username: state.username,
            package_id: id,
            combination_id: state.paymentMethod,
            security_password: state.securityPassword,
            // auto_sell: state.tradeType === 'auto_sell' ? true : false,
            // lock_trade: state.tradeType === 'lock_trade' ? true : false,
        };
        postUrl(`/purchase-plan`, apiData).then(response => {
            setLoading(false);
            let { status, message, errors, data } = response;
            if (status) {
                setResult(data);
                setDialogOpen(true);
                addAlert('', message || t('success.purchaseSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', message || t('error.purchaseError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }
    let serviceFee = 0;
    let totalAmount = 0;

    if (_.size(selectedPlan?.service_fee) > 0) {
        if (selectedPlan?.service_fee?.['type'] === 'percent') {
            serviceFee = selectedPlan?.price * (parseFloat(selectedPlan?.service_fee?.['amount']) / 100);
        } else {
            serviceFee = parseFloat(selectedPlan?.service_fee?.['amount']);
        }
    }
    totalAmount = parseFloat(selectedPlan?.price) + serviceFee;

    return (
        <>
            <div className='w-full' style={{ borderRadius: '0px 0px 140px 0px', height: 221, background: theme.palette.primary.main, boxShadow: '3px 3px 8px 0px rgba(181, 126, 87, 0.50), -5px -5px 8px 0px rgba(0, 0, 0, 0.15) inset' }}>
                <Typography variant='h6' className='p-t-77 p-lr-16 p-b-12 txt-center' style={{ color: '#fff' }}><b>{t('plan.subscribePlan')}</b></Typography>
            </div>
            <Box className='p-lr-35 p-b-80' style={{ minHeight: '90vh' }}>
                <Box className='p-t-16'>
                    <div className='pos-relative dis-flex flex-m' style={{ top: -100, width: '100%', flexDirection: 'column' }}>
                        <div className='m-b-10' style={{ width: 171, maxHeight: 171, aspectRatio: 1 / 1 }}>
                            <img src={selectedPlan?.plan_logo ? selectedPlan?.plan_logo?.file_name : '/images/logos/logo.png'} className='s-full' alt="Plan Logo" loading='lazy' />
                        </div>

                        <div className='txt-center'>
                            <Typography variant='h5' style={{ color: '#565656' }}><b>{selectedPlan?.name ? selectedPlan?.name?.[i18n.language] : selectedPlan?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</b></Typography>
                            <Typography variant='body1' style={{ color: '#AEAEAE' }}>{_.size(selectedPlan.description) > 0 ? (selectedPlan?.description?.[i18n.language]?.title ? selectedPlan?.description?.[i18n.language]?.title : selectedPlan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.title) : ""}</Typography>
                            <Typography variant='body1' style={{ color: '#AEAEAE' }}>{_.size(selectedPlan.description) > 0 ? (selectedPlan?.description?.[i18n.language]?.subtitle ? selectedPlan?.description?.[i18n.language]?.subtitle : selectedPlan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.subtitle) : ""}</Typography>
                        </div>
                    </div>

                    <Grid className='pos-relative' container style={{ top: -80 }}>
                        <Grid item xs={12}>
                            <TextField
                                sx={{ '& .MuiInputBase-root': { borderRadius: '10px' } }}
                                variant="standard"
                                placeholder={t('user.username')}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                autoComplete="username"
                                name='username'
                                value={state?.username}
                                onChange={handleChange}
                                className={styles.textFieldStyle}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{ '& .MuiInputBase-root': { borderRadius: '10px', padding: "15px 0px 15px 15px" } }}
                                variant="standard"
                                select
                                placeholder={t('plan.paymentMethod')}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                autoComplete="paymentMethod"
                                name='paymentMethod'
                                value={state?.paymentMethod}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.combination_id ? inputErrors.combination_id : ''}
                                error={inputErrors && inputErrors.combination_id ? true : false}
                            >
                                <MenuItem value={0} disabled>{t('general.pleaseSelect')}</MenuItem>
                                {
                                    _.map(paymentMethods, (paymentRules, combinationId) => {
                                        let combinationStr = '';
                                        _.map(paymentRules, (percentage, walletId) => {
                                            let method = _.find(wallets, { 'id': parseInt(walletId) })
                                            combinationStr = combinationStr ? (combinationStr + ' + ') : '';
                                            combinationStr += (method?.name ? method?.name?.[i18n.language] : method?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]) + ` [${percentage}%]`;
                                        })
                                        return (
                                            <MenuItem key={combinationId} value={combinationId}>
                                                {combinationStr}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </Grid>
                        {/* {
                            selectedPlan.extra_setting?.split_times
                                ?
                                <Grid item xs={12}>
                                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <FormControl>
                                            <FormLabel>{t(`plan.tradeType`)}</FormLabel>
                                            <RadioGroup
                                                defaultValue="lock_trade"
                                                name="tradeType"
                                                onChange={handleChange}
                                            >
                                                {
                                                    _.map(tradeList, type => {
                                                        return (
                                                            <FormControlLabel key={type.value} value={type.value} control={<Radio />} label={type.label} />
                                                        )
                                                    })
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                :
                                null
                        } */}
                        <Grid item xs={12}>
                            <TextField
                                sx={{ '& .MuiInputBase-root': { borderRadius: '10px' } }}
                                variant="standard"
                                placeholder={t('user.securityPassword')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle securityPassword visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {state?.showSecurityPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                name='securityPassword'
                                type={state.showSecurityPassword ? 'text' : 'password'}
                                color='primary'
                                value={state.securityPassword}
                                onChange={handleChange}
                                className={styles.textFieldStyle}
                                helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                                error={inputErrors && inputErrors.security_password ? true : false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {paymentMethods[state.paymentMethod] && (
                                <>
                                    {Object.entries(paymentMethods[state.paymentMethod]).map(([walletId, percentage]) => {
                                        const wallet = wallets.find(wallet => wallet.id === parseInt(walletId));
                                        if (!wallet) {
                                            return null;
                                        }
                                        const walletAmount = (selectedPlan.price * percentage / 100).toFixed(2);
                                        return (
                                            <div key={walletId} className='p-b-10 dis-flex flex-m flex-sb-m'>
                                                <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}>
                                                    {wallet.name[i18n.language] || wallet.name[process.env.REACT_APP_DEFAULT_LANGUAGE]}
                                                </Typography>
                                                <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}>
                                                    <b>$ {currencyFormat(walletAmount)}</b>
                                                </Typography>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                            <div className='p-tb-10 dis-flex flex-m flex-sb-m' style={paymentMethods[state.paymentMethod] ? { borderTop: '1px solid ' + theme.palette.gray.main } : null}>
                                <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}>{t('plan.planPrice')}</Typography>
                                <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}><b>$ {currencyFormat(selectedPlan?.price)}</b></Typography>
                            </div>
                            <div className='p-b-10 dis-flex flex-m flex-sb-m'>
                                <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}>{t('plan.serviceFee')}{selectedPlan?.service_fee?.['type'] === 'percent' && `(${selectedPlan?.service_fee?.['amount']}%)`}</Typography>
                                <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}>
                                    <b>$ {_.size(selectedPlan?.service_fee) > 0 ? (currencyFormat((serviceFee).toFixed(2))) : ((0).toFixed(2))}</b>
                                </Typography>
                            </div>
                            <div className='p-tb-10 dis-flex flex-m flex-sb-m' style={{ borderTop: '1px solid ' + theme.palette.gray.main }}>
                                <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}>{t('plan.totalAmount')}</Typography>
                                <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}>
                                    <b>$ {currencyFormat((totalAmount).toFixed(2))}</b>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <div className='w-full flex-c-m' style={{ bottom: 10 }}>
                        <Button fullWidth variant='contained' className='big-button' onClick={purchasePlan}>
                            {t('button.purchaseNow')}
                        </Button>
                    </div>
                </Box>

                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    disableScrollLock={true}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    }}
                >
                    <Box style={{ marginTop: 20, maxWidth: 350, boxShadow:'2px 2px 4px 0px rgba(255, 255, 255, 1) inset, -3px -3px 4px 0px rgba(0, 0, 0, 0.2) inset, 4px 4px 8px 0px rgba(0, 0, 0, 0.3)' }}>
                        <Receipt type={'plan-history'} data={result} handleClose={handleDialogClose} />
                    </Box>
                </Dialog>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    listRoot: {
        backgroundColor: '#fff',
    },
    dropdownIcon: {
        pointerEvents: 'none',
        position: 'absolute',
        right: 0
    },
}));