import _ from 'lodash';
import React, { Fragment, useCallback , useEffect } from "react";
import { useSelector , useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import { removeLoginAccess } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useTranslation, Trans  } from 'react-i18next';
import { getUrl, postUrl } from '@utils/ApiAction';
import { storeProfile } from '@actions';
import ScrollToTop from '@utils/ScrollToTop';
import { useTheme } from '@mui/styles';
import moment from 'moment';

import { Hidden, useMediaQuery } from '@mui/material';

//NOTIFICATON AND LOADING
import NotificationLoadingProvider from "@providers/NotificationLoadingProvider";
import NotificationLoading from "@layouts/NotificationLoading";
import BottomNavigationBar from '@layouts/BottomNavigationBar';
import TitleBar from '@layouts/TitleBar';
import SideBar from '@layouts/SideBar';
import { public_restricted_routes, public_routes, private_routes } from '@configs/Router';

export default function App(props) {
    const theme = useTheme();
    const { accessToken  } = useSelector(state => state.general);
    const { expired_date } = useSelector(state => state.user);
    const { addAlert } = useNotificationLoading();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let location = useLocation();
    let maintenance = location.pathname === '/maintenance' ? true : false;
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    let navigate = useNavigate();
    
    useEffect(() => {
        if (accessToken && !maintenance) {
            getUrl('/user').then(response => {
                if (response.status) {
                    const userData = response.data;
                    dispatch(storeProfile(response.data));
                    if (!userData.name || !userData.mobile || !userData.mobile_country_code) {
                        navigate('/profile', { state: { userData, hideSidebar: true } });
                    }
                } else {
                    addAlert('', t('error.contactSupport'), 'error', '');
                }
            })
        }
        
        // eslint-disable-next-line
    }, [accessToken, maintenance]);

    useEffect(() => {
        const checkTokenExpiration = () => {
            const currentDate = moment();
            const expirationDate = moment(expired_date);
        
            if (expirationDate.isBefore(currentDate)) {
                removeLoginAccess();
                navigate('/login', { state: { from: location } });
            }
        };
    
        checkTokenExpiration();
    
        const interval = setInterval(checkTokenExpiration, 60000);
    
        return () => clearInterval(interval);
    }, [expired_date, dispatch, navigate, location]);

    const renderPublicRoute = useCallback(() => {
        return public_routes.map((route, index) =>
            <Route key={index} element={route.component} path={route.path} />
        )
    }, []);

    const renderAuthRestrictedRoute = useCallback(() => {
        return public_restricted_routes.map((route, index) =>
            <Route key={index} element={accessToken ? <Navigate to="/" replace={true} /> : route.component} path={route.path} />
        )
    }, [accessToken]);

    const renderPrivateRoute = useCallback(() => {
        return private_routes.map((route, index) =>
            <Route key={index} element={accessToken ? route.component : <Navigate to="/login" replace={true} />} path={route.path} />
        )
    }, [accessToken]);

    return (
        <Fragment>
            <NotificationLoadingProvider>
                <ScrollToTop />
                <NotificationLoading />
                <div className='flex-sb'>
                    <Hidden mdDown>
                        {!maintenance ? <SideBar /> : null}
                    </Hidden>
                    <div id="main" className='w-full bg-base' style={{ height: '100vh', overflowY: 'auto', overflowX: 'hidden' }}>
                        {!maintenance ? <TitleBar showTitle={mobileView ? false : true} /> : null}
                        <Routes>
                            {renderPublicRoute()}
                            {renderAuthRestrictedRoute()}
                            {renderPrivateRoute()}
                            <Route path="*" element={accessToken ? <Navigate replace to="/" /> : <Navigate replace to="/login" />} />
                            {/* <Route path="*" element={<NotFound />} /> */}
                        </Routes>
                        <Hidden mdUp>
                            <BottomNavigationBar />
                        </Hidden>
                    </div>
                </div>
            </NotificationLoadingProvider>
        </Fragment>
    );
};