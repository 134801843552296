import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { Box, Button, Grid, IconButton, InputAdornment, Link, TextField, Typography, Hidden } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiEye, FiEyeOff } from "react-icons/fi";

import { authFail, authSuccess } from '@actions';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { storePaths, reducePaths } from '@utils/Tools';

export default function LinkAccToMstore() {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [state, setState] = useState({
        username: '',
        password: '',
        showPassword: false,
    });
    const [inputErrors, setInputErrors] = useState();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    let location = useLocation();

    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const loginUser = (event) => {
        setLoading(true);
        event.preventDefault();
        postUrl('/link-mstore', state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <div className='flex-c-m' style={{ minHeight: '100vh' }}>
                <div className='dashboard-container'>
                    <div className='dashboard-w' style={{ width: isSmDown ? 'auto' : '550px' }}>
                        <Typography className='p-b-20 txt-center' style={{fontSize:'22px', color: theme.palette.gray.main, fontWeight: 700}}>{t('login.linkToMstore')}</Typography>
                        <Box component="form" onSubmit={loginUser}>
                            <TextField
                                variant="standard"
                                placeholder={t('user.mstoreUsername')}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                type="username"
                                autoComplete="username"
                                value={state.username}
                                onChange={({ target }) => setState({ ...state, username: target.value })}
                                className={classes.textFieldStyle}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                            />
                            <TextField
                                variant="standard"
                                placeholder={t('user.mstorePassword')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {state.showPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                type={state.showPassword ? 'text' : 'password'}
                                color='primary'
                                value={state.password}
                                onChange={({ target }) => setState({ ...state, password: target.value })}
                                className={classes.textFieldStyle}
                                helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                error={inputErrors && inputErrors.password ? true : false}
                            />
                            <Button variant="contained" color="primary" type="submit" className='big-button' style={{ marginTop: 20, background: theme.palette.primary.main }} fullWidth>
                                {t('button.link')}
                            </Button>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    contentBox: {
        paddingTop: '75%',
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
    forgotLink: {
        fontSize: 14,
        color: theme.palette.white.main,
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    leftBg: {
        flex: 0.3,
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url('../images/auth/login-red-bg.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    rightBg: {
        flex: 0.7
    },
    imgStyle: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '50%',
        height: '85%',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: -10,
        right: 0,
        objectFit: 'contain'
    },
    loginBox:{
        position:'absolute',
        display: 'block', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
    }
}));