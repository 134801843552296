import QRCode from 'qrcode.react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Box, Button, Typography, Tabs, Tab, Hidden, FormControl, Select, MenuItem } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FaChevronDown } from "react-icons/fa";

import useNotificationLoading from '@utils/useNotificationLoading';

const ReferralQr = () => {
    const shareLink = useRef(null);
    const { t } = useTranslation();
    const { username } = useSelector(state => state.user);
    const { addAlert } = useNotificationLoading();
    const [position, setPosition] = useState('auto');
    const styles = useStyles();
    const theme = useTheme();

    const copyLink = () => {
        // navigator.clipboard.writeText(`${window.location.origin}/register?r=${btoa(username)}&placement=${btoa(username)}&position=${position}`);
        navigator.clipboard.writeText(`${window.location.origin}/register?r=${btoa(username)}`);
        addAlert('', t('general.copied'), 'success', '');
    };

    let positionData = [
        {label: t('general.auto'), value: "auto"},
        {label: t('general.left'), value: "1"},
        {label: t('general.right'), value: "2"},
    ]

    return (
        <div className='dashboard-container'>
            <div className='dashboard-w'>
                <div className='txt-center p-b-30'>
                    <Typography variant='h6' className='text-gold-short' style={{ fontWeight: 'bold', lineHeight: 1.1 }} >{t("title.referralQr")}</Typography>
                </div>
                {/* <Hidden mdDown>
                    <Tabs
                        value={position}
                        onChange={(event, newValue) => setPosition(newValue)}
                        variant="fullWidth"
                        textColor='primary'
                        indicatorColor='primary'
                        style={{ margin: "15px 0px" }}
                    >
                            {
                                _.map(positionData, (detail, key) => {
                                    return (
                                        <Tab key={key} label={detail.label} value={detail.value} />
                                    )
                                })
                            }
                    </Tabs>
                </Hidden> */}
                {/* <Hidden mdUp>
                <div style={{ padding: 0, width: '100%' }}>
                    <FormControl fullWidth className='dis-flex flex-m'>
                        <Select
                            variant='standard'
                            value={position}
                            MenuProps={{ classes: { paper: styles.listRoot } }}
                            onChange={(event) => setPosition(event.target.value)}
                            className={`txt-upper txt-center ${styles.selectBox}`}
                            style={{ background: 'transparent' }}
                            disableUnderline
                            IconComponent={() => (
                                <FaChevronDown className={styles.dropdownIcon} />
                            )}
                        >
                            {
                                _.map(positionData, (detail, key) => (
                                    <MenuItem key={key} value={detail.value} className='txt-upper' style={{ color: theme.palette.primary.main, fontSize: 14 }}>{detail.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
                </Hidden> */}
                <div className='m-t-30'>
                    {/* QRCode */}
                    <Box style={{ marginBottom: 20 }}>
                        <div className='flex-c'>
                            <div>
                                <QRCode
                                    size='178'
                                    value={`${window.location.origin}/register?r=${btoa(username)}`}
                                    // value={`${window.location.origin}/register?r=${btoa(username)}&placement=${btoa(username)}&position=${position}`}
                                    renderAs="svg"
                                    fgColor={'#eec66f'}
                                    bgColor="transparent"
                                />
                            </div>
                        </div>

                    </Box>
                    {/* URlink */}
                    <div className='m-b-20' style={{wordWrap: "break-word" }}>
                        <p ref={shareLink} className='txt-white fs-16 txt-center'>
                            {/* {`${window.location.origin}/register?r=${btoa(username)}&placement=${btoa(username)}&position=${position}`} */}
                            {`${window.location.origin}/register?r=${btoa(username)}`}
                        </p>
                    </div>
                    {/* ShareButton */}
                    <div style={{width:300, maxWidth:'100%', margin:'0 auto'}}>
                        <Button
                            onClick={copyLink}
                            variant="contained"
                            className='big-button'
                            fullWidth
                        >
                            {t("button.copy")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReferralQr

const useStyles = makeStyles(theme => ({
    listRoot: {
        backgroundColor: '#fff',
    },
    selectBox: {
        width: '80%',
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: 14,
        height: 40,
        boxShadow: 'none',
        padding: 0,
    },
    dropdownIcon: {
        pointerEvents: 'none',
        position: 'absolute',
        right: 0
    },
}));