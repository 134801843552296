import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';
import { Button, IconButton, Link, Container, Typography } from '@mui/material';

import { changeLanguage } from '@actions';

export default function ComingSoon() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [serverTime, setServerTime] = useState({
        time: '',
        date: '',
    });
    let location = useLocation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime({
                time: moment().format("HH:mm:ss"),
                date: moment().format("ddd, MMM DD"),
            })
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    return (
        <>
            <div className='flex-c-m flex-col-mobile' style={{ minHeight: '100vh', margin: "0px 50px" }}>
                <div className='p-lr-20' style={{ width: 500, maxWidth: '55vw' }}>
                    <img src='/images/comingSoon/comingSoon3.png' alt='comingSoon' className='w-full' loading='lazy' />
                </div>
                <div className='box-auth' style={{padding:0}}>
                    <div className='clwhite'>
                        <p className='fs-25 lh-10' style={{ fontWeight: 700 }}>{t('comingSoon.title')}</p>
                        <div style={{ fontWeight: 500 }}>
                            <p className='txt-upper fs-15' >{serverTime.date}<span className='p-lr-20'>·</span>{serverTime.time}</p>
                            <p className='p-t-15' style={{ fontWeight: 500 }}>{t('comingSoon.content2')}</p>
                        </div>
                    </div>

                    <div className='p-t-30'>
                        <Link component={RouterLink} to={`/`} className='max-w-full' underline="none">
                            <Button variant="contained" className='big-button max-w-full' style={{ marginTop: 20, width: 250,  }} fullWidth>
                                {t('button.backToHomepage')}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles(theme => ({

}));