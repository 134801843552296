import React, { memo } from 'react';
import { makeStyles , useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Hidden, Typography } from '@mui/material';

import AuthTitleBar from '@layouts/AuthTitleBar';
import RegisterForm from '@layouts/RegisterForm';

const Register = memo(() => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className='flex-c-m' style={{ minHeight: '100vh' }}>
            <AuthTitleBar />
            <div className={`${classes.fieldBox}  gradient-gold-border  p-tb-20`} >
                <div className="box-auth ">
                <Typography className='p-b-20 txt-center text-gold-short' style={{fontSize:'1.2rem'}}>{t('login.registerAcc')}</Typography>
                    <RegisterForm />
                </div>
            </div>
        </div>
    )
});

const useStyles = makeStyles(theme => ({

    fieldBox:{
        display: 'block', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        [theme.breakpoints.down('md')]:{
            left:0,
        },
        [theme.breakpoints.down('sm')]:{
            left:0,
        },
        [theme.breakpoints.down('xs')]:{
            left:0,
        },
    },
    // registerBox:{
    //     width: '70%',
    //     [theme.breakpoints.down('md')]:{
    //         width: '100%',
    //     },
    //     [theme.breakpoints.down('sm')]:{
    //         width: '100%',
    //     },
    //     [theme.breakpoints.down('xs')]:{
    //         width: '100%',
    //     },
    // },
}));

export default Register;