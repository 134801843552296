import {
    STORE_PROFILE,
    RESET_PROFILE,
    STORE_MAXRANK,
} from '../actions/types';

const INITIAL_STATE = {
    id: null,
    username: '',
    name: '',
    email: '',
    mobile_country_code: '',
    mobile: '',
    status: '',
    two_factor_confirmed_at: '',
    profile_image: '',
    // crypto_address: '',
    wallets: [],
    max_rank: null,
    merchant: {},
    disclaimer:true,
    expired_date: '',
    bind: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case STORE_PROFILE:
            return {
                ...state,
                id: actions.payload.id,
                username: actions.payload.username,
                name: actions.payload.name,
                email: actions.payload.email,
                mobile_country_code: actions.payload.mobile_country_code,
                mobile: actions.payload.mobile,
                status: actions.payload.status,
                two_factor_confirmed_at: actions.payload.two_factor_confirmed_at,
                profile_image: actions.payload.profile_image,
                // crypto_address: actions.payload.crypto_address,
                wallets: actions.payload.wallets,
                max_rank: actions.payload.max_rank,
                merchant: actions.payload.merchant,
                disclaimer: actions.payload.checked_disclaimer,
                expired_date: actions.payload.expired_date,
                bind: actions.payload.bind_status,
            };
        case RESET_PROFILE:
            return INITIAL_STATE;
        case STORE_MAXRANK:
            return {
                ...state,
                max_rank: actions.payload,
            };
        default:
            return state;
    }
}