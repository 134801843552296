import { Drawer, List, ListItem, ListItemIcon, Typography, Grid, Box } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import WalletItem from '@components/WalletItem';
import _ from 'lodash';
import { makeStyles, useTheme } from '@mui/styles';
import { storePaths } from '@utils/Tools';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';

const WalletListing = (props) => {
  const { listing } = props;
  const theme = useTheme();
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  let location = useLocation();
  let navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const { permissions } = useSelector((state) => state.general);

  const handleWalletClick = (data) => {
    // Open the drawer and set the selected wallet data
    setDrawerOpen(true);
    setSelectedWallet(data);
  };

  const handleCloseDrawer = () => {
    // Close the drawer
    setDrawerOpen(false);
  };

  const goToLink = (link) => {
    navigate(link, { state: { from: storePaths(location) } })
  };

  const gradients = [
    'linear-gradient(77deg, rgba(88,86,89,1) 0%, rgba(112,111,116,1) 58%, rgba(65,63,68,1) 100%)',
    'linear-gradient(77deg, rgba(88,86,89,1) 0%, rgba(112,111,116,1) 58%, rgba(65,63,68,1) 100%)',
    'linear-gradient(77deg, rgba(88,86,89,1) 0%, rgba(112,111,116,1) 58%, rgba(65,63,68,1) 100%)',
    'linear-gradient(77deg, rgba(88,86,89,1) 0%, rgba(112,111,116,1) 58%, rgba(65,63,68,1) 100%)',
    // 'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box',
    // 'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box',
    // 'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box',
  ];

  // Function to render drawer content
  const renderDrawerContent = (data) => {
    return(
      <div className={styles.drawerContent}>
        <Typography variant='h6' className={styles.drawerTitle}>{t('general.pleaseSelect')}</Typography>
        
        <List>
          {
            data?.setting_transfer?.[data.id]?.[data.id] && !_.includes(permissions, "member-wallet-transfer-disallow") && data?.setting_transfer?.[data.id]?.[data.id]?.allow_others && (
              <React.Fragment>
                <ListItem
                  onClick={() => goToLink(`/transfer?w=${btoa(data?.id)}`)}
                  className={styles.listItem}
                >
                  <ListItemIcon>
                    <img src="/images/wallets/icon_transfer.png" alt="Transfer" className={styles.icon} loading='lazy' />
                  </ListItemIcon>
                  <Typography style={{color: 'black'}}>{t('walletListing.transfer')}</Typography>
                </ListItem>
                <div className={styles.rectangle}></div>
              </React.Fragment>
            )
          }
          {/* {data?.setting_transfer?.[data.id] && !_.includes(permissions, "member-wallet-convert-disallow") && _.find(data?.setting_transfer?.[data.id], function (w, key) { if (key != data.id) { return w.allow_self === true } else { return false } }) && (
            <React.Fragment>
              <ListItem
                onClick={() => goToLink(`/convert?w=${btoa(data?.id)}`)}
                className={styles.listItem}
              >
                <ListItemIcon>
                  <img src="/images/wallets/icon_convert.png" alt="Convert" className={styles.icon} loading='lazy' />
                </ListItemIcon>
                <Typography style={{ color: 'black' }}>{t('walletListing.convert')}</Typography>
              </ListItem>
              <div className={styles.rectangle}></div>
            </React.Fragment>
          )} */}
          {
            data?.setting_withdraw && !_.includes(permissions, "member-wallet-withdraw-disallow") 
              ?
            (
              <React.Fragment>
                <ListItem
                  onClick={() => goToLink(`/merchant-withdraw-wallet?w=${btoa(data?.id)}`)}
                  className={styles.listItem}
                >
                  <ListItemIcon>
                    <img src="/images/wallets/icon_withdrawal.png" alt="Withdrawal" className={styles.icon} loading='lazy' />
                  </ListItemIcon>
                  <Typography style={{color: 'black'}}>{t('walletListing.withdrawal')}</Typography>
                </ListItem>
                  <div className={styles.rectangle}></div>
              </React.Fragment>
            )
            :
            null
          }
          {/* {
            data?.setting_deposit 
            ?
            (
              <React.Fragment>
                      
                  <ListItem
                    onClick={() => goToLink(`/deposit?w=${btoa(data?.id)}`)}
                    className={styles.listItem}
                  >
                    <ListItemIcon>
                      <img src="/images/wallets/icon_deposit.png" alt="Deposit" className={styles.icon} loading='lazy' />
                    </ListItemIcon>
                    <Typography style={{color: 'black'}}>{t('walletListing.deposit')}</Typography>
                  </ListItem>
                  <div className={styles.rectangle}></div>
              </React.Fragment>
            )
            :
            null
          } */}
          <React.Fragment>
            <ListItem
              onClick={() => goToLink(`/merchant-transaction?w=${btoa(data?.id)}`)}
              className={styles.listItem}
            >
              <ListItemIcon>
                <img src="/images/wallets/icon_transaction.png" alt="Transaction" className={styles.icon} loading='lazy' />
              </ListItemIcon>
              <Typography style={{color: 'black'}}>{t('walletListing.transaction')}</Typography>
            </ListItem>
            {/* No rectangle for the last item */}
          </React.Fragment>
        </List>
      </div>
    )
  };  
  
  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      {_.size(listing) > 0 ? (
        _.map(listing, (data, key) => {
          let setBgIndex = key % 4;
          let background = gradients[setBgIndex];
          return (
            <Grid key={key} item xs={12} sm={6} md={6} style={{ width: '100%' }}>
              {isMd ? (
                <div onClick={() => handleWalletClick(data)} key={key} style={{ width: '100%' }}>
                  <WalletItem
                    walletName={data?.name[i18n.language] ? data.name[i18n.language] : data?.name?.cn}
                    walletValue={data?.wallet_balances?.[0]?.balance}
                    walletDecimal={data?.decimal}
                    background={background}
                    walletCode={data?.code}
                    urlLink={`/merchant-transaction?w=${btoa(data?.id)}`}
                  />
                </div>
              ) : (
                <Box className={`pos-relative  btn-animate`} style={{overflow:'hidden', borderRadius: 20}} >
                  <div>
                    <WalletItem
                      walletName={data?.name[i18n.language] ? data.name[i18n.language] : data?.name?.cn}
                      walletValue={data?.wallet_balances?.[0]?.balance}
                      walletDecimal={data?.decimal}
                      background={background}
                      walletCode={data?.code}
                    />
                  </div>
                  <div style={{borderRadius: 15}} className='w-full h-full pos-absolute flex-c-m clwhite menu-animate'>
                    <div className='flex-sa-m w-full '>
                      {data?.setting_transfer?.[data.id]?.[data.id] && data?.setting_transfer?.[data.id]?.[data.id]?.allow_others && !_.includes(permissions, "member-wallet-transfer-disallow") && (
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                          <img src="/images/wallets/icon_transfer.png" alt="Transfer" className={`${styles.icon} btn-icon-round`} onClick={() => goToLink(`/transfer?w=${btoa(data?.id)}`)} />
                          <Typography style={{color: 'white'}}>{t('walletListing.transfer')}</Typography>
                        </div>
                      )}
                      {/* {data?.setting_transfer?.[data.id] && !_.includes(permissions, "member-wallet-convert-disallow") && _.find(data?.setting_transfer?.[data.id], function (w, key){if(key != data.id){return w.allow_self === true}else{ return false}}) && (
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                          <img src="/images/wallets/icon_convert.png" alt="Convert" className={`${styles.icon} btn-icon-round`} onClick={() => goToLink(`/convert?w=${btoa(data?.id)}`)} />
                          <Typography style={{color: 'white'}}>{t('walletListing.convert')}</Typography>
                        </div>
                      )} */}
                      {data?.setting_withdraw &&  !_.includes(permissions, "member-wallet-withdraw-disallow") && (
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                          <img src="/images/wallets/icon_withdrawal.png" alt="Withdrawal" className={`${styles.icon} btn-icon-round`} onClick={() => goToLink(`/merchant-withdraw-wallet?w=${btoa(data?.id)}`)} />
                          <Typography style={{color: 'white'}}>{t('walletListing.withdrawal')}</Typography>
                        </div>
                      )}
                      {/* {data?.setting_deposit && (
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                          <img src="/images/wallets/icon_deposit.png" alt="Deposit" className={`${styles.icon} btn-icon-round`} onClick={() => goToLink(`/deposit?w=${btoa(data?.id)}`)} />
                          <Typography style={{color: 'white'}}>{t('walletListing.deposit')}</Typography>
                        </div>
                      )} */}
                      <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        <img src="/images/wallets/icon_transaction.png" alt="Transaction" className={`${styles.icon} btn-icon-round`} onClick={() => goToLink(`/merchant-transaction?w=${btoa(data?.id)}`)} />
                        <Typography style={{color: 'white'}}>{t('walletListing.transaction')}</Typography>
                      </div>
                    </div>
                  </div>
                </Box>
              )}
            </Grid>
          );
        })
      ) : (
        <Grid item xs={12}>
          <div className='flex-c-m flex-col'>
            <img src={'/images/general/filter-search-empty-data.png'} width={250} alt="No data" loading='lazy' />
            <p className='txt-empty'>{t('general.noWalletData')}</p>
          </div>
        </Grid>
      )}
      {
        mobileView ?
          <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={handleCloseDrawer}
            disableScrollLock={true}
            classes={{ paper: styles.paperAnchorBottom }}
          >
            {selectedWallet && renderDrawerContent(selectedWallet)}
          </Drawer>
          :
          null
      }
      
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  drawerContent: {
    padding: '20px 40px 0 40px',
  },
  drawerTitle: {
    textAlign: 'center',
    marginBottom: '10px',
    color: 'rgba(82, 82, 82, 0.80)'
  },
  rectangle: {
    background: '#F1F1F1',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px #FFF',
    height: '4px',
    margin: '5px 0',
    flexShrink: 0
  },
  icon: {
    cursor: 'pointer',
    padding: 5,
    [theme.breakpoints.down('md')]: {
      width: 40,
      height: 'auto',
      marginRight: 8,
    },
  },
  listItem: {
    cursor: 'pointer',
  },
  paperAnchorBottom: {
    top: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    height: 'auto',
    maxHeight: '100%',
    // maxWidth: 444,
    overflow: 'hidden',
    margin:'0 auto',
    borderRadius:'30px 30px 0px 0px'
  },
}));


export default WalletListing;