import {
    CHANGE_LANGUAGE, RESET_CODE_STATE,AUTH_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    language: process.env.REACT_APP_DEFAULT_LANGUAGE || 'cn',
    accessToken: '',
    permissions: '',
    role: '',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case CHANGE_LANGUAGE:
            return { ...state, language: actions.payload };
        case RESET_CODE_STATE:
            return { ...state,  accessToken: '',permissions:'',role:'' };
        case AUTH_SUCCESS:
            return {
                ...state,
                accessToken: actions.payload.access_token,
                permissions: actions.payload.permissions,
                role: actions.payload.role,
            };
        default:
            return state;
    }
}