import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const DateDisplay = props => {
    const { startDate, endDate } = props;
    const { t, i18n } = useTranslation();

    return (
        <div style={{ display: "flex" }}>
            <Typography>
                {startDate ? (startDate).format('YYYY-MM-DD') : t('trade.startDate')}
            </Typography>
            <Typography style={{ paddingLeft: 20, paddingRight: 20 }}>
                {t('trade.to')}
            </Typography>
            <Typography>
                {endDate ? (endDate).format('YYYY-MM-DD') : t('trade.endDate')}
            </Typography>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    //
}));

export default DateDisplay;