import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Box, Typography, Grid, Dialog, LinearProgress, Button } from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { makeStyles, useTheme } from '@mui/styles';
import useNotificationLoading from '@utils/useNotificationLoading';

import Receipt from '@layouts/Receipt';

const PlanHistoryItem = props => {
    const { history } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [chipSetting, setChipSetting] = useState({
        bgColor: '#006638',
        color: '#fff',
        text: t('transfer.completed'),
    });
    const [isDisabled, setIsDisabled] = useState(history?.redeem_code ? true : false);
    const { addAlert } = useNotificationLoading();

    const decimalPlaces = 2;
    // const serviceFee = parseFloat(history?.details?.service_fee) || 0;

    // const formattedAmount = (parseFloat(history?.price) + serviceFee).toLocaleString(undefined, {
    //     minimumFractionDigits: decimalPlaces,
    //     maximumFractionDigits: decimalPlaces,
    // });

    const formattedAmount = (parseFloat(history?.price)).toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });

    const sign = '';
    let color = '#F3AF2C';
    let text = t('button.pending');
    let barColor = '#CDCDCD';

    switch (history?.status) {
        case 10: color = 'gradient-gold-bg'; text = t('button.pending'); barColor = 'linear-gradient(280deg,#b58947,#ffeaa5,#cea253,#a07a3f)'; break;
        case 20: color = 'gradient-gold-bg'; text = t('button.completed'); barColor = 'linear-gradient(280deg,#b58947,#ffeaa5,#cea253,#a07a3f)'; break;
        case 30: color = 'bg-inactive txt-theme'; text = t('button.cancelled'); barColor = 'linear-gradient(280deg,#b58947,#ffeaa5,#cea253,#a07a3f)'; break;
        case 40: color = 'bg-inactive txt-theme'; text = t('button.expired'); barColor = 'linear-gradient(280deg,#b58947,#ffeaa5,#cea253,#a07a3f)'; break;
        default: break;
    }

    const dateObject = new Date(history?.created_at);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-indexed
    const day = dateObject.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');
    const time = `${hours}:${minutes}:${seconds}`;

    const percent = (parseFloat(history?.profit_limit_balance) / parseFloat(history?.profit_limit)) * 100;
    const mpPercent = (parseFloat(history?.trade_limit_balance) / parseFloat(history?.trade_limit)) * 100;
    const monthNonZero = dateObject.getMonth().toString();

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(`${value}`)
            .then(() => {
                addAlert('', t('success.copySuccess'), 'success', '');
            })
            .catch(err => {
                addAlert('', err?.message || t('error.copyFailed'), 'error', '');
            });
    };

    return (
        <Box style={{ position: 'relative', zIndex: 1 }}>
            <div className={`s-full ${styles.downlineBoxDesign} gradient-gold-border`}
                onClick={() => {
                    if (isDisabled) {
                        return;
                    }
                    setDialogOpen(true);
                }}
            >
                <div className='p-b-20'>
                    <div className={`w-full txt-center p-all-5 ${color}`}
                        style={{
                            width: 300,
                            maxWidth: '60%',
                            margin: '0 auto',
                            boxShadow: '-2px -2px 4px 0px #0000001A inset, 4px 0px 4px 0px #00000026 inset',
                            clipPath: 'polygon(0 0, 100% 0, 90% 100%, 10% 100%)',
                        }}
                    >
                        <p className='txt-upper'>
                            {text}
                        </p>
                    </div>
                    {/* <div style={{ position: 'relative', textAlign: 'center', top: -15 }}>
                        <Typography variant='body2' className='txt-center txt-upper' style={{ position: 'absolute', width: '100%', top: 5 }}>{text}</Typography>
                        <svg width="100%" height="28" viewBox="0 0 208 28" fill="none">
                            <g filter="url(#statusPackage)">
                                <path d="M0.407043 0.795898H207.048L194.465 27.4863H12.9901L0.407043 0.795898Z" fill={color} />
                            </g>
                            <defs>
                                <filter id="statusPackage" x="-1.59296" y="-1.2041" width="210.641" height="28.6904" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="-2" dy="-2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_844_21791" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                                    <feBlend mode="normal" in2="effect1_innerShadow_844_21791" result="effect2_innerShadow_844_21791" />
                                </filter>
                            </defs>
                        </svg>
                    </div> */}
                </div>

                <Grid container spacing={1} className='dis-flex flex-sb-m'>
                    <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant='h4'>{day}</Typography>
                        <Typography className='txt-upper' variant='body1'>{monthNames[monthNonZero]}</Typography>
                        {/* <Typography style={{ color: theme.palette.gray.main }} className='txt-center' variant='body1'>{year}</Typography> */}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography noWrap variant='body1' className='text-gold' style={{ fontWeight: "bold" }}>
                            {history?.plan_name ? history?.plan_name?.[i18n.language] : history?.plan_name?.process.env.REACT_APP_DEFAULT_LANGUAGE}
                        </Typography>
                        <Typography noWrap variant='body2'>TXID: {history.ulid}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'end' }}>
                        <Typography variant='body1' className='text-gold-short' style={{ fontWeight: "bold", textAlign: "right", wordBreak: 'break-all' }}>
                            $ {formattedAmount}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            history.redeem_code ?
                                <>
                                <div className='divider-black m-b-20 m-t-5' style={{height:4}}/>
                                <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                                    <Grid item xs={6}>
                                        <p className='txt-content'
                                            style={{
                                                fontWeight: 'bold',
                                                wordBreak:'break-all'
                                            }}
                                        >
                                            {t('plan.redeemCode')}
                                        </p>
                                        <p className='txt-amount' style={{letterSpacing:0.5}}>{history?.redeem_code?.code}</p>
                                    </Grid>
                                    <Grid item xs={6} style={{ textAlign: 'end' }}>
                                        <Button variant="contained" onClick={()=>copyToClipboard(history?.redeem_code?.code)}>{t('button.copy')}</Button>
                                    </Grid>
                                </Grid>
                                </>
                                :
                                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item xs={12}>
                                        <Typography noWrap style={{ fontSize: 12, }}>{t('receipt.profitLimit')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className='w-full' style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='w-full p-r-10'>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={percent}
                                                    sx={{
                                                        borderRadius: 15, // Rounded ends for the progress bar
                                                        '& .MuiLinearProgress-bar': {
                                                            borderRadius: 15, // Rounded ends for the progress bar's filled part
                                                            background: barColor,
                                                        },
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                        height: 20,
                                                        borderRadius: 15,
                                                        backgroundColor: '#ffffff1c',
                                                        boxShadow:'inset 2px 2px 6px 0 #000000bd, -1px -1px 1px 0 #ffffff1a, 1px 1px 1px 0 #ffffff1c',
                                                        borderWidth: '0 0 2px 0',
                                                        borderColor: '#ffffff1c',
                                                        // boxShadow: '3px 3px 4px 0px #FFFFFFD9, 2px 2px 4px 0px #00000040 inset'
                                                    }}
                                                />
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <p className='fs-12 txt-right txt-theme'>
                                                    {history?.profit_limit_balance} / {history?.profit_limit}
                                                </p>
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography noWrap style={{ fontSize: 12 , marginTop:10 }}>{t('receipt.mpProfitLimit')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className='w-full' style={{ display: 'flex', alignItems: 'center'}}>
                                            <div className='w-full p-r-10'>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={mpPercent}
                                                    sx={{
                                                        borderRadius: 15, // Rounded ends for the progress bar
                                                        '& .MuiLinearProgress-bar': {
                                                            borderRadius: 15, // Rounded ends for the progress bar's filled part
                                                            background: barColor,
                                                        },
                                                        '& .MuiLinearProgress-barColorPrimary': {
                                                            borderRadius: 5,
                                                            background: 'rgb(74,14,166)',
                                                            background: 'linear-gradient(124deg, rgba(74,14,166,1) 0%, rgba(197,62,204,1) 37%, rgba(127,62,204,1) 100%)',
                                                        },
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                        height: 20,
                                                        borderRadius: 15,
                                                        boxShadow:'inset 2px 2px 6px 0 #000000bd, -1px -1px 1px 0 #ffffff1a, 1px 1px 1px 0 #ffffff1c',
                                                        backgroundColor: '#ffffff1c',
                                                        borderWidth: '0 0 2px 0',
                                                        borderColor: '#ffffff1c',
                                                        // boxShadow: '3px 3px 4px 0px #FFFFFFD9, 2px 2px 4px 0px #00000040 inset'
                                                    }}
                                                />
                                            </div>
                                            <div style={{ width: '40%' }}>
                                                <p className='fs-12 txt-right txt-theme'>
                                                    {history?.trade_limit_balance} / {history?.trade_limit}
                                                </p>
                                            </div>
                                        </Box>
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
                    {
                        history?.redeem_code ?
                            null :
                            <Grid item xs={12}>
                                <p className='p-t-10 txt-upper txt-center fs-10 text-gold-short'>{t('planHistory.viewMoreDetails')}</p>
                            </Grid>
                    }
                </Grid>
                <div className='pos-absolute p-all-15 bor-15' style={{ zIndex: -1, bottom: -10, right: 12, width: '95%', height: 50, borderRadius: 8, border: '1px solid #eed189' }} />
            </div>

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                disableScrollLock={true}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
            >
                <Box style={{ maxWidth: 400, overflow: 'hidden' }}>
                    <Receipt type={'plan-history'} data={history} handleClose={handleDialogClose} />
                </Box>
            </Dialog>
        </Box>
    )
}

export default PlanHistoryItem;

const useStyles = makeStyles(theme => ({
    expandDownDesign: {
        color: '#fff',
        borderRadius: 5,
        background: 'linear-gradient(109deg, #B17435 3.41%, #DEA96C 98.09%)',
        filter: 'drop-shadow(2px 2px 2px rgba(255, 255, 255, 0.99))',
    },
    expandUpDesign: {
        color: theme.palette.primary.main,
        borderRadius: 5,
        border: `1px solid ${theme.palette.primary.main}`,
        filter: 'drop-shadow(2px 2px 2px rgba(255, 255, 255, 0.99))'
    },
    downlineBoxDesign: {
        position: 'relative',
        marginBottom: 25,
        padding: '0 15px 20px',
        borderRadius: 15,
        // background: '#eee', 
        // boxShadow: '3px 3px 4px 0px rgba(0, 0, 0, 0.10), -3px -3px 4px 0px #FFF'
    },
    downlineBoxDesign2: {
        position: 'relative',
        marginBottom: 25,
        padding: '15px 30px',
        borderRadius: 15,
        // background: theme.palette.primary.main, 
        // boxShadow: '3px 3px 4px 0px rgba(0, 0, 0, 0.10), -3px -3px 4px 0px #FFF',
    },
}));