import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Grid, Hidden } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PropTypes } from 'prop-types';
import { currencyFormat } from '@utils/Tools';


const TableData = props => {
    const {date, title,  downlineUsername, remark, amount, factor} = props;
    const { t, i18n } = useTranslation();
    const dateObject = new Date(date);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = dateObject.toLocaleString('default', { month: 'short' });
    const theme = useTheme();
    return (
        <Grid container style={{marginTop:10}}>
            <Grid item xs={3} sm={1} className='txt-white' style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <p className='txt-center fs-28 lh-12'>
                    {day}
                </p>
                <p className='txt-center fs-16 txt-upper' style={{letterSpacing: "2px"}}>
                    {month}
                </p>
            </Grid>
            <Grid item xs={9} sm={6}>
                <Typography className='text-gold' style={{ fontWeight:'bold', paddingTop:5}}>
                    {title}
                </Typography>
                {
                    downlineUsername === '' ?
                        null
                        :
                        <p className='txt-content lh-12'>
                            {t(`table.downline`,{'username':downlineUsername})}
                        </p>
                }
                {
                    remark === '' ?
                        null
                        :
                        <p className='txt-content'>
                            {t(`table.remark`,{'remark':remark})}
                        </p>
                }
                <Hidden smUp>
                    <Grid item xs={12} sm={5} >
                        <Typography className='text-gold' style={{ fontWeight: 'bold' }}>
                            {parseInt(factor) === 1 ? null : '-'}$ {currencyFormat(amount, 2)}
                        </Typography>
                    </Grid>
                </Hidden>
            </Grid>
            {/* {
                factor == 1 ?
                    <Grid item xs={3} sm={4}  className='flex-r-m ' style={{paddingRight:20}}>
                        <Typography style={{ textAlign:'end', color: theme.palette.white.main, fontWeight:'bold' }}>
                           $ {Number(amount).toFixed(2)}
                        </Typography>
                    </Grid>
                    :
                    <Grid item xs={3} sm={4} className='flex-r-m' style={{paddingRight:20}}>
                        <Typography style={{ textAlign: 'end', color: theme.palette.white.main, fontWeight:'bold' }}>
                            -$ {Number(amount).toFixed(2)}
                        </Typography>
                    </Grid>
            } */}
            <Hidden only={'xs'}>
            <Grid item xs={6} sm={5} className='flex-r-m' style={{paddingRight:20}}>
                <Typography style={{  fontWeight:'bold' }}>
                    { parseInt(factor) === 1 ? null : '-'}$ {currencyFormat(amount , 2)}
                </Typography>
            </Grid>
            </Hidden>
        </Grid>
        // <div>{data.id}</div>
    )
}


// DEFINE DEFAULT PROPS TYPE
TableData.propTypes = {
    date:PropTypes.string,
    title:PropTypes.string,
    downlineUsername:PropTypes.string,
    remark:PropTypes.string,
    amount:PropTypes.number,
    factor:PropTypes.oneOf([1, -1])
};

// DEFINE DEFAULT PROPS VALUE
TableData.defaultProps = {
    date:'',
    title:'',
    downlineUsername:'',
    remark:'',
    amount:0,
    factor:1
};
export default TableData;