import { Drawer, List, ListItem, ListItemIcon, Typography, Grid, Box, Button } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useRef } from 'react';

import WalletItem from '@components/WalletItem';
import _ from 'lodash';
import { makeStyles, useTheme } from '@mui/styles';
import { storePaths } from '@utils/Tools';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useReactToPrint } from "react-to-print";
import useNotificationLoading from '@utils/useNotificationLoading';

import { QRCodeSVG } from 'qrcode.react';

const QRListing = (props) => {
  const theme = useTheme();
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const [qrDialog, setQrDialog] = useState(false);
  const { addAlert, setLoading } = useNotificationLoading();
  const { merchant } = useSelector(state => state.user);

  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'QR_',
  });
  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_MEMBER_SIDE_URL}spend?m=${btoa(merchant?.id)}`)
      .then(() => {
        addAlert('', t('success.copySuccess'), 'success', '');
      })
      .catch(err => {
        addAlert('', err?.message || t('error.copyFailed'), 'error', '');
      });
  };

  const ComponentToPrint = ({ innerRef }) => {
    return (

      <div style={{ height: '100%', width: "100%", backgroundColor: 'white', paddingTop: '10%', backgroundSize: '100%' }} ref={innerRef}>
        {/* <Box style={{width: "100%", display: "flex", justifyContent: "center",  paddingBottom: 20}}>
                <Typography style={{fontWeight: "bold", fontSize: '50px'}} align="center">Redeem With</Typography>
            </Box> */}

        <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <img alt='logo' src="../logo512.png" style={{ width: "25%" }} />
        </Box>

        <Box style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
          <QRCodeSVG
            size='300'
            style={{ backgroundColor: 'white', padding: 30, border: 'none', borderRadius: '18px', boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.15) inset, 6px 6px 6px 0px rgba(0, 0, 0, 0.11)' }}
            value={`${process.env.REACT_APP_MEMBER_SIDE_URL}spend?m=${btoa(merchant?.id)}`}
          />
        </Box>

        <Box style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: 40 }}>
          <Typography style={{ fontWeight: "bold", fontSize: '55px', color: '#D4A964' }} align="center">{merchant?.name[i18n.language]}</Typography>
        </Box>

        {/* 
            <Box style={{width: "100%", display: "flex", justifyContent: "center",  paddingBottom: 60}}>
                <Typography style={{  fontSize: '21px'}} align="center">{branchInfo.branch_name}</Typography>
            </Box> */}

      </div>

    )
  }

  return (
    <div className='txt-center w-full p-b-30'>
      <div className={`head ${styles.walletBalanceLayout} txt-center p-t-10`}>
        <Box style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: 40, paddingBottom: 20}}>
          <Typography style={{ fontWeight: "bold", fontSize: '35px', color: '#D4A964' }} align="center">{merchant?.name[i18n.language]}</Typography>
        </Box>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <QRCodeSVG
            size='180'
            value={`${process.env.REACT_APP_MEMBER_SIDE_URL}spend?m=${btoa(merchant?.id)}`}
            includeMargin={true}
            style={{borderRadius:20}}
          />
        </div>

        <div style={{ marginTop: 30 }}>
          <Button variant="contained" style={{ marginLeft: 10 }} size='small' onClick={handlePrint}>{t('button.print')}</Button>
          <Button variant="outlined" style={{ marginLeft: 10 }} size='small' onClick={copyToClipboard}>{t('button.copyToClipboard')}</Button>
          <div style={{ display: "none" }}>
            <ComponentToPrint innerRef={componentRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  drawerContent: {
    padding: '20px 40px 0 40px',
  },
  drawerTitle: {
    textAlign: 'center',
    marginBottom: '10px',
    color: 'rgba(82, 82, 82, 0.80)'
  },
  rectangle: {
    background: '#F1F1F1',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px #FFF',
    height: '4px',
    margin: '5px 0',
    flexShrink: 0
  },
  icon: {
    cursor: 'pointer',
    padding: 5,
    [theme.breakpoints.down('md')]: {
      width: 40,
      height: 'auto',
      marginRight: 8,
    },
  },
  listItem: {
    cursor: 'pointer',
  },
  paperAnchorBottom: {
    top: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    height: 'auto',
    maxHeight: '100%',
    // maxWidth: 444,
    overflow: 'hidden',
    margin:'0 auto',
    borderRadius:'30px 30px 0px 0px'
  },
}));


export default QRListing;