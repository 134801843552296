import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { storePaths , currencyFormat } from '@utils/Tools';

const BonusItem = (props) => {
    const { bonusName, bonusCode, bonusValue, bonusDecimal, bonusType, background, labelColor, urlLink } = props;
    const { t } = useTranslation();
    const styles = useStyles();
    let location = useLocation();
    let navigate = useNavigate();

    const getBonusIconPath = () => {
        switch (bonusCode) {
            case 'sponsor-bonus':
                return '/images/bonus_icon/sponsor.png';
            case 'roi-bonus':
                return '/images/bonus_icon/roi.png';
            case 'leadership-bonus':
                return '/images/bonus_icon/leadership.png';
            case 'matching-bonus':
            case 'matching-bonus-2':
                return '/images/bonus_icon/matching.png';
            case 'extra-sponsor-bonus':
                return '/images/bonus_icon/extra-sponsor.png';
            case 'subsidy-bonus':
            case 'extra-subsidy-bonus':
                return '/images/bonus_icon/subsidy.png';
            case 'pairing-bonus':
                return '/images/bonus_icon/pairing.png';
            default:
                return null;
        }
    };

    const handleOnClick = () => {
        if (urlLink) {
            navigate(urlLink, { state: { from: storePaths(location) } });
        }
    };

    return (
        <div onClick={handleOnClick} className={`${styles.bonusItem} gradient-gold-bg pos-relative p-all-2`}
            style={{
                cursor: urlLink ? 'pointer' : 'default',
                minHeight: 150,
                width:'90%',
                margin:'0 auto',
                overflow:'hidden'
                // background: `${background}`,
            }}
        >
            <div className='bg-base pos-relative' style={{borderRadius:'60px 15px'}}>
                {getBonusIconPath() && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                            src={getBonusIconPath()}
                            alt={`Bonus Icon - ${bonusName}`}
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                    </div>
                )}
                <div className={`${styles.bonusBottom} flex-col-tablet-up pos-relative`} style={{gap:5, zIndex:1}}>
                    <div>
                        <Typography variant="h6" className={styles.amount}>
                            <b>$ {currencyFormat(bonusValue ?? 0, bonusDecimal ?? 2)}</b>
                            {/* <b>$ {bonusValue ? parseFloat(bonusValue).toFixed(bonusDecimal) : (0).toFixed(bonusDecimal)}</b> */}
                        </Typography>
                        <Typography className={styles.bonusType}>{bonusType}</Typography>
                    </div>
                    <div className='w-full translateX ' style={{maxWidth:100,}}>
                        <Button variant="contained" fullWidth
                            style={{
                                // width: 70,
                                height: 40,
                                borderRadius: 10,
                                fontWeight: 700,
                                minWidth: 0,
                                minHeight: 0,
                            }}
                            className='gradient-gold-btn'
                        >
                           {t('button.detail')}
                        </Button>
                    </div>
                </div>

               
            </div>
            <div className='pos-absolute bg-blur-brown'></div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    bonusItem: {
        borderRadius: '60px 15px',
        // background: 'linear-gradient(146deg, #82512D 7.7%, #CE8F3C 96.94%)',
        // boxShadow: '3px 3px 4px 0px #FFF, 3px 3px 6px 0px rgba(0, 0, 0, 0.25) inset',
    },
    bonusContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        // background: `url('images/wallets/background_image.png') center center / cover`,
    },
    bonusBottom: {
        padding: '0px 20px 20px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    amount: {
        textAlign: 'left',
        fontSize: 16,
        wordBreak: 'break-all',
        fontWeight:'bold'
    },
    bonusType: {
        textAlign: 'left',
        fontSize: 12,
        textTransform: 'uppercase',
    },
}));

BonusItem.propTypes = {
    Name: PropTypes.string,
    walletLogo: PropTypes.string,
    walletValue: PropTypes.string,
    walletDecimal: PropTypes.number,
    background: PropTypes.string,
    labelColor: PropTypes.string,
};

BonusItem.defaultProps = {
    walletName: '',
    walletLogo: 'images/logos/logo.png',
    walletValue: '0',
    walletDecimal: 0,
    background: 'linear-gradient(109.07deg, #A51616 3.41%, #C33838 98.09%)',
    labelColor: '#ffffff',
};

export default BonusItem;