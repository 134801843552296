import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, TextField } from '@mui/material';

export default function MerchantIntro(props) {
    const { state, inputErrors, handleChange } = props;
    const { t } = useTranslation();

    let text = t('merchant.maxCharacters', { number: '150' });
    const parts = text.split('150');

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextField
                    variant="standard"
                    name='website'
                    placeholder={t('merchant.websiteUrl')}
                    value={state?.website || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.website ? inputErrors.website : <i>{t('merchant.exampleURL', { url: 'https://www.company.com' })}</i>}
                    error={inputErrors && inputErrors.website ? true : false}
                    InputLabelProps={{ shrink: true }}
                    // InputProps={{ disableUnderline: true }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    variant="standard"
                    name='businessNature'
                    placeholder={t('merchant.businessNature') + '*'}
                    value={state?.businessNature || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.business_nature ? inputErrors.business_nature : ''}
                    error={inputErrors && inputErrors.business_nature ? true : false}
                    InputLabelProps={{ shrink: true }}
                    // InputProps={{ disableUnderline: true }}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    variant="standard"
                    name='aboutShop'
                    placeholder={t('merchant.aboutShop') + '*'}
                    value={state?.aboutShop || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.about_shop ? inputErrors.about_shop : <>{parts[0]}<strong>{150}</strong>{parts[1]}</>}
                    error={inputErrors && inputErrors.about_shop ? true : false}
                    InputLabelProps={{ shrink: true }}
                    // InputProps={{ disableUnderline: true }}
                    sx={{
                        "&.MuiTextField-root .MuiInputBase-root": {
                            height: 100,
                            alignItems: 'baseline',
                            overflow: 'scroll',
                        },
                        "&.MuiTextField-root .MuiFormHelperText-root": {
                            textAlign: inputErrors && inputErrors.about_shop ? 'left' : 'right',
                        },
                    }}
                    multiline
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    variant="standard"
                    name='productSell'
                    placeholder={t('merchant.productSell') + '*'}
                    value={state?.productSell || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.product_sell ? inputErrors.product_sell : ''}
                    error={inputErrors && inputErrors.product_sell ? true : false}
                    InputLabelProps={{ shrink: true }}
                    // InputProps={{ disableUnderline: true }}
                    sx={{
                        "&.MuiTextField-root .MuiInputBase-root": {
                            height: 100,
                            alignItems: 'baseline',
                            overflow: 'scroll',

                        }
                    }}
                    multiline
                    required
                    fullWidth
                />
            </Grid>
        </Grid>
    )
}