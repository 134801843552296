import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useTheme } from '@mui/styles';
import { Typography, Grid, Hidden,  useMediaQuery 
} from '@mui/material';
import { currencyFormat, convertToK } from '@utils/Tools';

import CircleIcon from '@mui/icons-material/Circle';
import { ArrowForwardIos } from '@mui/icons-material';

const MerchantTransactionListing = props => {
    const { data , handleClickOpen , dialogShow = false , type } = props;
    
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    let factor = data?.factor !== 1 ? '-' : '+';
    let typeData = '';
    
    if ( type === 'unit') {
        typeData = data.unit;
    }else if ( type === 'lock_value') {
        typeData = data.lock_value;
    }else if ( type === 'cap_limit') {
        typeData = data.cap_limit;
    }

    return (
        <Grid 
            container 
            className={`flex-l-m p-tb-5 ${mobileView ? 'p-lr-5' : 'p-lr-20'} w-full`} 
            style={{ cursor: dialogShow ? 'pointer' : 'auto' }} 
            columnSpacing={mobileView ? 2 : 3} 
            onClick={ () => dialogShow ? handleClickOpen(data) : null }
        >
            <Grid item xs={mobileView ? 3 : 1} className='flex-l-m' style={{ flexDirection: 'column' }}>
                <Typography variant='h5' style={{ lineHeight: 1.0 }}>{moment(data.created_at).format('DD')}</Typography>
                <Typography variant='body2' className='txt-upper'>{moment(data.created_at).format('MMM')}</Typography>
            </Grid>

            <Grid item xs={mobileView ? 6 : dialogShow ? 7 : 8} className='flex-l p-r-10' style={{ flexDirection: 'column' }}>
                <Typography variant='body2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}>
                    <b>
                        {data?.transaction_code_display?.[i18n.language]}
                    </b>
                </Typography>
                {/* <p className='txt-caption' style={{whiteSpace:'nowrap'}}>{t('trade.remark', {remark: data?.details?.remark || '-'})}</p> */}
            </Grid>

            <Grid item xs={3} className='flex-b' style={{ flexDirection: 'column' }}>
                <Typography variant='body2' className='text-gold-short' noWrap>
                    <b>
                        {factor} {typeData}
                    </b>
                </Typography>
            </Grid>
            {
                dialogShow ?
                <Hidden mdDown>
                    <Grid item xs={1} className='flex-c-m'>
                        <ArrowForwardIos style={{ fontSize: 18, color: theme.palette.white.main }} />
                    </Grid>
                </Hidden>
                :
                null
            }
            
        </Grid>
    )
}

export default MerchantTransactionListing;