import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useTheme, makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import theme from '../../Theme';
import { useMediaQuery } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { storePaths } from '@utils/Tools';

export default function MarketPlaceCard(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { marketPrice, mobileDialog = false } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));

    const handleViewAllClick = () => {
        navigate('/market-price', { state: { from: storePaths(location) } });
    };

    return (
        <div 
        className='gradient-gold-border w-full pos-relative bor-15 p-lr-20 p-b-20'>
        {/* style={{  boxShadow: mobileDialog? '2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 4px 4px 8px 0px rgba(0, 0, 0, 0.15)' : ''}} */}
        {/* backgroundColor: mobileDialog? theme.palette.white.main : theme.palette.secondary.main,  */}
            <div className={`${classes.marketPriceTitleContainer} gradient-gold-bg flex-c-m`}>
                <div className="p-lr-20">
                    {/* <div style={{ alignContent: 'center', marginRight: 20, height: 35 }}>
                        <img src='/images/logos/logo-white.png' alt='logo' className='w-full' style={{ objectFit: 'contain', maxHeight: 35, maxWidth: 30 }} loading='lazy' />
                    </div> */}
                    <p className='fs-16 txt-base'>
                        {t('title.marketPlace')}
                    </p>
                    {/* <p className='pointer fs-14 txt-white' onClick={handleViewAllClick} style={{ display: mobileDialog ? 'none' : 'block' }}>
                        {t('button.viewAll')}
                    </p> */}
                </div>
            </div>
            <div className={classes.itemRow}>
                <p className="text-gold-short"><b>{t('trade.remainingUnit')}</b></p>
                <p className="text-gold-short"><b>{t('trade.rate')}</b></p>
            </div>
            {mobileDialog 
                ? <div className="divider-list-black" /> 
                : <div className="divider-list-black" />
            }
            {
                Array.isArray(marketPrice) && _.size(marketPrice) > 0 ?
                    marketPrice.map((item, index) => (
                        <div key={index}>
                            <div className={classes.itemRow}>
                                <Typography  style={{color: mobileDialog ? '#F0F0F0': '#F0F0F0'}}>{item.market_price}</Typography>
                                <Typography  style={{color: mobileDialog ? '#F0F0F0': '#F0F0F0'}}>
                                    {!isNaN(parseFloat(item.rate)) ? parseFloat(item.rate).toFixed(3) : item.rate}
                                </Typography>
                            </div>
                            {
                                !(index === marketPrice.length - 1) && 
                                (
                                    mobileDialog 
                                        ? <div className="divider-list-black" /> 
                                        : <div className="divider-list-black" />
                                )
                            }
                        </div>
                    ))
                :
                (
                    <div className='p-b-10 p-tb-10' >
                        <div className='flex-m w-full flex-col' style={{ fontWeight: 500, }}>
                            <img src="images/general/filter-search-empty-data.png" width="150" alt="No data" />
                            <p className='txt-empty'>{t('general.noDisplay')}</p>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    marketPriceTitleContainer: {
        // position: 'absolute',
        // top: 0,
        // left: '50%',
        // transform: 'translateX(-50%) translateY(-50%)',
        width: '80%', 
        margin: '0 auto',
        padding: '8px 0',
        borderRadius: '0 0 20px 20px',
        marginBottom:20,
        // boxShadow: '0px 4px 5px 0px rgba(209, 60, 60, 0.17)'
    },
    marketPriceTitle: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0',
    },
    itemRow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0',
        alignItems: 'center',
        width:'80%',
        margin:'0 auto'
    },
    itemText: {
        flex: 1,
        textAlign: 'center',
        fontWeight: 500,
    },
    newDividerClass: {
        backgroundColor: theme.palette.white.main,
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px rgba(255, 255, 255, 1)',
        height: 4,
        display: 'inline-block',
        border: 0,
        width: '100%'
    }
}));
