import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';

import {
    Typography, Grid, ThemeProvider, Stack, Pagination, Hidden, createTheme, useMediaQuery, Dialog, DialogContent, Button
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
// import { Balance, Lock, LockOpen, TimeToLeave, Add } from '@mui/icons-material';

import { getUrl } from '@utils/ApiAction';
import { storePaths, currencyFormat, convertToK } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import CircleIcon from '@mui/icons-material/Circle';

import AllButton from '@components/AllButton';
import VscSquareButton from '@components/VscSquareButton';
import DateDisplay from '@components/DateDisplay';
import BonusTokenHistoryItem from '@components/TransactionItems/BonusTokenHistoryItem';

const BonusTokenHistory = () => {
    const { id } = useSelector((state) => state.user);
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    const styles = useStyles();
    const theme = useTheme();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    // let navigate = useNavigate();
    // let location = useLocation();

    const [profile, setProfile] = useState([]);
    const [transaction, setTransaction] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchDate, setSearchDate] = useState(false);

    const [selectedStatus, setSelectedStatus] = useState('');

    const filters = [
        { title: t('transaction.filterOptions.all'), value: '' },
        { title: t('transaction.filterOptions.credit'), value: -1 },
        { title: t('transaction.filterOptions.debit'), value: 1 },
    ];

    const handleClickOpen = (value) => {
        setOpenDialog(true);
        setSelectedTransaction(value);
    };

    const handleClickClose = () => {
        setOpenDialog(false);
    };
    const onChangePage = (page) => {
        setPage(page);
    }
    const handleChangeStatusFilter = value => {
        setSelectedStatus(value);
        setPage(1);
        setTransaction([]);
    };

    // -------- API --------

    useEffect(() => {
        callApi();

        // eslint-disable-next-line
    }, [page, selectedStatus, searchDate]);

    const callApi = () => {
        setLoading(true);

        let apiData = {
            per_page: 10,
            page: page,
            filtering: selectedStatus,
            start_date: startDate !== null ? (startDate).format('YYYY-MM-DD') : null,
            end_date: endDate !== null ? (endDate).format('YYYY-MM-DD') : null
        };

        getUrl(`/get-member-bonus-token-history`, apiData).then((response) => {
            if (response.status) {
                setProfile(response.profile);
                setTransaction(response.transaction.data);
                setTotal(response.transaction.last_page);
            }
            else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        })
            ;
    }

    // -------- layout --------

    const paginationBar = () => {
        return (
            <ThemeProvider theme={paginationStyle} >
                <Stack spacing={2} style={{ padding: '10px 10%' }}>
                    {loading ? null : <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />}
                </Stack>
            </ThemeProvider>
        )
    }

    const dialogDisplay = () => {
        let total = currencyFormat(selectedTransaction?.active_unit + selectedTransaction?.passive_unit, 0);
        return (
            <Dialog open={openDialog} onClose={handleClickClose} className='w-full' >
                <DialogContent style={{ padding: '10px 30px 40px' }}>
                    <div className='flex-r-m'>
                        <Button onClick={handleClickClose} style={{ minWidth: 28, aspectRatio: 1 / 1, padding: 5, borderRadius: 100, }}>
                            <Close />
                        </Button>
                    </div>

                    <div className='flex-col-c-m p-b-10'>
                        <p className='txt-upper txt-center fs-18 text-gold-short' style={{ fontWeight: 700 }}>
                            {t('trade.transactionDetails')}
                        </p>
                    </div>

                    <div className='txt-center p-t-20'>
                        <p className=' txt-center fs-18 txt-white' style={{ fontWeight: 700 }}>
                            {selectedTransaction.transaction_code_display?.[i18n.language] ?? selectedTransaction.transaction_code_display?.['en']}
                        </p>
                    </div>

                    <div className='txt-upper w-full flex-col-c-m '>
                        <p className='text-gold-short' style={{ fontSize: '1.5rem' }}>
                            <b>{selectedTransaction?.factor !== 1 ? '-' : '+'} {total}</b>
                        </p>
                        <p className='text-gold-short' style={{ fontSize: '1.2rem' }}>
                            <b>{t('trade.units')}</b>
                        </p>
                    </div>

                    <Grid container className={`${styles.dialogDetailBoxDesign} gradient-gold-border`}>
                        <Grid item xs={12} sm={4} className='p-t-5'>
                            <Typography variant='body2' style={{ fontWeight: 'bold', lineHeight: 1.2 }}>{t('trade.dateTime')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} className='p-t-5'>
                            <Typography variant='body2' style={{ lineHeight: 1.2 }} className={`txt-right txt-upper`}>
                                <i>{moment(selectedTransaction?.updated_at).format('DD MMM YYYY  ·  HH:mm:ss A')}</i>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} className='p-t-5'>
                            <Typography variant='body2' style={{ fontWeight: 'bold', lineHeight: 1.2 }}>{t('trade.tradingId')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} className='p-t-5'>
                            <Typography variant='body2' style={{ lineHeight: 1.2, wordBreak: 'break-all', }} className={'txt-right'}>{selectedTransaction?.ulid ?? '-'}</Typography>
                        </Grid>
                        {
                            _.includes(["user-spend", "merchant-spend-claim"], selectedTransaction?.code) &&
                            <>
                                <Grid item xs={12} sm={4} className='p-t-5'>
                                    <Typography variant='body2' style={{ fontWeight: 'bold', lineHeight: 1.2 }}>{selectedTransaction?.details?.to ? t('receipt.merchant') : t('receipt.receivedFrom')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} className='p-t-5'>
                                    <Typography variant='body2' style={{ lineHeight: 1.2, wordBreak: 'break-all', }} className={'txt-right'}>{selectedTransaction?.details?.to ? (selectedTransaction?.details?.to[i18n.language] ? selectedTransaction?.details?.to[i18n.language]:selectedTransaction?.details?.to?.en) : selectedTransaction?.details?.from}</Typography>
                                </Grid>
                            </>
                        }
                    </Grid>

                    <div className={`${styles.dialogDetailBoxDesign} gradient-gold-border`}>
                        <Typography variant='body2' style={{ fontWeight: 'bold' }}>{t('receipt.remark')}</Typography>
                        <Typography variant='body2'>{selectedTransaction?.details?.remark || '-'}</Typography>
                    </div>

                    <div className={`${styles.dialogDetailBoxDesign} gradient-gold-border`}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }} className='p-t-5'>
                            <Typography variant='body2' style={{ fontWeight: 'bold' }}>{t('trade.paymentBreakdown')}</Typography>
                            <Typography variant='body2' style={{ fontWeight: 'bold' }}>{selectedTransaction?.factor !== 1 ? '-' : '+'} {total} {t('trade.units')} </Typography>
                        </div>

                        {
                            parseFloat(selectedTransaction?.passive_unit) > 0 &&
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className='p-t-5'>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <div className='passive-circle' />
                                    <Typography style={{ marginLeft: 5 }}> {t('trade.passive')} </Typography>
                                </div>
                                <Typography variant='body2'> {selectedTransaction.passive_unit} {t('trade.units')} </Typography>

                            </div>
                        }

                        {
                            parseFloat(selectedTransaction?.active_unit) > 0 &&
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className='p-t-5'>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <div className='active-circle' />
                                    <Typography style={{ marginLeft: 5 }}> {t('trade.active')} </Typography>
                                </div>
                                <Typography variant='body2'> {selectedTransaction?.active_unit} {t('trade.units')} </Typography>
                            </div>
                        }

                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    // -------- desktop --------

    const desktopSummary = () => {
        let activeUnit = profile?.active_unit ?? 0;
        let passiveUnit = profile?.passive_unit ?? 0;
        return (
            <div
                style={{
                    margin: 'auto',
                    padding: '40px 0',
                    width: '70%',
                }}
            >
                <div className='gradient-gold-border bor-15 p-all-20 txt-white' style={{ boxShadow: '4px 4px 4px 0px #00000040 inset, 4px 4px 4px 0px #FFFFFF0F', }}>
                    <div
                        className='title-bg' style={{ width: '80%', margin: '-45px auto 20px' }}>
                        <p className='text-gold-short txt-center'>{t('trade.bonusTokenSummary')}</p>
                    </div>

                    <div style={{ padding: '10px 20px', overflow: 'auto' }}>
                        <Grid container className={styles.summaryStyle}>

                            <Grid item xs={3} className='summaryContent'>
                                <p style={{ fontWeight: 700, fontSize: 20 }}>{currencyFormat(activeUnit, 0)}</p>
                                <div className='fs-10 gradient-gold-bg p-tb-5 p-lr-10 bor-5' style={{ fontWeight: 400, boxShadow: '3px 3px 4px 0px #CBCBCB0F, 3px 3px 4px 0px #0000007A inset', width: 'fit-content', margin: 'auto' }}>
                                    <p className=' txt-base'>{t('trade.active')} </p>
                                </div>
                            </Grid>

                            <Grid item xs={1} className='summaryContent' > <p className='fs-30'>+</p> </Grid>

                            <Grid
                                item xs={3}
                                className='summaryContent'
                            >
                                <p style={{ fontWeight: 700, fontSize: 20 }}>{currencyFormat(passiveUnit, 0)}</p>
                                <div className=' gradient-gold-border fs-10 p-tb-3 p-lr-8 bor-5' style={{ fontWeight: 400, boxShadow: '3px 3px 4px 0px #CBCBCB0F, 3px 3px 3px 0px #00000040 inset', width: 'fit-content', margin: 'auto' }}>
                                    <p className='text-gold-short'>
                                        {t('trade.passive')}
                                    </p>
                                </div>
                            </Grid>

                            <Grid item xs={1} className='summaryContent'> <p className='fs-30'>=</p> </Grid>

                            <Grid item xs={4} className='summaryContent'>
                                <div className='txt-center text-gold-short'>
                                    <p className='fs-20' style={{ fontWeight: 700 }}>{currencyFormat(activeUnit + passiveUnit, 0)}</p>
                                    <p className='fs-17 txt-white'>{t('general.total')}</p>
                                </div>
                            </Grid>

                        </Grid>
                    </div>
                </div>
                <p className='txt-content p-t-5'>* {t('trade.notIncluded')}</p>
            </div>

        );
    }

    const desktopTransactionListing = () => {
        return (
            <div className='w-full m-t-20 p-all-20 bor-15 gradient-gold-border'>
                {
                    _.size(transaction) > 0 ?
                        _.map(transaction, (data, idx) => {
                            // let factor = data?.factor !== 1 ? '-' : '+';
                            return (
                                <div key={idx}>
                                    <BonusTokenHistoryItem data={data} handleClickOpen={handleClickOpen} dialogShow={true} />

                                    {
                                        idx !== _.size(transaction) - 1 &&
                                        <Grid item xs={12}>
                                            <div className='divider-list-black' />
                                        </Grid>
                                    }
                                </div>
                            )
                        })
                        :
                        <div>
                            <p className='txt-empty'>{t('general.noData')}</p>
                        </div>
                }

                {
                    !mobileView && _.size(transaction) > 0 &&
                    paginationBar()
                }
            </div>
        )
    }

    const desktopFilterBar = () => {
        return (
            <Grid container style={{ display: 'flex', alignItems: mobileView ? 'flex-end' : 'center', marginTop: 20, }}>
                <Grid item xs={4} md={5}>
                    <Typography variant='body1' className='text-gold'><b>{t('trade.bonusTokenHistory')}</b></Typography>
                </Grid>
                <Grid item xs={8} md={7} className='txt-right' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {/* Date Filter */}
                    <DateDisplay startDate={startDate} endDate={endDate} />
                    <div style={{ paddingRight: 10 }}>
                        <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                    </div>
                    <AllButton filters={filters} handleChange={handleChangeStatusFilter} />
                </Grid>
            </Grid>
        )
    }

    // -------- mobile --------

    const mobileFilterBar = () => {
        return (
            <Grid container spacing={2} style={{ display: 'flex', alignItems: mobileView ? 'flex-end' : 'center', marginTop: 20, }}>
                <Grid item xs={12} sm={7}>
                    {/* <Typography variant='body2' style={{ lineHeight: 1.5, color: '#adadad' }}><b>{ t('trade.transaction') }</b></Typography> */}
                    <Typography variant='body2' style={{ lineHeight: 1.5, color: '#adadad' }}><b>{t('trade.bonusTokenHistory')}</b></Typography>
                    <DateDisplay startDate={startDate} endDate={endDate} />
                    {/* <Typography variant='body2' style={{ lineHeight: 1.5, color: '#adadad', paddingRight: 10 }}>
                        {t('trade.dateRange', {start: (startDate)?.format('DD-MM-YYYY') || 'DD-MM-YYYY', end: (endDate)?.format('DD-MM-YYYY') || 'DD-MM-YYYY'})}
                    </Typography> */}
                </Grid>
                <Grid item xs={12} sm={5} className='txt-right' style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ paddingRight: 10 }}>
                        <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                    </div>
                    <AllButton filters={filters} handleChange={handleChangeStatusFilter} />
                </Grid>
            </Grid>
        )
    }

    const mobileSummary = () => {
        let activeUnit = profile?.active_unit ?? 0;
        let passiveUnit = profile?.passive_unit ?? 0;

        return (
            <div className='gradient-gold-border txt-white p-tb-20'
                style={{
                    // backgroundColor: theme.palette.secondary.main,
                    borderRadius: 15,
                    margin: '50px 0 30px',
                }}
            >
                <div
                    className='clwhite title-bg txt-center'
                    style={{
                        width: '80%',
                        margin: '-45px auto 20px',
                        fontWeight: 700,
                    }}
                >
                    {t('trade.bonusTokenSummary')}
                </div>

                <div className='p-lr-15'>
                    <div className='txt-left p-b-15'>
                        <div className='flex-m'>
                            <p className='fs-12 p-r-10'> {t('trade.active')} </p>
                            {/* <CircleIcon style={{ color: theme.palette.primary.main, fontSize: 10, marginLeft: 5, }} /> */}
                            <div className='active-circle' style={{width:12, height:12}} />
                        </div>
                        <div>
                            {/* <p style={{ fontWeight: 700 }}>{convertToK(activeUnit, 0)}</p> */}
                            <p>{currencyFormat(activeUnit, 0)}</p>
                        </div>
                    </div>

                    {/* <p style={{ fontSize: 20 }}>+</p> */}

                    <div className='txt-left p-b-15'>
                        <div className='flex-m'>
                            <p className='fs-12 p-r-10'> {t('trade.passive')} </p>
                            {/* <CircleIcon style={{ color: theme.palette.white.main, fontSize: 10, marginLeft: 5, }} /> */}
                            <div className='passive-circle' style={{width:12, height:12}} />
                        </div>
                        <div>
                        {/* <p style={{ fontWeight: 700 }}>{convertToK(passiveUnit, 0)}</p> */}
                        <p>{currencyFormat(passiveUnit, 0)}</p>
                        </div>
                    </div>

                    {/* <p className={styles.lightGrayText} style={{ fontSize: 30 }}>=</p> */}

                    <div className='txt-left text-gold p-b-10'>
                        <p className='fs-12 p-r-10'> {t('general.total')} </p>
                        <div>
                            {/* <p style={{ fontWeight: 700 }}>{convertToK(activeUnit + passiveUnit, 0)}</p> */}
                            <p>{currencyFormat(activeUnit + passiveUnit, 0)}</p>
                        </div>
                    </div>
                    <p className='txt-caption'>* {t('trade.notIncluded')}</p>
                </div>
                

            </div>
        );
    }

    const mobileTransactionListing = () => {
        return (
            <>
                <div className='w-full bor-15 gradient-gold-border m-t-20 p-tb-20 p-lr-15'>
                    {
                        _.size(transaction) > 0 ?
                            _.map(transaction, (data, idx) => {
                                let factor = data?.factor !== 1 ? '-' : '+';
                                return (
                                    <div key={idx}>
                                        <BonusTokenHistoryItem data={data} handleClickOpen={handleClickOpen} dialogShow={true} />

                                        {
                                            idx !== _.size(transaction) - 1 &&
                                            <Grid item xs={12}>
                                                <div className='divider-list-black' />
                                            </Grid>
                                        }
                                    </div>
                                )
                            })
                            : <Grid container className='flex-l-m p-tb-10'>
                                <p className='txt-empty'>
                                    {t('general.noData')}
                                </p>
                            </Grid>
                    }
                </div>
                {paginationBar()}
            </>

        )
    }

    return (
        <div className='dashboard-container'>
            <Hidden mdDown>
                {desktopSummary()}
                <div className={styles.shadowDisplay} style={{ width: mobileView ? '85%' : '65%', margin: 'auto' }} />
                {desktopFilterBar()}
                {desktopTransactionListing()}
            </Hidden>

            <Hidden mdUp>
                {mobileSummary()}
                <div className={styles.shadowDisplay} style={{ width: mobileView ? '85%' : '65%', margin: 'auto' }} />
                {mobileFilterBar()}
                {mobileTransactionListing()}
            </Hidden>
            {dialogDisplay()}

        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    summaryStyle: {
        '& .summaryContent': {
            textAlign: 'center',
            margin: 'auto',
        }
    },
    lightGrayText: {
        color: theme.palette.lightGray.main,
        fontWeight: 400
    },
    shadowDisplay: {
        height: 6,
        borderRadius: '50%',
        margin: '30px 0px',
        background: 'radial-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.01))',
        boxShadow: '0px 0px 10px 4px #00000078',
    },
    dialogDetailBoxDesign: {
        // boxShadow: '3px 3px 4px 0px #FFFFFF, 3px 3px 2px 0px #0000001A inset',
        borderRadius: 20,
        padding: '20px',
        marginTop: 20,
    }
}));

const paginationStyle = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                ul: {
                    justifyContent: 'space-around',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#f0f0f0',
                },
            },
        }
    }
});

export default BonusTokenHistory;