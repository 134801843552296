import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

import { Hidden, Typography, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { ArrowForwardIos } from '@mui/icons-material';

import { currencyFormat } from '@utils/Tools';

const RedeemTransactionItem = props => {
    const { data, mobileView, factor, currentPrice, displayOnly = false } = props;
    const { t, i18n } = useTranslation();

    const theme = useTheme();

    return (
        <>
            <Grid container className={`flex-l-m p-tb-5 ${mobileView ? 'p-lr-5' : 'p-lr-20'} w-full`} style={{ cursor: displayOnly ? 'pointer' : 'auto' }} columnSpacing={mobileView ? 2 : 3}>
                <Grid item xs={mobileView ? 3 : 1} className='flex-l-m' style={{ flexDirection: 'column' }}>
                    <Typography variant='h5' style={{ lineHeight: 1.0 }}>{moment(data.updated_at).format('DD')}</Typography>
                    <Typography variant='body2' className='txt-upper'>{moment(data.updated_at).format('MMM')}</Typography>
                </Grid>

                <Grid item xs={mobileView ? 6 : displayOnly ? 7 : 8} className='flex-l p-r-10' style={{ flexDirection: 'column' }}>
                    <Typography variant='body2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}>
                        <b>{data?.transaction_code_display?.[i18n.language]}</b>
                    </Typography>
                    <Typography variant='caption' style={{ color: theme.palette.darkGray.main, lineHeight: 1.2 }} noWrap>{t('trade.remark', { remark: data?.details?.remark || '-' })}</Typography>
                </Grid>

                <Grid item xs={3} className='flex-b' style={{ flexDirection: 'column' }}>
                    <Typography variant='body2' noWrap><b>{factor} ${currencyFormat(parseFloat(data?.redeemable_value), 2)}</b></Typography>
                    <Typography variant='caption' style={{ color: theme.palette.darkGray.main, lineHeight: 1.0 }} noWrap>{factor} {Math.round(parseFloat(data?.redeemable_value) / currentPrice) || 0} {t('trade.unit')}</Typography>
                </Grid>

                <Hidden mdDown>
                    {
                        displayOnly &&
                        <Grid item xs={1} className='flex-c-m'>
                            <ArrowForwardIos style={{ fontSize: 18, color: theme.palette.white.main }} />
                        </Grid>
                    }
                </Hidden>
            </Grid>
        </>
    )
}

export default RedeemTransactionItem;