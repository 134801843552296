import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import 'swiper/css/pagination';
// import { Pagination } from 'swiper/modules';
// import styles bundle
import 'swiper/css/bundle';
// import Swiper and modules styles
import 'swiper/css';
// import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Grid } from '@mui/material';
import _ from 'lodash';
import BonusCard from '../components/BonusCard';
import { PropTypes } from 'prop-types';
import TableData from '@components/TableData';
import { Pagination } from "@mui/material";

const BonusTable = (props) => {
    const { history, total, pageApi, setPageApi} = props;
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    //pageApi
    return (
        <Grid container style={{borderRadius:8, padding:"10px 15px 15px"}} className='box-in'>
            {_.map(history, (data, key) => {
                if(history.length === key + 1){
                    return (
                        <Grid item xs={12} key={key}>
                            <TableData
                                data={data}
                                date ={data?.bonus_date}
                                title ={data?.bonus?.name ? data?.bonus?.name?.[i18n.language] : data?.bonus?.name?.process.env.REACT_APP_DEFAULT_LANGUAGE}
                                downlineUsername ={data.downline_username? data.downline_username: ''}
                                amount = {parseFloat(data.amount)}
                                factor = {1}
                            />
                        </Grid>
                    );
                }else{
                    return (
                        <Grid item xs={12} key={key}>
                            <Grid item xs={12} key={key}>
                                <TableData
                                    data={data}
                                    date ={data?.bonus_date}
                                    title ={data?.bonus?.name ? data?.bonus?.name?.[i18n.language] : data?.bonus?.name?.process.env.REACT_APP_DEFAULT_LANGUAGE}
                                    downlineUsername ={data.downline_username? data.downline_username: ''}
                                    amount = {parseFloat(data.amount)}
                                    factor = {1}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Rectangle Bottom */}
                                <div className='s-full m-t-10' style={{ height: 4, background: '#1E1E1E', boxShadow: '0px 1px 2px 0px #000000DB inset, 0px 2px 3px 0px #FFFFFF12' }}></div>
                            </Grid>
                        </Grid>
                    );
                }
                
            })}
            <Grid item xs={12} className='flex-c' style={{marginTop:10}}> 
                <Pagination classes={{ul: classes.ul}} count={total} onChange={(e, value) => setPageApi(value)} showFirstButton showLastButton size="large"/>
            </Grid>
        </Grid>
       
    );
};

const useStyles = makeStyles(() => ({
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#ffffffde"
        }
    }
}));

// DEFINE DEFAULT PROPS TYPE
BonusTable.propTypes = {
    history:PropTypes.array,
    total:PropTypes.number
};

// DEFINE DEFAULT PROPS VALUE
BonusTable.defaultProps = {
    history: [],
    total:0,
};
export default BonusTable;