import moment from 'moment-timezone';
import _ from 'lodash';
// import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';

// import { Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {
    Box, Grid, Typography, ThemeProvider, Pagination, Stack, createTheme, DialogTitle, IconButton, DialogContent, Hidden, Button, Dialog, useMediaQuery
} from '@mui/material';
import { storePaths, tradingAmountFormat } from '@utils/Tools';

import { makeStyles, useTheme, styled } from '@mui/styles';

import { Close, ArrowForwardIos } from '@mui/icons-material';
import theme from '../../Theme';

import VscSquareButton from '@components/VscSquareButton';
import DateDisplay from '@components/DateDisplay';

export default function History() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const { addAlert, setLoading, loading } = useNotificationLoading();
    let location = useLocation();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const [data, setData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);

    let navigate = useNavigate();

    // Date
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchDate, setSearchDate] = useState(false);

    //Dialog
    const [dialogInfo, setDialogInfo] = useState({
        open: false,
        info: null,
    })

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } });
    };

    const onChangePage = (page) => {
        setPage(page);
    }

    useEffect(() => {
        debouncedCallApi();

        // eslint-disable-next-line
    }, [page, searchDate]);

    const debouncedCallApi = _.debounce(() => {
        callApi();
    }, 1000);

    const callApi = () => {
        setLoading(true);
        try {
            let apiData = {
                // status: selectedStatus,
                per_page: 10,
                page: page,
                start_date: startDate ? startDate.format('YYYY-MM-DD') : null,
                end_date: endDate ? endDate.format('YYYY-MM-DD') : null
            };

            getUrl(`/report-member-spend-list`, apiData).then(response => {
                setLoading(false);
                if (response.status) {
                    let { data, current_page, last_page } = response.data;
                    if (_.size(data) > 0)
                        setCurrPage(current_page);
                    setTotal(last_page);
                    setData(data);

                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });

        } catch (error) {
            setLoading(false);
            navigate(`/`);
        }
    }

    // const handleChangeStatusFilter = (event) => {
    //     setSelectedStatus(event.target.value);
    //     setPage(1);
    //     setData([]);
    // };

    const closeDialog = () => {
        setDialogInfo({ ...dialogInfo, info: null, open: false });
    }

    const openDialog = (data) => {
        setDialogInfo({ ...dialogInfo, info: data, open: true });
    }

    // const filteredData = data.filter(item => {
    //     if (selectedStatus === 'all') {
    //         return true;
    //     } else {
    //         return item.status === parseInt(selectedStatus);
    //     }
    // });

    return (
        <div>
            <CustomDiv className='dashboard-container'>
                <Grid
                    container
                    spacing={3}
                    justify="center"
                    // alignItems="center"
                    alignItems="stretch"
                    style={{
                        width: '100%'
                    }}
                    sx={{ marginTop: { xs: 5, sm: 5, md: 0, lg: 0 } }}
                >
                    <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'space-between' }} container >
                        <Grid item xs={12} sm={7} md={5}>
                            <Typography variant='h6' style={{ fontWeight: 'bold', color: theme.palette.white.main }}>
                                {t('title.spendHistory')}
                            </Typography>
                            {mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                        </Grid>
                        <Grid container item xs={12} sm={5} md={7} className='flex-r-m' spacing={3}>
                            {/* DatePicker for start date and end date */}
                            <Grid item sx={{ justifyContent: { xs: 'flex-end' }, display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                {!mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                                <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ background: theme.palette.secondary.main, borderRadius: 15, marginLeft: 30, paddingBottom: 20, marginTop: 10 }}>
                        {
                            loading ?
                                null :
                                _.size(data) > 0 ?
                                    <>
                                        {
                                            _.map(data, (item, key) => {
                                                const dateObject = new Date(item?.created_at)
                                                const day = dateObject.getDate().toString().padStart(2, '0');
                                                const month = dateObject.toLocaleString('default', { month: 'short' });

                                                return (
                                                    <div className='p-b-15' key={key} onClick={() => openDialog(item)}>
                                                        <Box className='max-w-full w-full p-r-15' underline="none" style={{ cursor: 'pointer' }}>
                                                            {
                                                                key !== 0 && <div className='divider-black p-lr-15' style={{ height: 3 }} />
                                                            }
                                                            <div className='flex-sb-m p-b-10  bor-10 p-tb-10 p-lr-15' style={{ color: theme.palette.white.main }}>
                                                                <div className='flex-m'>
                                                                    <div style={{ flexDirection: 'column', marginRight: 30 }} className='flex-c-m'>
                                                                        <Typography style={{ textAlign: 'center', fontSize: 28, color: theme.palette.white.main, lineHeight: "1.2" }}>
                                                                            {day}
                                                                        </Typography>
                                                                        <Typography style={{ textAlign: 'center', fontSize: 16, color: theme.palette.white.main, textTransform: "uppercase", letterSpacing: "2px" }}>
                                                                            {month}
                                                                        </Typography>
                                                                    </div>
                                                                    <div className='p-l-15 flex-l-m'>
                                                                        <Hidden mdDown>
                                                                            <div style={{ width: 60  , marginRight:20}}>
                                                                                <img src={'/images/logos/logo.png'} alt="plan_logo" loading='lazy' style={{ aspectRatio: 1 / 1, objectFit: 'contain' }} />
                                                                            </div>
                                                                        </Hidden>
                                                                        <div className='p-r-20' >
                                                                            <Typography variant="h6" className="text-gold-short" style={{ fontWeight: 'bold' , wordBreak:'break-all' }} >{( item?.details?.to && item?.details?.to[i18n.language] ? item?.details?.to[i18n.language]: item?.details?.to?.en ) || '-'}</Typography>
                                                                            <Typography variant="subtitle2" style={{ fontStyle: 'italic', color: theme.palette.white.main , wordBreak:'break-all' }} >{` ${t('spend.transactionId')}:${item?.trade_transaction?.ulid}`}</Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                    <div className='flex-c-m' >
                                                                        <p style={{ fontWeight: 700 }}>{`-$${tradingAmountFormat(parseFloat(item?.redeemable_value).toFixed(2)) || 0}`}</p>
                                                                        <ArrowForwardIos style={{ fontSize: 'x-large', color: theme.palette.white.main , paddingLeft:10 }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                                        <div className="w-full" style={{ textAlign: 'center' }}>
                                            <img src={'/images/general/filter-search-empty-data.png'} width={250} alt="No data" loading='lazy' />
                                        </div>
                                        <p className='txt-center p-t-20 p-b-30' style={{ fontWeight: 500, color: '#a0a0a0', textTransform: 'uppercase' }}>{t('table.noRecord')}</p>
                                    </div>
                        }
                        <ThemeProvider theme={paginationStyle}>
                            <Stack spacing={2} style={{ padding: '10px 0px' }}>
                                {
                                    _.size(data) > 0 && !loading &&
                                    <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />
                                }
                            </Stack>
                        </ThemeProvider>
                    </Grid>
                </Grid>
                {/* Transation Detail */}
                <Dialog
                    open={dialogInfo.open}
                    onClose={closeDialog}
                    fullWidth
                    PaperProps={{
                        style: {
                            gap: 0,
                            padding: 10
                        },
                    }}
                >
                    <DialogTitle className='w-full' >
                        <div className='flex-r-m'>
                            <Button onClick={closeDialog} style={{ minWidth: 26, aspectRatio: 1 / 1, padding: 5, borderRadius: 100, }}>
                                <Close />
                            </Button>
                        </div>
                    </DialogTitle>
                    <DialogContent className='w-full'>
                        <div className='flex-c-m' style={{ height: '100%', width: '100%', position: 'relative' }}>
                            <div style={{ zIndex: 1 }} >
                                <div className='flex-c-m'>
                                    <Typography className="text-gold-short" style={{ textTransform: 'uppercase', fontWeight: 'bold' }} variant="body1" >{t('spend.transactionDetail')}</Typography>
                                </div>
                                <div className='flex-c-m m-t-10' style={{ flexDirection: 'column' }}>
                                    <div style={{  width:80 , height:80 }}>
                                        <img src={'/images/logos/logo.png'} alt="merchant_logo" loading='lazy' style={{ aspectRatio: 1 / 1, objectFit: 'contain' }} />
                                    </div>
                                    <Typography variant="h6" style={{ fontWeight: 'bold', color: theme.palette.white.main , wordBreak:'break-all' , textAlign:'center' , margin:'0 auto' , width:'50%' }} >{(dialogInfo?.info?.details?.to && dialogInfo?.info?.details?.to[i18n.language] ? dialogInfo?.info?.details?.to[i18n.language] : dialogInfo?.info?.details?.to?.en) || '-'}</Typography>
                                    <Typography variant="subtitle2" style={{ fontStyle: 'italic', color: theme.palette.white.main }} >{dialogInfo?.info?.merchant_email}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="box-out" style={{ borderRadius: 20, padding: 5, marginBottom: 20, marginTop: 10 }}>
                            <div className={`p-all-10 ${classes.columnStyle}`}>
                                <Typography style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{t('trade.dateTime')}</Typography>
                                <Typography style={{ color: theme.palette.white.main }}>{dialogInfo?.info?.created_at}</Typography>
                            </div>
                            <div className={`p-all-10 ${classes.columnStyle}`}>
                                <Typography style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{t('trade.tradingId')}</Typography>
                                <Typography style={{ color: theme.palette.white.main , wordBreak:'break-all' }}>{dialogInfo?.info?.trade_transaction?.ulid}</Typography>
                            </div>
                        </div>
                        <div className="box-out" style={{  borderRadius: 20, padding: 5, marginBottom: 20 }}>
                            <div className='flex-l-m p-all-10'>
                                <Typography className="text-gold-short" style={{  fontWeight: 'bold' }}>{t('spend.paymentBreakdown')}</Typography>
                            </div>
                            <div className='flex-sb-m p-all-10'>
                                <Typography style={{ color: theme.palette.white.main }}>{t('spend.redeemableValue')}</Typography>
                                <Typography style={{ color: theme.palette.white.main }}>{tradingAmountFormat(dialogInfo?.info?.redeemable_value)}</Typography>
                            </div>
                            <div className='flex-sb-m p-all-10'>
                                <Typography style={{ color: theme.palette.white.main }}>{t('spend.passiveUnit')}</Typography>
                                <Typography style={{ color: theme.palette.white.main }}>{tradingAmountFormat(dialogInfo?.info?.trade_transaction?.passive_unit)}</Typography>
                            </div>
                            <div className='flex-sb-m p-t-10 p-lr-10'>
                                <Typography style={{ color: theme.palette.white.main }}>{t('spend.activeUnit')}</Typography>
                                <Typography style={{ color: theme.palette.white.main }}>{tradingAmountFormat(dialogInfo?.info?.trade_transaction?.active_unit)}</Typography>
                            </div>
                            <div className='flex-sb-m p-b-10 p-lr-10'>
                                <Typography style={{ color: theme.palette.white.main, fontStyle: 'italic' }} variant="caption">{t('trade.currentRate')}</Typography>
                                <Typography style={{ color: theme.palette.white.main, fontStyle: 'italic' }} variant="caption">{tradingAmountFormat(dialogInfo?.info?.trade_transaction?.trade_price)}</Typography>
                            </div>
                            <div className='flex-sb-m p-all-10'>
                                <Typography className="text-gold-short" style={{ fontWeight: 'bold' }}>{t('spend.totalPurchaseValue')}</Typography>
                                <Typography className="text-gold-short" style={{ fontWeight: 'bold' }}>{tradingAmountFormat(parseFloat(dialogInfo?.info?.redeemable_value).toFixed(2))}</Typography>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </CustomDiv>
        </div>
    );
}

const paginationStyle = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                ul: {
                    justifyContent: 'space-around',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: theme.palette.white.main, // Change pagination numbers color to red
                },
            },
        },
    }
});

const CustomDiv = styled('div')({
    paddingTop: 30,
    '@media (min-width: 900px)': {
        paddingTop: 80,
    },
});

const useStyles = makeStyles(theme => ({
    columnStyle:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        [theme.breakpoints.down('md')]: {
            justifyContent:'center',
            alignItems:'flex-start',
            flexDirection:'column',
        },
    }
}));
