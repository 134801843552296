import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Tab, Tabs, Typography, LinearProgress, Link, ThemeProvider, createTheme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';
import { storePaths , currencyFormat } from '@utils/Tools';

import BonusListing from '@pages/Wallet/BonusListing';
import WalletListing from '@pages/Wallet/WalletListing';
import { FiPlus } from "react-icons/fi";

const Wallet = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    let location = useLocation();

    let { page } = useParams();
    const { accessToken } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    
    const [walletListing, setWalletListing] = useState([]);
    const [bonusListing, setBonusListing] = useState([]);
    const [activeTab, setActiveTab] = useState(page);
    const [totalBalance, setTotalBalance] = useState(0);
    const [isBonusTabActive, setIsBonusTabActive] = useState(false);
    const [isBonusTabDisabled, setIsBonusTabDisabled] = useState(false);
    const [profitLimit, setProfitLimit] = useState({});
    const [state, setState] = useState({
        pageIndex: null,
        subPage: 'wallet',
    });

    useEffect(() => {
        if (page) {
            if (_.includes(['wallet', 'bonus'], page)) {
                setState({
                    ...state,   
                    pageIndex: page === 'bonus' ? 'bonus' : 'wallet',
                    subPage: page,
                });
                if (page === 'bonus') {
                    setIsBonusTabActive(true);
                } else {
                    setIsBonusTabActive(false);
                }
            }
        }
    }, [page]);

    useEffect(() => {
        setLoading(true);
        getUrl(`/member-wallets`).then(response => {
            if (response.status) {
                setWalletListing(response.data);
                // Extract balance values from the wallet_balances array
                const balanceArray = response.data.flatMap(item => {
                    return item.wallet_balances.map(wallet => parseFloat(wallet.balance) || 0);
                });

                // Calculate total balance
                const totalBalance = balanceArray.reduce((sum, balance) => sum + balance, 0);

                // Update the total balance in the state
                setTotalBalance(totalBalance);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/member-bonuses`).then(response => {
            if (response.status) {
                setBonusListing(response.data);
                setProfitLimit(response.profit.total_profit_limit);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        setLoading(false);
    }, []);

    const handleTabChange = (event, newValue) => {
        // Use the new value to determine the active tab
        if (newValue === 'bonus') {
            // Check if the Bonus tab is disabled
            if (isBonusTabDisabled) {
                return;
            }

            // Enable bonusListing and set the bonus tab as active
            // setBonusListing([]);
            setIsBonusTabActive(true);
        } else {
            // Disable bonusListing for other tabs and set the bonus tab as inactive
            setIsBonusTabActive(false);
        }

        // Always update the active tab to handle toggling
        setActiveTab(newValue);
        navigate(`/funds/${newValue === 'bonus' ? 'bonus' : 'wallet'}`, { state: { from: storePaths(location) } });
    };

    let percent = null;
    let remain = null;
    let text = '';
    let profit_limit = null;

    percent = ((parseFloat(profitLimit?.profit_limit) > 0) ? ((parseFloat(profitLimit?.profit_limit_balance) / parseFloat(profitLimit?.profit_limit)) * 100) : 0).toFixed(2);
    remain = parseFloat(profitLimit?.profit_limit_balance);
    text = (profitLimit?.profit_limit_balance || 0) + ' / ' + (profitLimit?.profit_limit || 0);
    profit_limit = parseFloat(profitLimit?.profit_limit);

    return (
        <>
            {accessToken && (
                <div className='dashboard-container'>
                    {/* <Typography style={{ fontSize: 12, padding: '15px 40px 0 ', width: '100%'}}>
                    {t('deposit.noti', {'value': state?.details?.min_amount})}
                </Typography> */}
                    <>
                            <div style={{ width: 800, margin: ' 0 auto', zIndex: 2 }} className='max-w-full pos-relative'>
                                <Tabs value={activeTab} onChange={handleTabChange} style={{ overflow: 'unset', }}>
                                    <Tab label={t('wallet.tabTitleWallet')} value="wallet" style={{maxWidth:'50%'}} />
                                    <Tab label={t('wallet.tabTitleBonus')} value="bonus"  style={{maxWidth:'50%'}}/>
                                </Tabs>
                            </div>

                        <div className='dashboard-w' style={{ borderTopLeftRadius: 0, paddingTop: 40, width:800, zIndex: 1 }} >
                            <div className='pos-relative ' style={{ zIndex: 2 }}>
                                <div className='txt-center w-full p-b-30 p-t-20'>
                                    {/* Title & Amount */}
                                    <div className={`head ${styles.walletBalanceLayout} txt-center p-t-30`}>
                                        {
                                            state?.pageIndex === 'bonus'
                                                ?
                                                <>
                                                    <p className='txt-white fs-16'>
                                                        <b>{t('wallet.accumulateBonus')}</b>
                                                    </p>
                                                    {/* <Typography variant='h3' className={styles.amount}>
                                                        $ {parseFloat(profitLimit?.profit_limit_balance || 0).toFixed(2)}
                                                    </Typography> */}
                                                    <Typography variant='h3' className="txt-amount">
                                                        $ {currencyFormat(profitLimit?.profit_limit_balance || 0, 2)}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <p className='txt-white fs-16'>
                                                        <b>{t('wallet.accumulateWalletAmount')}</b>
                                                    </p>
                                                    {/* <Typography variant='h3' className={styles.amount}>
                                                        $ {Intl.NumberFormat('en-US', {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }).format(totalBalance.toFixed(2))}
                                                    </Typography> */}
                                                    <Typography variant='h3' className="txt-amount">
                                                        $ { currencyFormat(totalBalance, 2)}
                                                    </Typography>
                                                </>
                                        }
                                    </div>

                                    {
                                        state?.pageIndex === 'bonus' &&
                                        <>
                                            {/* Profit Limit Balance */}
                                            <div className='w-full pos-relative flex-m p-b-10  p-t-10' >
                                                <div style={{ width: 'calc(100% - 20px)' }} className='flex-c-m'>
                                                    {/* <ThemeProvider theme={progressBarStyle}> */}
                                                        <div className='w-full' >
                                                            <ThemeProvider theme={changeDirection => ({ ...changeDirection, direction: 'rtl' })}>
                                                                <div className='p-all-4 bor-50 gradient-gold-bg '>
                                                                    <LinearProgress dir="rtl" variant="determinate" value={percent ? parseFloat(percent) : 0} style={{ height: 34, borderRadius: 48 }} />
                                                                </div>
                                                            </ThemeProvider>
                                                        </div>
                                                    {/* </ThemeProvider> */}
                                                    <div className='w-full txt-center pos-absolute'>
                                                        <div className='flex-c-m w-full' style={{ color: percent > 60 ? '#000' : '#eed189', fontWeight: 500 }}>
                                                            <p style={{ fontSize: 14, textShadow: '1px 1px 1px #00000040' }}>
                                                                {percent}% {t('wallet.of')} <span style={{ fontWeight: 500 }}>{parseFloat(profitLimit?.profit_limit || 0).toFixed(2)}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link component={RouterLink} to={`/plans`} state={{ from: storePaths(location) }} underline="none" className='pos-absolute' style={{ right: 0 }}>
                                                    <div className='flex-c-m clwhite plus-btn' style={{
                                                        boxShadow:'-8px 0 6px 0 #0000008f, inset -2px 3px 4px 0 #fff3d5c7',
                                                        background: theme.palette.primary.main,
                                                    }}>
                                                        <FiPlus className='fs-27 txt-base' />
                                                    </div>
                                                </Link>
                                            </div> 
                                        </>
                                    }

                                </div>
                                <div className='pos-relative' style={{ zIndex: 2 }}>
                                    {/* Content for each tab */}
                                    <div className={`tab-content ${state?.pageIndex === 'wallet' ? '' : 'hidden-left'}`}>
                                        {state?.pageIndex === 'wallet' && <WalletListing listing={walletListing} />}
                                    </div>
                                    <div className={`tab-content ${state?.pageIndex === 'bonus' ? '' : 'hidden-right'}`}>
                                        {state?.pageIndex === 'bonus' && <BonusListing listing={bonusListing} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            )}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    walletBalanceLayout: {       
        // backgroundImage: `url('/images/wallets/accumulate_background.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        width: '100%',
        minHeight: 80,
        backgroundPosition:'center bottom'
    },
}));



export default Wallet;