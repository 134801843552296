import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Box, Button, IconButton, Link } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { changeLanguage } from '@actions';
import { storePaths } from '@utils/Tools';

export default function SplashScreen() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    let location = useLocation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    return (
        <>
            {/* <Box style={{ height: '100%' }}>
            
            <div style={{ textAlign: 'center' }}>
                <img src="images/plans/plan1.png" style={{ width: "60%", filter: 'drop-shadow(6px 6px 20px rgba(0, 0, 0, 0.14))' }} alt="bg-img" loading='lazy'  />
                <img src="images/auth/splash-text.png" style={{ width: "70%", paddingTop: 20 }} alt="bg-img" loading='lazy' />
            </div>
        </Box> */}
            {/* /////////////////////////// */}
            {/* <div className='flex-c-m' style={{ backgroundColor: '#222', minHeight: '100vh', backgroundImage: `url('../images/auth/login-bg2.png')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}> */}
            <div className='flex-c-m' style={{ backgroundColor: '#222', minHeight: '100vh' }}>
                <div className='flex-sb-m pos-absolute w-full' style={{ top: 20, left: 0 }}>
                    {/* <img src="images/logos/logo-white.png" style={{ height: 60, margin: 30 }} alt="logo" loading='lazy' /> */}
                    {
                        language === 'en' ?
                            <IconButton style={{ margin: 30 }} onClick={() => changeSystemLanguage('cn')}>
                                <p className='fs-16 txt-upper' style={{ fontWeight: 500, color: '#fff' }}>中文</p>
                            </IconButton>
                            :
                            <IconButton style={{ margin: 30 }} onClick={() => changeSystemLanguage('en')}>
                                <p className='fs-16 txt-upper' style={{ fontWeight: 500, color: '#fff' }}>EN</p>
                            </IconButton>
                    }
                </div>
                <div className='login-container flex-c-m' style={{ paddingTop: '10vh' }}>
                    <div className='login-product'  >
                        {/* <img src='/images/auth/product.png' alt="product" className='w-full' /> */}
                    </div>
                    <div className='login-box pos-relative'>
                        {/* <p className='fs-30' style={{ backgroundImage: `url('../images/auth/mountain.png')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center bottom', backgroundSize: 'contain', paddingBottom: 30, color: theme.palette.primary.main }}><b>{t('login.welcomeBack')}</b></p> */}
                        <p className='fs-30' style={{ paddingBottom: 30 }}><b>{t('login.welcomeBack')}</b></p>
                        <div>
                            {/* <img src="images/auth/splash-text.png" style={{ width: "70%", paddingTop: 20 }} alt="bg-img" loading='lazy' /> */}
                        </div>
                        <Box className='pos-relative' style={{ width: 350, margin: '0 auto', maxWidth: '100%' }}>
                            <Link component={RouterLink} to={`/login`} state={{ from: storePaths(location) }}>
                                <Button variant="contained" color="primary" className='big-button' style={{ marginTop: 20 , background: theme.palette.primary.main }} fullWidth>
                                    {t('button.login')}
                                </Button>
                            </Link>
                            <Link component={RouterLink} to={`/register`} state={{ from: storePaths(location) }}>
                                <Button variant="contained" color="primary" className='big-button' style={{ marginTop: 20 , background: theme.palette.primary.main }} fullWidth>
                                    {t('login.registerAcc')}
                                </Button>
                            </Link>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles(theme => ({

}));