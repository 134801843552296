import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Button, FormControl, Grid, IconButton, Input, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiEye, FiEyeOff, FiMinus, FiPlus } from "react-icons/fi";

import { getUrl, putUrl } from '@utils/ApiAction';
import { storePaths, currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
// import { AiOutlineConsoleSql } from 'react-icons/ai';

export default function MerchantTradeSellingForm () {
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const classes = useStyles();
    const theme = useTheme();
    let location = useLocation();
    let navigate = useNavigate();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [params] = useSearchParams();

    const [inputErrors, setInputErrors] = useState({});
    const [userData, setUserData] = useState({
        activeUnit: 0,
        tradableValue: 0,
        maxUnit: 0,
        maxValueAllowed : 0,
        rate: 0,
        decimal: 2,
    });
    const [priceData, setPriceData] = useState({
        low_price: 0,
        high_price: 0,
    });
    const [profit, setProfit] = useState({
        type: 0,
        amount: 0,
    });
    const [state, setState] = useState({
        unit: '',
        rate: '',
        securityPassword: '',
    });
    const [systemStatus, setSystemStatus] = useState(null);
    const [step, setStep] = useState(0);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setLoading(true);
        callApi();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(userData.maxValueAllowed !== 'undefined' && userData.maxValueAllowed > 0 && state.rate >= 0){
            let maxUnit = Math.min(parseInt(userData.activeUnit), parseInt(Math.floor(userData.tradableValue / userData.rate)));
            let maxUnitAllowed = parseInt(Math.floor(userData.maxValueAllowed / state.rate));
            if(maxUnitAllowed > 0){
                setUserData({ ...userData, maxUnit : Math.min(maxUnit, maxUnitAllowed)});
            }else{
                setUserData({ ...userData, maxUnit : 0});
            }
            if (maxUnitAllowed < state.unit) {
                setState({ ...state, unit : maxUnitAllowed })
            }
        }
    }, [state.rate]);

    const callApi = () => {
        getUrl(`/get-merchant-selling-form-data`).then(response => {
            setLoading(false);
            if (response.status) {
                const parsedLowPrice = parseFloat(response?.low_and_high_price?.low_price);
                let initialRate = '';
                if (params.get("rate")) {
                    if (params.get("rate") <= response?.low_and_high_price?.high_price && params.get("rate") >= response?.low_and_high_price?.low_price) {
                        initialRate = params.get("rate");
                    }
                } else if (!isNaN(parsedLowPrice) && typeof parsedLowPrice === 'number') {
                    initialRate = parsedLowPrice.toFixed(3);
                }
                setState({ ...state, rate: initialRate });
                let tv = response?.user_data?.tradable_value > 0 ? response?.user_data?.tradable_value : 0;
                let maxUnit = Math.min(parseInt(response?.user_data?.active_unit), parseInt(Math.floor(tv / response?.user_data?.rate)));
                let unit = Math.min(response.user_data.max_unit, parseInt(Math.floor(response.user_data.max_value_allowed / initialRate)))
                
                setUserData({
                    activeUnit: response?.user_data?.active_unit,
                    tradableValue: tv,
                    maxUnit: Math.min(maxUnit, unit),
                    maxValueAllowed: response.user_data.max_value_allowed,
                    rate: response?.user_data?.rate,
                    decimal: response?.user_data?.decimal,
                });
                setPriceData(response?.low_and_high_price);
                setProfit(response.profit);
                setSystemStatus(parseInt(response.trade_system_status));
            }
            else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleRateChange = (type, value) => {
        setState((prevRate) => {
            let newRate = value;

            switch (type) {
                case 'increase':
                    newRate = parseFloat(prevRate.rate ? prevRate.rate : 0) + value;
                    if (newRate > priceData.high_price) {
                        newRate = priceData.high_price;
                    }
                    newRate = parseFloat(newRate).toFixed(3);
                    break;
                case 'decrease':
                    newRate = parseFloat(prevRate.rate ? prevRate.rate : 0) - value;
                    if (newRate < priceData.low_price) {
                        newRate = priceData.low_price;
                    }
                    newRate = parseFloat(newRate).toFixed(3);
                    break;
                default: 
                    if ( !isNaN( value ) ) 
                    {
                        if (value.toString().split('.')[1]?.length > 3) {
                            newRate = prevRate.rate;
                        } else {
                            newRate = value;
                        }
                    }
                    else
                    {
                        newRate = priceData.low_price;
                    }
            }
            return {
                ...prevRate,
                rate: newRate,
            };
        });
    };

    const getServiceFees = () => {
        let sum = state.unit * state.rate;
        let fees = 0;
        if (profit?.type === 'percent') {
            fees = sum * profit.amount / 100;
        } else {
            fees = profit.amount;
        }
        return fees ? fees : 0;
    }

    const calculateProfitEarn = () => {
        let sum = (state.unit * state.rate) - getServiceFees();
        return sum > 0 ? sum : 0;
    }

    const handlePreviousStep = () => {
        // window.scrollTo(0, 0);
        setStep(0);
    }
    const handleNextStep = () => {
        if ( (  !isNaN(state.unit) && state.unit > 0 ) && ( !isNaN(state.rate) && state.rate > 0 ) ) {
            // window.scrollTo(0, 0);
            // submitSellValidation();
            if (state.rate <= priceData.high_price && state.rate >= priceData.low_price)
            {
                setStep(1);
            }
            else
            {
                setInputErrors({
                    'rate' : t('trade.invalidRate')
                });
            }
            

        } else {
            addAlert("", t('trade.fillRateUnit'), 'error', '');
        }
    }

    const submitSell = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            unit: state.unit,
            rate: state.rate,
            type: 'merchant',
            security_password: state.securityPassword,
        }
        putUrl(`/sell-merchant-balance-amount`, params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response?.message || t('success.sellingSuccess'), 'success', '');
                navigate('/merchant-trade/sell-transaction-history', { state: { from: storePaths(location) } });
            } else {
                setInputErrors(response.errors);
                addAlert('', response?.message || t('error.sellingError'), 'error', '');
                if (response.errors?.unit || response.errors?.rate) {
                    setStep(0);
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    // STEP 0
    const step0 = () => {
        return (
            <div>
                <p className='fs-25 text-gold-short txt-center'>
                    <b>{t('trade.tradeToken')}</b>
                </p>
                {tradableCard()}
                {tradableForm()}
            </div>
        )
    }
    const tradableCard = () => {
        return (
            <div className='flex-sa-m'>
                <div className="m-b-30" style={{ width: '400px' }}>
                    <div className={`${classes.tradableCard} gradient-gold-border`}>
                        <Typography variant="caption" className='txt-upper'>{t('trade.tradableValue')}</Typography>
                        <div className={mobileView ? '' : 'flex-c-m'}>
                            <p style={{ fontSize: 22, wordBreak: 'break-all', fontWeight: "bold" }}>
                                ${currencyFormat(userData.activeUnit, userData.decimal)}
                            </p>
                            <p style={{ fontSize: 22, fontStyle: "italic", marginLeft: 10, textTransform: "uppercase" }}>
                                {'≈ ' + currencyFormat(Math.floor(userData.activeUnit / userData.rate), 0) + " " + t('trade.unit')}
                            </p>
                        </div>
                        <Typography variant="body2" style={{ fontStyle: "italic" }}>{t('trade.currentRate') + ': $' + userData.rate}</Typography>
                    </div>
                </div>
            </div>
        )
    }
    const tradableForm = () => {
        return (
            <div className='pos-relative h-full flex-sa-m'>
                <div style={{ width: "100%", maxWidth: '400px' }}>
                    {/* UNIT */}
                    <Box>
                        <TextField
                            placeholder={t('trade.unit')}
                            variant='standard'
                            fullWidth
                            value={state.unit}
                            onChange={
                                ({ target }) => {
                                    const value = target.value;
                                    const isValidNumber = (value) => {
                                      return /^\d+$/.test(value);
                                    };
                                    setState((prevState) => ({
                                      ...prevState,
                                      unit: isValidNumber(value) && value <= userData.maxUnit && value > 0 && !isNaN(value)
                                        ? value 
                                        : ''
                                    }));
                                }
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button variant="contained" className='max-button' onClick={({ target }) => setState({ ...state, unit: userData.maxUnit })}>
                                            {t('general.max')}
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {
                            inputErrors && inputErrors.unit
                                ?
                                <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.unit}</Typography>
                                :
                                null
                        }
                        <Box display="flex" justifyContent="space-between" style={{ marginTop: 7 }}>
                            <p className='txt-caption'>
                                {t('trade.availableUnit') + ": " + currencyFormat(userData.activeUnit, 0)}
                            </p>
                            <p className='txt-caption'>
                                {t('trade.max_trading_unit') + ": " + currencyFormat(userData.maxUnit, 0)}
                            </p>
                        </Box>
                    </Box>
                    {/* PRICE */}
                    <Box>
                        <p style={{ fontSize: 14,  marginTop: 25, marginBottom: 5 }}>{t('trade.sellingRate')}</p>
                        <div className='pos-relative flex-c-m' style={{ width: '100%' }}>
                            <Input
                                style={{ width: '100%', }}
                                type="number"
                                value={state.rate}
                                onChange={({ target }) => handleRateChange('onchange', target.value)}
                                inputProps={{ min: 0, style: { textAlign: 'center'} }}
                                startAdornment={
                                    <Button variant="contained"
                                        className={`${classes.iconButtonStyle}`}
                                        onClick={() => handleRateChange('decrease', 0.001)}
                                    >
                                        <FiMinus className='fs-20'/>
                                    </Button>
                                }
                                endAdornment={
                                    <Button variant="contained"
                                        className={`${classes.iconButtonStyle}`}
                                        onClick={() => handleRateChange('increase', 0.001)}
                                    >
                                        <FiPlus className='fs-20'/>
                                    </Button>
                                }
                            />
                        </div>
                        {
                            inputErrors?.rate ?
                                <Typography className={classes.errorMessage} >{inputErrors?.rate}</Typography>
                                :
                                null
                        }
                        {
                            priceData.low_price !== null && priceData.high_price !== null &&
                            <div className='flex-r'>
                                <p className='txt-caption' >
                                    {t('trade.rate')} : {priceData.low_price} ~ {priceData.high_price}
                                </p>
                            </div>
                        }
                    </Box>
                    {/* PROFIT */}
                    <Grid container style={{ marginTop: 10 }}>
                        <Grid item xs={6}>
                            <p className='txt-caption'>
                                {t('trade.totalBudgetedRevenue')}
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <p className='txt-caption' style={{ textAlign: 'justify', textAlignLast: 'right' }}>
                                ${currencyFormat(calculateProfitEarn(), userData.decimal)}
                            </p>
                        </Grid>
                    </Grid>
                    <div className='flex-c-m p-t-20 w-full pos-relative'>
                        <Button
                            variant="contained"
                            onClick={() => handleNextStep()}
                            className='big-button'
                            fullWidth
                            style={{ textTransform: "uppercase" }}
                            disabled={userData.tradableValue > 0 ? false : true}
                        >
                            {t('button.next')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    // STEP 1
    const step1 = () => {
        return (
            <div>
                <div style={{ textAlign: "center" }}>
                    <p className='txt-upper text-gold-short' style={{ fontWeight: 'bold', fontSize: 18 }}>{t('trade.tradingDetails')}</p>
                    <div style={{ lineHeight: "1.6rem" }}>
                        <p style={{ marginTop: 30 }}>{t('trade.tradeUnit')}</p>
                        <p className='fs-24 text-gold' style={{ fontWeight: "bold" }}>{currencyFormat(state.unit, 2)}</p>
                        <p>≈ $ {currencyFormat(state.unit * userData.rate, 2)}</p>
                    </div>
                </div>
                {/* Approx. Summary */}
                <div className='gradient-gold-border bor-20 p-all-15 m-t-15'>
                    <div className='txt-center'>
                        <p style={{fontWeight: "bold" }}>{t('trade.approxSummary')}</p>
                        <div className='divider-list-black'></div>
                    </div>
                    <div className='fs-14' style={{ lineHeight: "2rem" }}>
                        <div className='flex-sb-m'>
                            <p>{t('trade.sellingRate')}</p>
                            <p>{state.rate}</p>
                        </div>
                        <div className='flex-sb-m'>
                            <p>{t('trade.totalSelling')}</p>
                            <p>${currencyFormat(state.unit * state.rate, 2)}</p>
                        </div>
                        <div className='flex-sb-m'>
                            <p>{t('trade.serviceCharge')} {profit.amount > 0 ? (profit?.type === 'percent' ? profit.amount + "%" : "$" + profit.amount) : ''}</p>
                            <p>- ${currencyFormat(getServiceFees(), 2)}</p>
                        </div>
                        <div className='flex-sb-m'>
                            <p className='fs-18 text-gold-short' style={{fontWeight: "bold" }}>{t('trade.totalReceived')}</p>
                            <p className='fs-18 text-gold-short' style={{fontWeight: "bold" }}>${currencyFormat(calculateProfitEarn(), userData.decimal)}</p>
                        </div>
                    </div>
                </div>
                {/* Security Password */}
                <div className='p-t-30'>
                    <FormControl variant="standard" fullWidth>
                        <Input
                            placeholder={t('profile.currentSecurityPassword')}
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            value={state.securityPassword}
                            onChange={({ target }) => setState({ ...state, securityPassword: target.value })}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword((show) => !show)}>
                                        {showPassword ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {
                        inputErrors?.security_password ?
                            <Typography className={classes.errorMessage} >{inputErrors?.security_password}</Typography>
                            :
                            null
                    }
                </div>
                {/* Button */}
                <div className='p-t-30'>
                    <Button
                        variant="contained"
                        onClick={() => submitSell()}
                        className='big-button'
                        fullWidth
                        style={{ textTransform: "uppercase", marginBottom: 10 }}
                    >
                        {t('trade.tradeNow')}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => handlePreviousStep()}
                        className='big-button'
                        fullWidth
                        style={{ textTransform: "uppercase", marginBottom: 10 }}
                    >
                        {t('button.back')}
                    </Button>

                </div>
            </div>
        )
    }

    return (
        <div className='dashboard-container' style={{ paddingTop: 100 }}>
            {
                systemStatus !== null
                    ?
                    (systemStatus === 10 || systemStatus === 15) // TRADE SYSTEM ACTIVE
                        ?
                        <div className="dashboard-w" style={{ minHeight: '80vh' }}>
                            {(step === 0 ? step0() : (step === 1 ? step1() : null))}
                        </div>
                        :
                        <div className='dashboard-w flex-c-m flex-col' style={{ minHeight: '70vh' }}>
                            <div className='w-full'>
                                <div style={{ width: 150, margin:'0 auto'}}>
                                    <img src='/images/general/filter-search-empty-data.png' alt="empty" className='w-full' />
                                </div>
                                <p className='txt-empty'><b>{t('trade.tradeNotAvailable')}</b></p>
                            </div>
                        </div>
                    :
                    null
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    errorMessage: {
        color: '#d32f2f',
        fontSize: '0.875rem',
    },
    tradableCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        borderRadius: '16px',
        overflow: 'hidden',
        marginTop: '20px',
        alignItems: 'center',
        padding: 40,
        lineHeight: "2rem"
    },
    iconButtonStyle: {
        borderRadius: 8,
        minWidth: '45px !important',
        height: '45px !important',
    }
}));