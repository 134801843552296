import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Button, Grid, IconButton, InputAdornment, TextField, Typography, Box, Stepper, Step, StepLabel, MenuItem, FormHelperText, FormControl, Select } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { makeStyles, useTheme } from '@mui/styles';
import { FiEye, FiEyeOff } from "react-icons/fi";
import RefreshIcon from '@mui/icons-material/Refresh';

import { postUrl, getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import axios from 'axios';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
};  

const INITIAL_STATE = {
    username: '',
    name: '',
    email: '',
    country: '',
    code: '',
    mobile: '',
    password: '',
    passwordConfirmation: '',
    securityPassword: '',
    securityPasswordConfirmation: '',
    referralName: '',
    placementName: '',
    placementPosition: '0',
    mstoreUsername: '',
    mstorePassword: '',
    placementType:'manual',
    captcha:''
};

const RegisterForm = memo((props) => {
    const { stepperStyle } = props;
    const theme = useTheme();
    const classes = useStyles();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { username } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [option, setOption] = useState({
        passwordShow: false,
        passwordConfirmationShow: false,
        securityPasswordShow: false,
        securityPasswordConfirmationShow: false,
        mstorePasswordShow: false,
    });
    const [searchParams] = useSearchParams();

    const [showMstoreAcc, setShowMstoreAcc] = useState(false);
    const [mstoreUsername, setMstoreUsername] = useState('');
    const [mstorePassword, setMstorePassword] = useState('');

    const [captcha, setCaptcha] = useState('');
    const [captchaKey, setCaptchaKey] = useState('');
    const [timeLeft, setTimeLeft] = useState(0);
    const [timeBlock, setTimeBlock] = useState(0);

    let location = useLocation();
    let current_path = location?.pathname;
    const isRecruitPath = current_path === '/account/recruit';

    const POSITION = [
        { 'code': 'left', 'value': '1', label: t('general.left') },
        { 'code': 'right', 'value': '2', label: t('general.right') },
        // { 'code': 'auto', 'value': 'auto', label: t('general.auto') }
    ];

    const PLACEMENT_MODE = [
        // { 'code': 'auto', 'value': 'auto', label: t('user.pMode.auto') },
        { 'code': 'manual', 'value': 'manual', label: t('user.pMode.manual') }
    ];

    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
    })
    const [userMobile, setUserMobile] = useState({
        country: "",
        code: "",
        mobile: "",
        error: {},
    })

    useEffect(() => {
        setLoading(true)
        getUrl(`/mobile-code-list`).then(response => {
            setLoading(false);
            if (response.status) {
                setMobileCodeList({ ...mobileCodeList, country: response?.data?.country_list });
            }

        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {

        if (_.size(mobileCodeList?.country) > 0) {
            if (userMobile?.country !== '') {
                let codeList = {};
                let countryCode = userMobile?.code;
                let code = _.find(mobileCodeList?.country, { 'code': userMobile?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (userMobile?.code !== '' ? userMobile?.code : '');
                    }
                }

                setMobileCodeList({ ...mobileCodeList, code: codeList });
                setUserMobile(prevState => ({ ...prevState, code: countryCode }));
            } else if (userMobile?.country === '' && userMobile?.code !== '') {
                let tempArr = _.clone(mobileCodeList?.country);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, userMobile?.code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(prevList => ({ ...prevList, code: tempArr[0]['tel_code'], updateState: false }));
                    setUserMobile(prevState => ({ ...prevState, mobileCountry: tempArr[0]['code'] }));
                }
            }
        }
        // eslint-disable-next-line
    }, [mobileCodeList?.country, userMobile?.country, userMobile?.code]);

    useEffect(() => {
        loadCaptcha();
        // Countdown interval to update `timeLeft` every second
        const countdownInterval = setInterval(() => {
            setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
        return () => {clearInterval(countdownInterval)};
    }, []);

    useEffect(() => {
        // Countdown interval to update `timeLeft` every second
        const countdownInterval = setInterval(() => {
            setTimeBlock((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
        return () => {clearInterval(countdownInterval)};
    }, [timeBlock]);

    useEffect(() => {
        // Trigger loadCaptcha when countdown reaches 0
        if (timeLeft === 0) {
            loadCaptcha();
        }
    }, [timeLeft]); 

    const loadCaptcha = async ()=> {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}captcha/api`)
        .then(res => {
            setCaptcha(res.data.img);
            setCaptchaKey(res.data.key);
            setTimeLeft(90);
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name){
            case 'mobile':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setUserMobile({ ...userMobile, [name]: value });
                break;
            case 'username':
                value = value.replace(/[^\w\s]/gi, "").toLowerCase().replace(/ /g, '');
                setState({ ...state, [name]: value });
                break;
            case 'mstoreUsername':
                value = value.replace(/[^\w\s]/gi, "").toLowerCase().replace(/ /g, '');
                setState({ ...state, [name]: value });
                setMstoreUsername(value);
                break;
            case 'mstorePassword':
                setMstorePassword(value);
                break;
            case 'placementType':
                setInputErrors({})
                if(value === 'auto'){
                    setState({ ...state, [name]: value, placementName :'', paramPosition:'0'});
                    break;
                }else{
                    setState({ ...state, [name]: value});
                    break;
                }
            default :
                setState({ ...state, [name]: value });
                break;
        }
        // if (name === 'mobile') {
        //     value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        //     setUserMobile({ ...userMobile, [name]: value });
        //     return;
        // }
        // if (name === 'username' || name === 'mstoreUsername') {
        //     value = value.replace(/[^\w\s]/gi, "").toLowerCase().replace(/ /g, '');
        // }

        // if(name === 'mstoreUsername') {
        //     value = value.replace(/[^\w\s]/gi, "").toLowerCase().replace(/ /g, '');
        //     setMstoreUsername(value);
        // }

        // if (name === 'mstorePassword') {
        //     setMstorePassword(value);
        // }
    
        // setState({ ...state, [name]: value });
    };    

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    useEffect(() => {
        let paramRef = searchParams.get("r") ? atob(searchParams.get("r")) : '';
        let paramPlacement = searchParams.get("placement") ? atob(searchParams.get("placement")) : '';
        let paramPosition = searchParams.get("position");
        setState({ ...state, referralName: paramRef ? paramRef : (username ? username : ''), placementName: paramPlacement ? paramPlacement : '', placementPosition: paramPosition ? paramPosition : '0', placementType : 'manual'});
    }, [username, searchParams]);
    
    const handleSubmit = () => {
        setLoading(true);
        setInputErrors({});
        let params = {
            name: state.name,
            username: state.username,
            email: state.email,
            country: userMobile?.country,
            mobile_code: userMobile?.code,
            mobile: userMobile?.mobile,
            password: state.password,
            source: '',
            password_confirmation: state.passwordConfirmation,
            security_password: state.securityPassword,
            security_password_confirmation: state.securityPasswordConfirmation,
            referral_name: state.referralName,
            placement_name: state.placementName,
            placement_position: state.placementPosition,
            placement_type: state.placementType,
            mstore_username: mstoreUsername,
            mstore_password: mstorePassword,
            captcha: state.captcha,
            key:captchaKey
        }
        postUrl('/register', params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                if (username) {
                    setState(INITIAL_STATE);
                    setActiveStep(0);
                } else {
                    setState({...state,captcha:''})
                    navigate('/login');
                }
            } else {
                if(response.errors){
                    setInputErrors(response.errors);
                   
                    if (response.errors.username || response.errors.email || response.errors.mobile) {
                        setActiveStep(0);
                    } else if (response.errors.referral_name || response.errors.placement_name || response.errors.placement_position) {
                        setActiveStep(1);
                    } else if (response.errors.password || response.errors.security_password) {
                        setActiveStep(2);
                    }
                }
                addAlert('', response.message, 'error', '');
            }
            loadCaptcha();
        }).catch((error) => {
            setLoading(false);
            loadCaptcha();
            setState({...state,captcha:''})
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const passwordTextField = (column, columnSnake) => {
        return(
            <Grid item xs={12} className={classes.textFieldStyle}>
                <TextField
                    variant="standard"
                    fullWidth
                    name={column}
                    placeholder={t(`user.${column}`) + '*'}
                    value={state?.[column]}
                    onChange={handleChange}
                    type={option?.[column + 'Show'] ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => handlePasswordShow(column + 'Show')}>
                                    {option?.[column + 'Show'] ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                        disableUnderline: true
                    }}
                    helperText={inputErrors && inputErrors[columnSnake] ? inputErrors[columnSnake] : ''}
                    error={inputErrors && inputErrors[columnSnake] ? true : false}
                    InputLabelProps={{ shrink: true }}
                />
                {
                    column === 'password' || column === 'securityPassword'
                    ?
                    <p className='txt-caption' >{t('user.'+column+'Rules')}</p>
                    :
                    null
                }
            </Grid>
        )
    }
    
    const steps = [
        t('stepper.personalInfo'),
        t('stepper.upline'),
        t('stepper.security'),
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    let currSectionFilled = false;

    const handleNext = () => {
        handleSectionFilled(state);

        if (!currSectionFilled) {
            addAlert('', t('error.requiredFillAllSection'), 'error', '');
            return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSectionFilled = (curr) => {
        if (activeStep === 0 && curr.username !== '' && curr.email !== '' && curr.name !== '' ) {
            currSectionFilled = true;
        } else if (activeStep === 1 && curr.referralName !== '') {
            currSectionFilled = true;
        } else if (activeStep === 2 && curr.securityPassword !== '' && curr.password !== '') {
            currSectionFilled = true;
        } else {
            currSectionFilled = false;
        }
        return currSectionFilled;
    };

    const handleCheckboxChange = (event) => {
        setShowMstoreAcc(event.target.checked);
    };

    const hanldeRefreshCaptcha = () => {
        loadCaptcha();
        setTimeBlock(10);
    };

    ////

    return (

        <>
            {/* ////////////////////// */}
            <Box sx={{ width: 700, maxWidth: '100%', margin: '0 auto' }}>
                <Stepper activeStep={activeStep} alternativeLabel className='p-b-30' >
                    {steps.map((label) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} sx={stepperStyle}>
                                <StepLabel style={{ wordBreak:'break-all'}} >{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>

            <div>
                {activeStep === 0 ? (
                    <Grid container>
                        <Grid item xs={12} className={classes.textFieldStyle}>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='username'
                                placeholder={t('user.username') + '*'}
                                value={state?.username}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                            />
                            <p className='p-t-8 p-l-5 fs-11' style={{lineHeight:1, color:'#8f8e8e'}}>
                                {t('user.usernameLowerCase')}
                            </p>
                        </Grid>
                        <Grid item xs={12} className={classes.textFieldStyle}>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='name'
                                placeholder={t('user.name') + '*'}
                                value={state?.name}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                fullWidth
                                name='email'
                                placeholder={t('user.email') + '*'}
                                value={state?.email}
                                onChange={handleChange}
                                required
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ disableUnderline: true }}
                                className={classes.textFieldStyle}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl sx={{ width: '100%' }} variant="standard" className={classes.textFieldStyle} error={inputErrors && inputErrors['country'] ? true : false}>
                                <Select
                                    id="country-select"
                                    value={userMobile.country}
                                    onChange={({ target }) => setUserMobile({ ...userMobile, country: target.value })}
                                    displayEmpty
                                    input={<OutlinedInput sx={{ border: 'none' }} />}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <em >{t('user.country')}</em>;
                                        }
                                        return mobileCodeList?.country.find(option => option.code === selected)?.name || selected;
                                    }}
                                    MenuProps={MenuProps}
                                    sx={{
                                      
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                            borderRadius: 10,
                                            boxShadow: 'none',
                                        },
                                       
                                    }}
                                >
                                    {_.size(mobileCodeList?.country) > 0 ? (
                                        _.map(mobileCodeList?.country, (option, key) => (
                                            <MenuItem key={key} value={option.code} style={{ color: '#222' }}>
                                                {i18n.language === 'cn' ? option.name_cn : option.name}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" style={{ color: '#222' }}>
                                            {t('general.pleaseSelect')}
                                        </MenuItem>
                                    )}
                                </Select>
                                {
                                    inputErrors && inputErrors['country'] &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors['country']}</FormHelperText>
                                }
                                {
                                    userMobile?.code > 0 ? (
                                        <></>
                                    ) : (
                                        <FormHelperText className='p-l-5 fs-11' style={{lineHeight:1, color:'#8f8e8e'}}>{t('general.pleaseSelectCountry')}</FormHelperText>
                                    )
                                }
                            </FormControl>
                        </Grid>
                        <div className='flex-sb-m w-full' style={{ flexWrap: 'wrap' }}>
                            <FormControl sx={{ width: 'calc(30% - 20px)' }} variant="standard" className={classes.textFieldStyle}>
                                <Select
                                    id="code-select"
                                    value={userMobile.code}
                                    onChange={({ target }) => setUserMobile({ ...userMobile, code: target.value })}
                                    displayEmpty
                                    input={<OutlinedInput sx={{ border: 'none', boxShadow: 'none' }} />}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <em>{t('user.code')}</em>;
                                        }
                                        return selected;
                                    }}
                                    MenuProps={MenuProps}
                                    sx={{
                                        
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                            borderRadius: 10,
                                            boxShadow: 'none',
                                        },
                                    }}
                                >
                                    {_.size(mobileCodeList?.code) > 0 ? (
                                        _.map(mobileCodeList?.code, (option, key) => (
                                            <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                                {option}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" style={{ color: '#222' }}>
                                            {t('general.pleaseSelect')}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <div className='txt-center' style={{ width: 40, color: "white" }}>
                                <p className='fs-21'>-</p>
                            </div>
                            <div style={{ width: 'calc(70% - 20px)' }}>
                                <TextField
                                    placeholder={t('user.mobile')}
                                    value={userMobile.mobile}
                                    onChange={handleChange}
                                    name="mobile"
                                    variant="standard"
                                    fullWidth
                                    // helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                    // error={inputErrors && inputErrors.mobile ? true : false}
                                    // InputLabelProps={{ shrink: true }}
                                    // InputProps={{ disableUnderline: true }}
                                    className={classes.textFieldStyle}
                                />
                            </div>
                            {inputErrors.mobile_code && !inputErrors.mobile && (
                                <FormHelperText style={{ color: 'red' }} className="p-l-10">
                                    {inputErrors.mobile_code}
                                </FormHelperText>
                            )}

                            {inputErrors.mobile && !inputErrors.mobile_code && (
                                <FormHelperText style={{ color: 'red' }} className="p-l-10">
                                    {inputErrors.mobile}
                                </FormHelperText>
                            )}

                            {inputErrors.mobile_code && inputErrors.mobile && (
                                <FormHelperText style={{ color: 'red' }} className="p-l-10">
                                    {t('error.mobileCodeAndMobileError')}
                                </FormHelperText>
                            )}
                        </div>
                    </Grid>
                ) : <>
                    {activeStep === 1 ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name='referralName'
                                    placeholder={t('user.referralName') + '*'}
                                    value={state?.referralName}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.referral_name ? inputErrors.referral_name : ''}
                                    error={inputErrors && inputErrors.referral_name ? true : false}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ disableUnderline: true }}
                                    className={classes.textFieldStyle}
                                    // disabled={searchParams.get("r") ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%', borderRadius:20 }} variant="standard" className={classes.textFieldStyle} error={inputErrors && inputErrors['placement_type'] ? true : false}>
                                    <Select
                                        name="placementType"
                                        value={state?.placementType}
                                        onChange={handleChange}
                                        displayEmpty
                                        disabled={searchParams.get("position") ? true : false}
                                        input={<OutlinedInput sx={{ border: 'none' }} />}
                                        MenuProps={MenuProps}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                                borderRadius: 10,
                                                boxShadow: 'none',
                                            },
                                        }}
                                    >

                                        <MenuItem key={0} value={0} disabled >{t('general.selectOption', { option: t('user.placementType') })} </MenuItem>
                                        {
                                            _.map(PLACEMENT_MODE, (data, key) => {
                                                return <MenuItem key={key} value={data.value}>{data.label}</MenuItem>
                                            })
                                        }

                                    </Select>
                                    {
                                        inputErrors && inputErrors['placement_type'] &&
                                        <FormHelperText style={{ color: 'red' }}>{inputErrors['placement_type']}</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                            {
                                state.placementType === 'manual' &&
                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        name='placementName'
                                        placeholder={t('user.placementName')}
                                        value={state?.placementName}
                                        onChange={handleChange}
                                        helperText={inputErrors && inputErrors.placement_name ? inputErrors.placement_name : ''}
                                        error={inputErrors && inputErrors.placement_name ? true : false}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ disableUnderline: true }}
                                        className={classes.textFieldStyle}
                                        disabled={searchParams.get("placement") ? true : false}
                                    />
                                </Grid>
                            }
                            {
                                state.placementType === 'manual' &&
                                <Grid item xs={12}>
                                    <Box sx={{ '& > :not(style)': { marginTop: 1, width: '100%', textAlign: "left" }, }} >
                                            <FormControl fullWidth error={inputErrors && inputErrors.placement_position ? true : false}>
                                                {/* <InputLabel>{t('user.position')}</InputLabel> */}
                                                <Select
                                                    value={state?.placementPosition || ''}
                                                    label={t('user.position')}
                                                    name="placementPosition"
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    disabled={searchParams.get("position") ? true : false}
                                                    MenuProps={MenuProps}
                                                    sx={{
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                            borderRadius: 10,
                                                            boxShadow: 'none',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={'0'} disabled style={{ color: '#222' }}>{t('general.selectOption', { option: t('user.placementPosition') })}</MenuItem>
                                                {
                                                    _.map(POSITION, (pos) => {
                                                        if ((pos.code !== 'auto') || (searchParams.get("position") === 'auto')) {
                                                            return (
                                                                <MenuItem key={pos.code} value={pos.value} style={{ color: '#222' }}>
                                                                    {pos.label}
                                                                </MenuItem>
                                                            )
                                                        }
                                                    })
                                                }
                                            </Select>
                                            {
                                                inputErrors && inputErrors?.placement_position &&
                                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.placement_position}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    ) :
                        <Grid container>
                            {passwordTextField('password', 'password')}
                            {passwordTextField('passwordConfirmation', 'password_confirmation')}
                            {passwordTextField('securityPassword', 'security_password')}
                            {passwordTextField('securityPasswordConfirmation', 'security_password_confirmation')}
                            <Grid item xs={12}>
                                <div style={{width:'100%', display:'flex', alignItems:'center'}}>
                                    <img src={captcha} alt="captcha" />
                                        <IconButton
                                            style={{ fontSize: 16 }}
                                            onClick={hanldeRefreshCaptcha}
                                            disabled={timeBlock === 0 ? false : true}
                                            sx={{
                                                "&.Mui-disabled": {
                                                    color: theme.palette.gray.main
                                                }
                                            }}
                                        >
                                        <RefreshIcon />
                                    </IconButton>
                                    <Typography style={{ fontSize: 10 }}>{t('user.captchaExpire',{time:timeLeft})}</Typography>
                                </div>

                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name='captcha'
                                    placeholder={t('user.captcha') + '*'}
                                    value={state?.captcha}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.captcha ? inputErrors.captcha : ''}
                                    error={inputErrors && inputErrors.captcha ? true : false}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ disableUnderline: true }}
                                    className={classes.textFieldStyle}
                                />
                            </Grid>
                        </Grid>
                    }
                </>
                }

            </div>

            <div>
                {
                    _.includes(['/register'], current_path) ?
                        <div style={{ fontWeight: 500 }}>
                            <Box className="flex-sb-m p-t-15">
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 ,  '&.Mui-disabled':{ backgroundColor:'transparent', color:'#ffffff4a', background:'#ffffff12'} ,  backgroundColor:'white' , ':hover':{
                                        backgroundColor:'white'
                                        }
                                    }}
                                >
                                    {t('button.previous')}
                                </Button>
                                {activeStep === steps.length - 1 ? <Button variant="contained" onClick={handleSubmit}>
                                    {t('login.registerAcc')}
                                </Button> :
                                    <Button variant="contained" onClick={handleNext}>
                                        {t('button.next')}
                                    </Button>
                                }
                            </Box>
                        </div>
                        :
                        <div style={{ fontWeight: 500 }}>
                            <Box className="flex-sb-m p-t-15">
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{
                                        mr: 1, '&.Mui-disabled': { backgroundColor: 'transparent', color:'#ffffff4a', background:'#ffffff12' }, backgroundColor: '#2f3133', ':hover': {
                                            backgroundColor: '#5c5c5c'
                                        }
                                    }}
                                >
                                    {t('button.previous')}
                                </Button>
                                {activeStep === steps.length - 1 ? <Button variant="contained" onClick={handleSubmit}>
                                    {t('login.registerAcc')}
                                </Button> :
                                    <Button variant="contained" onClick={handleNext}>
                                        {t('button.next')}
                                    </Button>
                                }
                            </Box>
                        </div>
                }
                
            </div>
        </>
    )
});

export default RegisterForm;

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    contentBox: {
        paddingTop: 320,
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
}));