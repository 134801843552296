import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import moment from 'moment-timezone';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import {  Typography, Hidden } from '@mui/material';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import { storePaths , currencyFormat } from '@utils/Tools';

import { useTheme } from '@mui/styles';

import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

export default function BonusProfitBalance() {
    const { t } = useTranslation();
    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();

    const { addAlert, setLoading } = useNotificationLoading();

    const [profitLimit, setProfitLimit] = useState({});

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } })
    };

    useEffect(() => {
        setLoading(true);
        getUrl(`/member-bonuses`).then(response => {
            if (response.status) {
                setProfitLimit(response.profit.total_profit_limit);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return (
        <div className=' gradient-gold-border m-b-50 p-tb-20 p-lr-30 flex-sb'>

            <div className='gradient-gold-border m-r-20 p-all-10' style={{ height: 65, borderRadius: '50%', aspectRatio: '1/1', alignContent: 'center',}}>
                <img src='/images/logos/logo-white.png' alt='logo' className='w-full bor-15' style={{ objectFit: 'contain'}} loading='lazy' />
            </div>
                
            <div style={{ width: '-webkit-fill-available'}} >

                <div className='flex-sb-m'>
                    <Typography variant='body2' style={{fontWeight: 500 }}>{t('wallet.accumulateBonus')}</Typography>
                    <div className='txt-center flex-m pointer text-gold-short' onClick={() => goToLink('/funds/bonus')}>
                        <Hidden lgDown>
                            <Typography variant='body2' >{ t('general.viewMore')}</Typography>
                        </Hidden>
                        <KeyboardArrowRightOutlinedIcon className='txt-theme' />
                    </div>
                </div>

                <div>
                    <p className='text-gold fs-25' style={{ fontWeight: '700'}}>
                        $ { currencyFormat(profitLimit?.profit_limit_balance || 0, 2)}
                    </p>
                </div>

            </div>

        </div>
    );
}