import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';

import { Box, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';

const WalletItem = props => {
    const { walletName, walletCode, walletValue, walletDecimal, walletType, background, labelColor, urlLink , clickable } = props;
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box className={`${styles.wallet} gradient-gold-bg bor-15 pos-relative`} style={{  backgroundSize: 'cover', height: '160px', overflow:'hidden' }}>
        {/* background: `${background} center fixed`, */}
            <div className='w-full h-full p-all-15 pos-relative bg-base bor-20'>
                <div className='txt-right w-full'>
                    <Typography className='text-gold-short' style={{ fontSize: '29px', lineHeight: 'unset', letterSpacing: '4px' }}><b>{walletCode}</b></Typography>
                    <Typography style={{ color: labelColor, fontSize: '12px', marginTop: '-8px', fontWeight: 500 }}><b>{walletName}</b></Typography>
                </div>

                {/* Amount and Font of Wallet Balance */}
                <div className='flex-col m-t-20'>
                    <Typography
                        variant="h6"
                        style={{
                            color: labelColor,
                            wordBreak: 'break-all',
                            fontSize: '23px'
                        }}
                        className='text-gold-short'>
                        {
                            _.includes(['BTA', 'BTP', 'BT2'], walletCode) ?
                                <b>{walletValue ? currencyFormat(walletValue, walletDecimal) : currencyFormat(0, walletDecimal)} {walletType} {t('general.unit')} </b>
                                // <b>{walletValue ? (parseFloat(walletValue)).toFixed(walletDecimal) : (parseFloat(0)).toFixed(walletDecimal)} {walletType} {t('general.unit')} </b>
                                :
                                <b>$ {walletValue ? currencyFormat(walletValue, walletDecimal) : currencyFormat(0, walletDecimal)} {walletType}</b>
                            // <b>$ {walletValue ? (parseFloat(walletValue)).toFixed(walletDecimal) : (parseFloat(0)).toFixed(walletDecimal)} {walletType}</b>
                        }

                    </Typography>

                    <Typography style={{ color: labelColor, textTransform: 'capitalize', fontSize: '14px', fontWeight: 500 }}>
                        {t('wallet.balance')}
                    </Typography>
                </div>
            </div>
            <div className='pos-absolute bg-blur-brown'></div>
        </Box>
    )
}

const useStyles = makeStyles((theme, clickable) => ({
    wallet: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-start', 
        justifyContent: 'space-between', 
        height: '100%', 
        padding: 2, 
        borderRadius: 20, 
        overflow: 'hidden', 
        // marginTop: '20px', 
        // border: '1px solid #E7E7E7', 
        cursor: clickable? 'pointer': 'default',
    }
}));

// DEFINE DEFAULT PROPS TYPE
WalletItem.propTypes = {
    walletName: PropTypes.string,
    walletLogo: PropTypes.string,
    walletValue: PropTypes.string,
    walletDecimal: PropTypes.number,
    background: PropTypes.string,
    labelColor: PropTypes.string,
    clickable: PropTypes.bool,
};

// DEFINE DEFAULT PROPS VALUE
WalletItem.defaultProps = {
    walletName: '',
    walletLogo: "",
    walletValue: '0',
    walletDecimal: 0,
    background: 'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box',
    labelColor: '#ffffff',
    clickable: true,
};

export default WalletItem;