import React, { useState , useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, IconButton, InputAdornment, TextField, Typography, Drawer } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiEye, FiEyeOff, FiKey, FiLock } from "react-icons/fi";

import { storeProfile } from '@actions';
import { putUrl , getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import TwoFA from '@components/TwoFA';

const INITIAL_STATE = {
    cryptoAddress: "",
    // walletType: "",
    securityPassword: "",
    authenticationCode: "",
};

const CryptoAddress = () => {
    const styles = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { wallets } = useSelector( state => state.user );
    const [state, setState] = useState(INITIAL_STATE);
    const [inputErrors, setInputErrors] = useState({});
    const [twoFASetting, setTwoFASetting] = useState(false);
    const [open, setOpen] = useState(false);

    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });
    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    useEffect(() => {
        setLoading(true);
        getApi();

        // setLoading(false);
    }, []);

    const getApi= () => {
        getUrl( 'get-setting-value' ).then(response => {
            setLoading(false)
            if(response.status){
                setTwoFASetting(response.twofa)
            }
        }).catch(error => {
            setLoading(false)
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        ;
    }

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleOpenDrawer = () => {
        if (state.cryptoAddress !== '' && state.securityPassword) {
            setOpen(true);
        } else {
            addAlert('', t('error.emptyCryptoSecurity'), 'error', '');
        }
    }

    const nextFunction = () => {
        return twoFASetting ? handleOpenDrawer : updateCryptoAddress ;
    }

    // submit data
    const updateCryptoAddress = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            crypto_address: state?.cryptoAddress,
            // wallet_type: state.walletType,
            security_password: state?.securityPassword,
            authentication_code: state?.authenticationCode,
        }
        putUrl(`/user/crypto-address`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setOpen(false);
                dispatch(storeProfile(response.data));
                setState({ cryptoAddress: "", walletType: "", securityPassword: "", authenticationCode: "" });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                let message = '';

                if (response.errors) {
                    if (response.errors.crypto_address || response.errors.security_password) {
                        setOpen(false);
                        message = t('error.editError');
                    } else {
                        message = response.errors.authentication_code;
                    }
                } else {
                    message = response.message;
                }
                addAlert('', message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            setOpen(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        ;
    }

    return (
       <div className='dashboard-container'>
        <div className='dashboard-w'>
            <div className='txt-center p-b-30'>
                <Typography variant='h6' className='text-gold' style={{ fontWeight: 'bold', lineHeight: 1.1 }} >{t('profile.cryptoAddress')}</Typography>
                <p className='txt-subtitle'>{t('profile.cryptoDesc')}</p>
            </div>
            
            <div className='pos-relative' style={{zIndex:2}}>
                <TextField
                    variant="standard"
                    fullWidth
                    name="cryptoAddress"
                    placeholder={t('profile.newCryptoAddress')}
                    value={state.cryptoAddress || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.crypto_address ? inputErrors.crypto_address : ''}
                    error={inputErrors && inputErrors.crypto_address ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <FiKey />
                            </InputAdornment>
                        ),
                    }}
                />
                <div className='p-t-10 txt-theme fs-13' style={{fontWeight:500}}>
                    <p>{t('withdraw.onlyAcceptBsc')}</p>
                </div>
                {
                    wallets?.general?.address &&
                    <div className='p-t-10 txt-theme fs-13' style={{fontWeight:500}}>
                        <p>{t('profile.currentCryptoAddress')} {wallets?.general?.address}</p>
                    </div>
                }
                <TextField
                    variant="standard"
                    fullWidth
                    name="securityPassword"
                    placeholder={t('profile.currentSecurityPassword')}
                    type={showPassword.securityPassword ? 'text' : 'password'}
                    value={state.securityPassword || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                    error={inputErrors && inputErrors.security_password ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <FiLock />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => handleClickShowPassword('securityPassword')}
                                >
                                    {showPassword.securityPassword ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    className={styles.textFieldBox}
                    style={{ paddingTop: 20 }}
                />
                {/* <TextField
                    variant="standard"
                    fullWidth
                    name="walletType"
                    placeholder={t('profile.walletType')}
                    value={state.walletType || ''}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors.wallet_type ? inputErrors.wallet_type : ''}
                    error={inputErrors && inputErrors.wallet_type ? true : false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <FiKey />
                            </InputAdornment>
                        ),
                    }}
                    className={styles.textFieldBox}
                /> */}
                <div className='flex-c-m m-t-20'>
                    <Button 
                    variant="contained" 
                    onClick={ nextFunction() } 
                    className='big-button' 
                    fullWidth
                    >
                        {t('button.update')}
                    </Button>
                </div>
            </div>
            
            <TwoFA 
                inputErrors={inputErrors?.authentication_code}  
                open={open}  
                onClose ={() => setOpen(false)} 
                value={ state?.authenticationCode || '' }
                handleChange={handleChange}
                onClick={updateCryptoAddress}
            />

            {/* <Drawer
                anchor={'bottom'}
                open={open}
                onClose={() => setOpen(false)}
                disableScrollLock={true}
                classes={{ paper: styles.paperAnchorBottom }}
            >
                <div className='p-all-30'>
                    <div className='dis-flex flex-m flex-sb-m p-b-20'>
                        <Typography className='w-full' style={{ color: 'rgba(82, 82, 82, 0.80)' }}><b>{t('profile.enterTwoFactorAuth')}</b></Typography>
                    </div>

                    <div className='p-b-50'>
                        <TextField
                            helperText={inputErrors && inputErrors.authentication_code ? inputErrors.authentication_code : ''}
                            error={inputErrors && inputErrors.authentication_code ? true : false}
                            placeholder={t('user.authenticationCode')}
                            variant="standard"
                            type="number"
                            fullWidth
                            value={state?.authenticationCode || ''}
                            name="authenticationCode"
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ disableUnderline: true }}
                        />
                    </div>

                    <Button variant='contained' className='big-button' style={{ marginTop: 20 }} fullWidth onClick={updateCryptoAddress}>
                        {t('button.update')}
                    </Button>
                </div>
            </Drawer> */}
        </div>
       </div>
    )
}

const useStyles = makeStyles(theme => ({

    textFieldBox: {
        marginBottom: 20
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        // maxWidth: 444,
        overflow: 'hidden',
        margin:'0 auto',
        borderRadius:'30px 30px 0px 0px'
    },
}));

export default CryptoAddress