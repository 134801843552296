import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

// import { Box, Button, Grid, Typography, IconButton } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';

// import { currencyFormat } from '@utils/Tools';
// import { storePaths } from '@utils/Tools';
// import moment from 'moment';

// import CloseIcon from '@mui/icons-material/Close';
// import { FaCheck, FaArrowCircleDown } from "react-icons/fa";

const Frame = props => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const { type, data, completedLink } = props;

    return (
        <>
            {/* // top right */}
            <div className='pos-absolute frame-size' style={{ top: 15, right: 15 }}>
                <img src='/images/auth/frame.png' alt="frame" className='w-full' />
            </div>
            {/* // top left */}
            <div className='pos-absolute frame-size' style={{ top: 15, left: 15, transform: 'scaleX(-1)' }}>
                <img src='/images/auth/frame.png' alt="frame" className='w-full' />
            </div>
            {/* // bottom left */}
            <div className='pos-absolute frame-size' style={{ bottom: 15, left: 15, transform: 'scaleX(-1) scaleY(-1)' }}>
                <img src='/images/auth/frame.png' alt="frame" className='w-full' />
            </div>
            {/* // bottom right */}
            <div className='pos-absolute frame-size' style={{ bottom: 15, right: 15, transform: 'scaleY(-1)' }}>
                <img src='/images/auth/frame.png' alt="frame" className='w-full' />
            </div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
  
}));

export default Frame;