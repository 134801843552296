import _ from 'lodash';
import React, { useState , useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { 
    useSearchParams, 
    // useNavigate , 
    useParams 
} from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { 
    Box, 
    Button, 
    Grid, 
    // IconButton, 
    // InputAdornment, 
    // TextField, 
    Typography , 
    Link 
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { FiEye, FiEyeOff } from "react-icons/fi";
// import { MdLock, MdEmail } from "react-icons/md";

import { postUrl , getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

export default function VerifyPage() {
    const INITIAL_STATE = {

    };

    const { t } = useTranslation();
    const classes = useStyles();
    // const [searchParams] = useSearchParams();
    // let navigate = useNavigate();
    // const [inputErrors, setInputErrors] = useState();
    const [state, setState] = useState(INITIAL_STATE);

    const { addAlert, setLoading } = useNotificationLoading();
    const [account, setAccount] = useState();
    let { id , hash } = useParams();
    
    useEffect(() => {
        setLoading(true);
        getUrl(`/verifymail/${id}/${hash}`).then(response => {
            setLoading(false);
            setAccount(response.status)
            if (response.status) {
                addAlert("", t('success.activationSuccess'), 'success', '');
            } else {
                addAlert("", t('genealogy.notFoundUsername'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        // eslint-disable-next-line
    }, []);    

    return (
        <Box style={{ minHeight: '100vh', position: "relative", overflowY: 'hidden' }}>
            
            <Box style={{ position: "absolute" }}>
                <img src="../../images/auth/login-bg.png" style={{ width: "100%" }} alt="bg-img" loading='lazy' />
            </Box>

            <Box className={classes.contentBox} style={{ position: "absolute" }}>
                
                {
                    account != null && account !== undefined  && 
                    (
                        account ?
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                <Typography variant="h6" className={classes.titleStyle} style={{margin: 40}}>{t(`success.accountActive`)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Link underline='none' to={`/login`} component={RouterLink}>
                                    <Button variant="contained" color="primary"  className='big-button' style={{ marginTop: 20 , width: '50%' }} fullWidth>
                                        {t(`title.login`)}
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    :
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                <Typography variant="h6" className={classes.titleStyle} style={{margin: 40}}>{t(`error.notFoundRequest`)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Link underline='none' to={`/login`} component={RouterLink}>
                                    <Button variant="contained" color="primary"  className='big-button' style={{ marginTop: 20 , width: '50%' }} fullWidth>
                                        {t(`button.backToHomepage`)}
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    )
                }
                
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    contentBox: {
        textAlign: "center",
        paddingTop: '70%',
        width: '100%',
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
}));