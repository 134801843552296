import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Typography, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

export default function MerchantRequest(props) {
    const { checked, handleChange, userAgreement } = props;
    const scrollableRef = useRef(null);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    const { t } = useTranslation();

    const theme = useTheme();
    const styles = useStyles();

    const handleScroll = () => {
        const element = scrollableRef.current;
        if (element) {
            const isBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 1;
            setIsScrolledToBottom(isBottom);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div className={`${styles.boxDesign} box-out`}>
                <div style={{ overflowY: 'scroll', height: '100%' }}>
                    <Typography variant='body2' style={{ color: theme.palette.primary.secondary }}><b>{t('merchant.docDetail')}</b></Typography>
                    <ul style={{ margin: 0 }}>
                        <li><Typography variant='body2' style={{ color: theme.palette.primary.secondary }}>{t('merchant.copySsm58')}</Typography></li>
                        <li><Typography variant='body2' style={{ color: theme.palette.primary.secondary }}>{t('merchant.copySsm78')}</Typography></li>
                        <li><Typography variant='body2' style={{ color: theme.palette.primary.secondary }}>{t('merchant.copyLicence')}</Typography></li>
                        <li><Typography variant='body2' style={{ color: theme.palette.primary.secondary }}>{t('merchant.copyHalal')}</Typography></li>
                        <li><Typography variant='body2' style={{ color: theme.palette.primary.secondary }}>{t('merchant.copyOthers')}</Typography></li>
                    </ul>
                </div>
            </div>

            <FormControlLabel
                style={{ paddingTop: 10 }}
                control={<Checkbox name='check' checked={checked} onChange={handleChange} />}
                label={<Typography variant='body2' className='txt-left' style={{ color: theme.palette.primary.secondary }}>
                    <b>{t('merchant.terms')}</b>
                </Typography>}
            />

            <div className={`${styles.boxDesign} box-out`}>
                <div className='p-10' style={{ overflowY: 'scroll', height: '100%', color: theme.palette.primary.secondary }} ref={scrollableRef} onScroll={handleScroll}>
                    <p style={{ marginTop: '30px', display: 'flex' }}>九方城用户协议与条款 本协议由9M World Pte Ltd., 属下＂九方城＂（以下简称“平台”）制定，适用于在平台运营的商城“九方城”。</p>
                    <p>此协议明确规范卖家、买家与平台之间的权利和义务，旨在为用户提供一个公平、透明、安全的交易环境。注册或使用本平台前，请务必仔细阅读以下条款。
                        如您继续使用平台，即视为完全接受本协议内容。 </p>
                    <b style={{ marginTop: '30px', display: 'flex' }}>第一部分：通用条款  </b>
                    <ol>
                        <li>
                            <strong>协议适用范围 </strong>
                            <ul>
                                <li>本协议适用于所有在“九方城”注册并获得批准的买家和卖家。 </li>
                                <li>平台保留随时修改协议条款的权利，修订后的协议自平台公告发布之日起生效。</li>
                            </ul>
                        </li>
                        <li>
                            <strong>会员资格 </strong>
                            <ul>
                                <li>仅限已注册并通过身份验证（KYC）的会员使用本商城。</li>
                                <li>买家与卖家需提交本人身份证明文件及清晰的持证照片，确保身份信息真实有效。</li>
                            </ul>
                        </li>
                        <li>
                            <strong>账户安全</strong>
                            <ul>
                                <li>用户须妥善保管账号信息，不得转让或出租账号。因用户疏忽造成的损失由用户自行承担。</li>
                                <li>平台保留因不正常行为（如欺诈、违规交易等）暂停或封禁账户的权利。</li>
                            </ul>
                        </li>
                    </ol>

                    <b style={{ marginTop: '30px', display: 'flex' }}>第二部分：卖家条款</b>
                    <ol>
                        <li>
                            <strong>注册与资格</strong>
                            <ul>
                                <li>卖家需满足以下条件：</li>
                                <ul>
                                    <li>完成实名认证（KYC）；</li>
                                    <li>拥有100U配套资格；</li>
                                    <li>提交产品信息并经平台审核批准。</li>
                                    <li>违反规定或提交虚假信息的账户将被封禁，平台保留追究法律责任的权利。</li>
                                </ul>
                            </ul>
                        </li>
                        <li>
                            <strong>商品发布与定价</strong>
                            <ul>
                                <li>卖家发布商品需如实填写相关信息（名称、描述、图片、价格等）。</li>
                                <li>商品定价可使用两种货币：</li>
                                <ul>
                                    <li>MP积分（最低10%，最高100%）；</li>
                                    <li>USDT（最多90%）。</li>
                                </ul>
                                <li>平台严禁发布以下商品：违法商品、假冒伪劣产品、不符合平台规定的物品等。</li>
                            </ul>
                        </li>
                        <li>
                            <strong>交易规则</strong>
                            <ul>
                                <li>买卖双方可协商交易细节（如价格、送货方式等），平台仅提供交易媒介服务。</li>
                                <li>如通过平台担保交易，平台将保持中立并保护买卖双方权益。</li>
                            </ul>
                        </li>
                        <li>
                            <strong>服务费用</strong>
                            <ul>
                                <li>卖家需缴纳平台服务费用，具体标准以公告为准。 </li>
                                <li>如因违规导致交易失败，相关费用不予退还。 </li>
                            </ul>
                        </li>
                    </ol>

                    <b style={{ marginTop: '30px', display: 'flex' }}>第三部分：买家条款</b>
                    <ol>
                        <li>
                            <strong>注册与认证</strong>
                            <ul>
                                <li>买家需完成实名认证，确保身份信息真实有效。</li>
                                <li>买家需在平台申请账户并通过审核后，方可进行交易。</li>
                            </ul>
                        </li>
                        <li>
                            <strong>交易与支付</strong>
                            <ul>
                                <li>买家可使用以下方式支付：</li>
                                <ul>
                                    <li>MP积分（最低10%，最高100%）；</li>
                                    <li>USDT（最多90%）。</li>
                                </ul>
                                <li>买家应确保支付信息真实有效，禁止虚假交易或恶意拒付。</li>
                            </ul>
                        </li>
                        <li>
                            <strong>收货与售后</strong>
                            <ul>
                                <li>买家需提供准确的收货地址并配合卖家完成交易。</li>
                                <li>如对商品存在争议，应及时与卖家协商，必要时可申请平台介入。</li>
                            </ul>
                        </li>
                    </ol>

                    <h2>第四部分：平台条款</h2>
                    <ol>
                        <li>
                            <strong>平台权利</strong>
                            <ul>
                                <li>审核、拒绝或删除违规商品或账户；</li>
                                <li>调查并处理恶意行为，包括但不限于警告、限制交易、封号等；</li>
                                <li>对涉及平台担保的交易提供中立裁定。</li>
                            </ul>
                        </li>
                        <li>
                            <strong>平台责任</strong>
                            <ul>
                                <li>平台仅为用户提供交易媒介服务，不直接参与或担保交易商品的合法性、真实性及品质；</li>
                                <li>平台在交易过程中提供数据保护及隐私管理，但对因不可抗力（如网络攻击等）造成的损失不承担责任。</li>
                            </ul>
                        </li>
                        <li>
                            <strong>争议解决</strong>
                            <ul>
                                <li>买卖双方交易中产生争议时，平台将根据双方提交的证据进行裁定。裁定结果为最终决定，用户需予以接受。</li>
                                <li>平台裁定不影响用户通过法律途径解决争议的权利。</li>
                            </ul>
                        </li>
                    </ol>

                    <h2>第五部分：违规处理</h2>
                    <ol>
                        <li>
                            <strong>违规行为包括但不限于以下内容：</strong>
                            <ul>
                                <li>发布虚假商品信息；</li>
                                <li>买卖双方勾结进行欺诈；</li>
                                <li>违反国家法律法规的行为；</li>
                                <li>滥用积分或平台规则进行套利。</li>
                            </ul>
                        </li>
                        <li>
                            <strong>违规处理措施：</strong>
                            <ul>
                                <li>首次违规给予警告并要求整改；</li>
                                <li>累计两次以上违规行为直接封号；</li>
                                <li>情节严重者追究法律责任。</li>
                            </ul>
                        </li>
                    </ol>

                    <h2>第六部分：法律适用与争议解决</h2>
                    <ol>
                        <li>
                            <strong>适用法律</strong>
                            <ul>
                                <li>本协议受平台所在国家的法律管辖。</li>
                            </ul>
                        </li>
                        <li>
                            <strong>争议解决</strong>
                            <ul>
                                <li>用户与平台之间如产生争议，首先应通过协商解决；协商不成的，可提交平台所在地法院管辖。</li>
                            </ul>
                        </li>
                    </ol>

                    <h2>第七部分：其他条款</h2>
                    <ol>
                        <li>
                            <strong>协议的生效与变更 </strong>
                            <ul>
                                <li>本协议自用户注册并获得批准时生效。</li>
                                <li>平台保留根据业务需求随时更新条款的权利。</li>
                            </ul>
                        </li>
                        <li>
                            <strong>解释权归属</strong>
                            <ul>
                                <li>本协议的最终解释权归 9M World Pte Ltd. 所有。</li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </div>

            <FormControlLabel
                style={{ paddingTop: 10 }}
                control={<Checkbox name='use_agreement' checked={userAgreement} onChange={handleChange} disabled={!isScrolledToBottom} />}
                label={<Typography variant='body2' className='txt-left' style={{ color: theme.palette.primary.secondary }}>
                    <b>{t('merchant.userAgreementTerm')}</b>
                </Typography>}
            />

        </div>
    )
}

const useStyles = makeStyles(theme => ({
    boxDesign: {
        width: '100%',
        height: 250,
        borderRadius: 10,
        textAlign: 'left',
        padding: '20px 30px',
    }
}));