import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, Button, Drawer, TextField, Typography, IconButton, InputAdornment, FormLabel, FormControl, FormControlLabel, Radio, RadioGroup, Dialog, DialogContent, DialogActions } from '@mui/material';

import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { currencyFormat } from '@utils/Tools';

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useSearchParams, useNavigate } from 'react-router-dom';

import MerchantItem from '@components/MerchantItem';
import TwoFA from '@components/TwoFA';

const Transfer = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    const { addAlert, setLoading } = useNotificationLoading();

    // const [completed, setCompleted] = useState(false);
    const [state, setState] = useState({
        current_price: '',
        merchant_id: '',
        username: '',
        amount: '',
        remark: '',
        security_password: '',
        authentication_code: '',
        showPassword: false,
    });
    const [confirmTransferDialog, setConfirmTransferDialog] = useState({
        open : false,
    })
    const [passiveUnit, setPassiveUnit] = useState(0);
    const [activeUnit, setActiveUnit] = useState(0);
    const [totalRedeemableValue, setTotalRedeemableValue] = useState(0);
    const [merchantData, setMerchantData] = useState([]);
    const [currentPrice, setCurrentPrice] = useState(0.000);
    const [searchParams] = useSearchParams();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [inputErrors, setInputErrors] = useState();
    // const [transaction, setTransaction] = useState([]);
    const [twoFASetting, setTwoFASetting] = useState(false);
    const [selectedOption, setSelectedOption] = useState('unit');

    useEffect(() => {
        setLoading(true);
        callApi()
        // eslint-disable-next-line
    }, [searchParams]);
    
    const handleChange = ({ target }) => {
        const { name, value } = target;
        if (name === 'amount') {
            let newValue = value;
            newValue = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            if (selectedOption === 'value') 
            {
                const regex = /^\d*\.?\d{0,2}$/;
                if (regex.test(newValue)) {
                    setState(prevState => ({ ...prevState, [name]: newValue }));
                }
            } 
            else 
            {
                const regex = /^\d*$/;
                if (regex.test(newValue)) {
                    setState(prevState => ({ ...prevState, [name]: newValue }));
                }
            }
        }
        else
        {
            setState({ ...state, [name]: value });
        }
    }

    const handleMaxButtonClick = () => {
        // let maxValue;
        // if (selectedOption === 'unit') {
        //     maxValue = minTotalUnit;
        //     // maxValue = currencyFormat(minTotalUnit, 0);
        // } else {
        //     maxValue = minTotalValue;
        //     // maxValue = currencyFormat(minTotalValue, 2);
        // }
        // setState({ ...state, amount: maxValue });
        setState({ ...state, amount: selectedOption === 'unit' ? minTotalUnit : minTotalValue });
        // setState({ ...state, amount: selectedOption === 'unit' ? currencyFormat(minTotalUnit, 0) : currencyFormat(minTotalValue, 2) });
    };

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
        setState({...state, amount: ''});
    };
    
    const callApi = () => {
        if (typeof searchParams.get("m") !== 'string') {
            setLoading(false);
            navigate(`/`);
            return;
        }
        
        try {
            let merchant_id = parseInt(atob(searchParams.get("m")));
            
            if (isNaN(merchant_id)) {
                setLoading(false);
                navigate(`/`);
                return;
            }
        
            setState({
                ...state,
                merchant_id: merchant_id,
            });
        
            let apiData = {
                merchant_id: merchant_id,
            };

            getUrl( 'get-setting-value' ).then(response => {
                setLoading(false)
                if(response.status){
                    setTwoFASetting(response.twofa)
                }
            }).catch(error => {
                setLoading(false)
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            })

            if(merchant_id === 0){
                getUrl(`/get-redeem-data`, apiData)
                .then((response) => {
                    setLoading(false);
                    if (response.status) {
                        setPassiveUnit(response.data.passive_unit);
                        setActiveUnit(response.data.active_unit);
                        setTotalRedeemableValue(response.data.total_redeemable_value);
                        setCurrentPrice(response.data.current_price);
                    } else {
                        navigate(`/`);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                    navigate(`/`);
                });
            }

            getUrl(`/get-redeem-data`, apiData)
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    setPassiveUnit(response.data.passive_unit);
                    setActiveUnit(response.data.active_unit);
                    setMerchantData(response.data.merchant_data);
                    setCurrentPrice(response.data.current_price);
                } else {
                    navigate(`/`);
                }
            })
            .catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
                navigate(`/`);
            });
        } catch (error) {
            setLoading(false);
            navigate(`/`);
        }
    };      

    const closeDrawer = () => {
        setDrawerOpen(false);
        setState({
            ...state,
            username: '',
            amount: '',
            remark: '',
            security_password: '',
            showPassword: false,
        });
        // Clear input errors
        setInputErrors(null);
    };   

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const insertSecurityPwd = () => {
        if (state.username && state.amount && state.remark && state.security_password) {
            setDrawerOpen(true);
        } else {
            addAlert('', t('transfer.insertAmountRemark'), 'error', '');
            // Close the drawer
            setDrawerOpen(false);
        }
    };
    
    const confirmTransfer = () => {
        setLoading(true);
        setConfirmTransferDialog({...confirmTransferDialog, open:false});
        setInputErrors();
        let params = {
            username: state?.username,
            merchant_id: state?.merchant_id,
            type: selectedOption,
            // redeemable_value: selectedOption === 'value' ? (state?.amount).replace(/,/g, '') : 0,
            // transfer_unit: selectedOption === 'unit' ? (state?.amount).replace(/,/g, '') : 0,
            amount: state?.amount,
            // amount: (state?.amount).replace(/,/g, ''),
            remark: state?.remark,
            security_password: state?.security_password,
            authentication_code: state?.authentication_code,
        }
        
        postUrl(`/trade/transfer`, params).then(response => {
            setLoading(false);
            if (response.status) {
                // setCompleted(true);
                // setTransaction(response.data);
                callApi();
                closeDrawer();
                addAlert('', t('success.transferSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
    
                // Check for specific errors and close the drawer accordingly
                if (
                    (!state.username || !state.amount || !state.remark || !state.security_password) ||
                    (response.errors && (response.errors.username || response.errors.amount || response.errors.remark || response.errors.security_password))
                ) {
                    setDrawerOpen(false);
                }                   
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    };    

    const userUnit = passiveUnit + activeUnit || 0;
    const totalValue = state.merchant_id === 0 ? totalRedeemableValue.total_redeemable_value || 0.00 : (merchantData?.total_redeemable_value || 0.00);
    const totalUnit =  totalValue / currentPrice || 0;
    const minTotalUnit = Math.min(totalUnit, userUnit) || 0;

    const userValue = userUnit * currentPrice || 0.00;
    const minTotalValue = Math.min(totalValue, userValue) || 0.00;

    return (
        <>
            <div className='dashboard-container'>
                <div className='dashboard-w'>
                    <div className='pos-relative' style={{zIndex:2}}>
                        <Box>
                            <Typography variant='h6' style={{ textAlign: 'center', color: theme.palette.darkBase.main, fontWeight: 700, fontSize: 25 }} >
                                <b>
                                    {t('title.transfer')}
                                </b>
                            </Typography>
                            <div>
                                <Box className="m-tb-30">
                                    <MerchantItem
                                        merchantCode={merchantData?.code}
                                        currentPrice={Number(currentPrice)}
                                        totalValue={currencyFormat(Number(totalValue), 2)}
                                        totalUnit={currencyFormat(totalUnit, 0)}
                                        merchantName={merchantData?.name ? (merchantData?.name?.[i18n.language] ? merchantData?.name?.[i18n.language] : merchantData?.name?.cn) : ''}
                                    />
                                </Box>
                            </div>
                            <FormControl>
                                <RadioGroup row value={selectedOption} onChange={handleRadioChange}>
                                    <FormControlLabel 
                                        value="unit" 
                                        control={<Radio />} 
                                        label={t('general.unit')} 
                                        sx={{ 
                                            marginRight: 3,
                                            '& .MuiTypography-root': { 
                                                color: theme.palette.gray.main, 
                                                fontWeight: 400, 
                                                fontSize: 17 
                                            } 
                                        }}
                                    />
                                    <FormControlLabel 
                                        value="value" 
                                        control={<Radio />} 
                                        label={t('general.value')} 
                                        sx={{ 
                                            '& .MuiTypography-root': { 
                                                color: theme.palette.gray.main, 
                                                fontWeight: 400, 
                                                fontSize: 17 
                                            } 
                                        }}
                                    />
                                </RadioGroup>
                            </FormControl>
                            
                            <Box>
                                <TextField
                                    placeholder={t('transfer.redeemValue')}
                                    variant='standard'
                                    fullWidth
                                    autoComplete='amount'
                                    name='amount'
                                    value={ state?.amount }
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button variant="contained" className='max-button' onClick={handleMaxButtonClick}>
                                                    {t('general.max')}
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {
                                    inputErrors && (inputErrors.transfer_unit || inputErrors.redeemable_value) ? (
                                        <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>
                                            {[inputErrors.transfer_unit, inputErrors.redeemable_value].filter(Boolean).join(', ')}
                                        </Typography>
                                    ) : null
                                }
                                {
                                    inputErrors && inputErrors.amount
                                        ?
                                        <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.amount}</Typography>
                                        :
                                        null
                                }

                                <Box display="flex" justifyContent="space-between" style={{ marginTop: "10px" }}>
                                    <Typography style={{ fontSize: "13px", fontWeight: 400, color: theme.palette.darkGray.main }}>
                                        {t('trade.availableUnit')}: {currencyFormat(userUnit, 0)} <br></br>
                                        ≈ ${currencyFormat(userValue, 2)}
                                    </Typography>
                                    <Typography style={{ fontSize: "13px", fontWeight: 400, color: theme.palette.darkGray.main }}>
                                        {selectedOption === 'unit' ? t('trade.max_trading_unit') : t('trade.max_trading_value')}: {selectedOption === 'unit' ? currencyFormat(minTotalUnit, 0) : `$${currencyFormat(minTotalValue, 2)}`}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <TextField
                                    placeholder={t('transfer.transferTo')}
                                    variant='standard'
                                    fullWidth
                                    autoComplete='username'
                                    name='username'
                                    value={state?.username}
                                    onChange={({ target }) => setState({ ...state, username: target.value.toLowerCase()})}
                                />
                                {
                                    inputErrors && inputErrors.username
                                        ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.username}</Typography>
                                        : inputErrors?.allow_self
                                            ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.allow_self}</Typography>
                                            : inputErrors?.allow_others
                                                ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.allow_others}</Typography>
                                                : null
                                }
                            </Box>
                            <Box style={{ margin: "20px 0px" }}>
                                <TextField
                                    placeholder={t('transfer.remarkNote')}
                                    variant='standard'
                                    fullWidth
                                    autoComplete='remark'
                                    name='remark'
                                    value={state?.remark}
                                    onChange={({ target }) => setState({ ...state, remark: target.value })}
                                />
                                {
                                    inputErrors && inputErrors.remark
                                        ?
                                        <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.remark}</Typography>
                                        :
                                        null
                                }
                            </Box>
                            <Box style={{ margin: "20px 0px" }}>
                                <TextField
                                    placeholder={t('transfer.securityPassword')}
                                    variant='standard'
                                    fullWidth
                                    type={state.showPassword ? 'text' : 'password'}
                                    autoComplete='securityPassword'
                                    name='securityPassword'
                                    value={state?.security_password}
                                    onChange={({ target }) => setState({ ...state, security_password: target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {state.showPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {
                                    inputErrors && inputErrors.security_password
                                        ?
                                        <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.security_password}</Typography>
                                        :
                                        null
                                }
                            </Box>
                            
                            <Box className="flex-c-m">
                                <Button variant="contained" className='big-button w-full' onClick={twoFASetting ? insertSecurityPwd: ()=>setConfirmTransferDialog({...confirmTransferDialog,open:true})}>{t('title.transfer')}</Button>
                                {/* <Button variant="contained" className='big-button w-full' onClick={twoFASetting ? insertSecurityPwd : confirmTransfer}>{t('transfer.confirmRedeem')}</Button> */}
                            </Box>
                        </Box>

                        <TwoFA
                            inputErrors={inputErrors?.authentication_code}
                            open={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                            value={state?.authentication_code || ''}
                            handleChange={({ target }) => {
                                const numericInput = target.value.replace(/\D/g, '');
                                setState({ ...state, authentication_code: numericInput });
                            }}
                            onClick={confirmTransfer}
                        />

                        <Dialog open={confirmTransferDialog.open} onClose={() => setConfirmTransferDialog({...confirmTransferDialog, open:false})} fullWidth maxWidth='sm'>
                            <DialogContent>
                                {`${t('general.confirmMsg1')} ${t('general.confirmMsg2')}`}
                            </DialogContent>
                            <DialogActions>
                                <Button variant='contained' sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: theme.palette.darkBase.main,
                                        color: theme.palette.white.main,
                                    },
                                    "&.Mui-disabled": {
                                        backgroundColor: "#D9D9D9",
                                        color: "#ADADAD",
                                        boxShadow: '2px 2px 4px 0px #00000040 inset',
                                    },
                                }} onClick={() => setConfirmTransferDialog({...confirmTransferDialog, open:false})}>{t('button.cancel')}</Button>
                                <Button variant='contained' onClick={confirmTransfer}>{t('button.confirm')}</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    
}));

export default Transfer;