import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, Typography, IconButton, InputAdornment, Dialog, DialogContent, DialogActions } from '@mui/material';

import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { currencyFormat } from '@utils/Tools';

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useNavigate } from 'react-router-dom';

import TwoFA from '@components/TwoFA';
import SpecialTransferItem from '@components/SpecialTransferItem';

const SpecialTransfer = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    const { addAlert, setLoading } = useNotificationLoading();

    const [completed, setCompleted] = useState(false);
    const [state, setState] = useState({
        current_price: '',
        username: '',
        amount: '',
        remark: '',
        security_password: '',
        authentication_code: '',
        showPassword: false,
    });
    const [totalPassiveValue, setTotalPassiveValue] = useState(0);
    const [currentPrice, setCurrentPrice] = useState(0.000);
    const [approxValue, setApproxValue] = useState(0.00);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [inputErrors, setInputErrors] = useState();
    const [transaction, setTransaction] = useState([]);
    const [twoFASetting, setTwoFASetting] = useState(false);
    const [confirmTransferDialog, setConfirmTransferDialog] = useState({
        open : false,
    })

    useEffect(() => {
        setLoading(true);
        callApi();
    }, []);

    useEffect(() => {
        const value = parseFloat(state.amount || 0) * parseFloat(currentPrice || 0);
        setApproxValue(value || 0.00);
    }, [state.amount, currentPrice]);

    const callApi = () => {
        setLoading(true);
    
        getUrl(`/get-special-transfer`).then(response => {
            setLoading(false);
            if (response.status) {
                setTotalPassiveValue(response.data.passive_unit);
                setCurrentPrice(response.data.current_price);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl( 'get-setting-value' ).then(response => {
            setLoading(false)
            if(response.status){
                setTwoFASetting(response.twofa)
            }
        }).catch(error => {
            setLoading(false)
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    };    

    const handleMaxButtonClick = () => {
        let maxValue = totalPassiveValue;
        setState({ ...state, amount: maxValue });
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
        setState({
            ...state,
            username: '',
            amount: '',
            remark: '',
            security_password: '',
            showPassword: false,
        });
        // Clear input errors
        setInputErrors(null);
    };   

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const confirmTransfer = () => {
        setLoading(true);
        setInputErrors();
        setConfirmTransferDialog({...confirmTransferDialog, open:false});
        let params = {
            username: state?.username,
            merchant_id: state?.merchant_id,
            transfer_unit: state?.amount,
            remark: state?.remark,
            security_password: state?.security_password,
            authentication_code: state?.authentication_code,
        }
        
        postUrl(`/trade-special-transfer`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setCompleted(true);
                setTransaction(response.data);
                callApi();
                closeDrawer();
                addAlert('', t('success.transferSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
    
                // Check for specific errors and close the drawer accordingly
                if (
                    (!state.username || !state.amount || !state.remark || !state.security_password) ||
                    (response.errors && (response.errors.username || response.errors.amount || response.errors.remark || response.errors.security_password))
                ) {
                    setDrawerOpen(false);
                }                   
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    };    

    const userUnit = totalPassiveValue || 0;
    const userValue = userUnit / currentPrice || 0.00;
    
    return (
        <>
            <div className='dashboard-container'>
                <div className='dashboard-w'>
                    <div className='pos-relative' style={{zIndex:2}}>
                        <Box>
                            <Typography variant='h6' style={{ textAlign: 'center', color: theme.palette.darkBase.main, fontWeight: 700, fontSize: 25 }} >
                                <b>
                                    {t('title.transfer')}
                                </b>
                            </Typography>
                            <div>
                                <Box className="m-tb-30">
                                    <SpecialTransferItem
                                        currentPrice={Number(currentPrice)}
                                        totalValue={currencyFormat(Number(userValue), 2)}
                                        totalUnit={Number(userUnit)}
                                    />
                                </Box>
                            </div>
                            <Box>
                                <TextField
                                    placeholder={t('transfer.redeemUnit')}
                                    variant='standard'
                                    fullWidth
                                    autoComplete='amount'
                                    name='amount'
                                    value={state?.amount}
                                    onChange={({ target }) => setState({ ...state, amount: target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button className='max-button' onClick={handleMaxButtonClick} variant='contained'>
                                                    {t('general.max')}
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {
                                    inputErrors && (inputErrors.transfer_unit) ? (
                                        <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>
                                            {[inputErrors.transfer_unit].filter(Boolean).join(', ')}
                                        </Typography>
                                    ) : null
                                }
                                <Box style={{ marginBottom: "10px" }}>
                                    <Typography style={{ fontSize: "13px", fontWeight: 400, color: theme.palette.darkGray.main, textAlign: 'end' }}>
                                        {t('trade.approxValue')}: ${currencyFormat(approxValue, 2)}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <TextField
                                    placeholder={t('transfer.transferTo')}
                                    variant='standard'
                                    fullWidth
                                    autoComplete='username'
                                    name='username'
                                    value={state?.username}
                                    onChange={({ target }) => setState({ ...state, username: target.value.toLowerCase()})}
                                />
                                {
                                    inputErrors && inputErrors.username
                                        ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.username}</Typography>
                                        : inputErrors?.allow_self
                                            ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.allow_self}</Typography>
                                            : inputErrors?.allow_others
                                                ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.allow_others}</Typography>
                                                : null
                                }
                            </Box>
                            <Box style={{ margin: "20px 0px" }}>
                                <TextField
                                    placeholder={t('transfer.remarkNote')}
                                    variant='standard'
                                    fullWidth
                                    autoComplete='remark'
                                    name='remark'
                                    value={state?.remark}
                                    onChange={({ target }) => setState({ ...state, remark: target.value })}
                                />
                                {
                                    inputErrors && inputErrors.remark
                                        ?
                                        <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.remark}</Typography>
                                        :
                                        null
                                }
                            </Box>
                            <Box style={{ margin: "20px 0px" }}>
                                <TextField
                                    placeholder={t('transfer.securityPassword')}
                                    variant='standard'
                                    fullWidth
                                    type={state.showPassword ? 'text' : 'password'}
                                    autoComplete='securityPassword'
                                    name='securityPassword'
                                    value={state?.security_password}
                                    onChange={({ target }) => setState({ ...state, security_password: target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {state.showPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {
                                    inputErrors && inputErrors.security_password
                                        ?
                                        <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.security_password}</Typography>
                                        :
                                        null
                                }
                            </Box>
                            
                            <Box className="flex-c-m">
                                <Button variant="contained" className='big-button w-full' onClick={twoFASetting?()=>setDrawerOpen(true):() => setConfirmTransferDialog({...confirmTransferDialog, open:true})}>{t('transfer.confirmRedeem')}</Button>
                                {/* <Button variant="contained" className='big-button w-full' onClick={twoFASetting ? insertSecurityPwd : confirmTransfer}>{t('transfer.confirmRedeem')}</Button> */}
                            </Box>
                        </Box>

                        <TwoFA
                            inputErrors={inputErrors?.authentication_code}
                            open={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                            value={state?.authentication_code || ''}
                            handleChange={({ target }) => {
                                const numericInput = target.value.replace(/\D/g, '');
                                setState({ ...state, authentication_code: numericInput });
                            }}
                            onClick={confirmTransfer}
                        />
                        <Dialog open={confirmTransferDialog.open} onClose={() => setConfirmTransferDialog({...confirmTransferDialog, open:false})} fullWidth maxWidth='sm'>
                            <DialogContent>
                                {`${t('general.confirmMsg1')} ${t('general.confirmMsg2')}`}
                            </DialogContent>
                            <DialogActions>
                                <Button variant='contained' sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: theme.palette.darkBase.main,
                                        color: theme.palette.white.main,
                                    },
                                    "&.Mui-disabled": {
                                        backgroundColor: "#D9D9D9",
                                        color: "#ADADAD",
                                        boxShadow: '2px 2px 4px 0px #00000040 inset',
                                    },
                                }} onClick={() => setConfirmTransferDialog({...confirmTransferDialog, open:false})}>{t('button.cancel')}</Button>
                                <Button variant='contained' onClick={confirmTransfer}>{t('button.confirm')}</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
   
}));

export default SpecialTransfer;