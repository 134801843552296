import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getUrl, postUrl } from '@utils/ApiAction';
import { useTheme, makeStyles } from '@mui/styles';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Typography, FormControl, Button, IconButton, TextField, Box, RadioGroup, FormControlLabel, Radio, InputAdornment, FormHelperText, Select,MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { currencyFormat, storePaths } from '@utils/Tools';

import TwoFA from '@components/TwoFA';
import { FiEye, FiEyeOff } from "react-icons/fi";

const INITIAL_STATE = { 
    type: 'unit', 
    redeemableValue: '', 
    unit: '', 
    currentPrice: '', 
    tradeDecimal: 0, 
    securityPassword: '' ,
    valueType : 'system',
    authentication_code: '',
    from:''
}
const INITIAL_PROFILE = { 
    passiveUnit: 0, 
    activeUnit: 0, 
    tradableValue: 0,
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
}; 

const Convert = () => {
    const theme = useTheme();
    let location = useLocation();
    const navigate = useNavigate();
    const isMountedRef = useRef(null);
    const { t, i18n } = useTranslation();
    const { id } = useSelector(state => state.user);
    const [merchant, setMerchant] = useState(null);
    const [userTradeProfile, setUserTradeProfile] = useState(INITIAL_PROFILE);
    const [twoFASetting, setTwoFASetting] = useState(false);
    const [walletSelection, setWalletSelection] = useState({});
    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState(INITIAL_STATE);
    const [activeStep, setActiveStep] = useState(0);
    const [option, setOption] = useState({
        securityPasswordShow: false,
    });
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, []);

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } })
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
        // Reset the state to clear the data
        setState(INITIAL_STATE);
        // Clear input errors
        setInputErrors(null);
    };

    const callApi = () => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`/member-convert-mp`).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                setUserTradeProfile(prevState => ({
                    ...prevState,
                    passiveUnit: response?.data?.passive_unit,
                    activeUnit: response?.data?.active_unit,
                    tradableValue: response?.data?.tradable_value,
                }))
                setState(prevState => ({
                    ...prevState,
                    currentPrice: response?.current_price,
                    tradeDecimal: 2
                }));
                setWalletSelection(response?.wallet_selection)
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                setMerchant(null);
                addAlert(JSON.stringify(error.message));
            }
        });

        getUrl( 'get-setting-value' ).then(response => {
            setLoading(false)
            if(response.status){
                setTwoFASetting(response.twofa)
            }
        }).catch(error => {
            setLoading(false)
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        return () => { isMountedRef.current = false };
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name) {
            case 'redeemableValue':
                // Remove all non-numeric characters except the decimal point
                value = value.replace(/[^0-9.]/g, '');

                // Ensure only one decimal point is present
                const decimalCount = value.split('.').length - 1;
                if (decimalCount > 1) {
                    value = value.slice(0, value.lastIndexOf('.'));
                }

                // Limit decimal places to three
                const parts = value.split('.');
                if (parts[1] && parts[1].length > 3) {
                    value = `${parts[0]}.${parts[1].slice(0, 3)}`;
                }

                let cUnit = Math.ceil(value / state?.currentPrice);
                setState({ ...state, [name]: value, unit: cUnit });
                break;
            case 'unit':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                let cRedeemValue = '';
                if (value !== "") {
                    cRedeemValue = value * state?.currentPrice;
                }
                setState({ ...state, [name]: value, redeemableValue: cRedeemValue });
                break;
            case 'from':
                setState({ ...state, [name]: value, redeemableValue: 0 , unit: 0, valueType:''});
                break;
            default:
                setState({ ...state, [name]: value });
                break;
        }
    };

    const handleConvert = () => {
        setInputErrors([]);
        let params = {
            from: state?.from,
            type: state?.type,
            current_price: state?.currentPrice,
            security_password: state?.securityPassword,
            value_type: state?.valueType,
        }

        if (state?.type === 'unit') {
            params['unit'] = state?.unit
        } else if (state?.type === 'redeemable_value') {
            params['redeemable_value'] = state?.redeemableValue;
        }
        postUrl(`/trade-mp-convert`, params).then(response => {
            setLoading(false);
            let { status, message, data, errors } = response;

            if (status) {
                setState(INITIAL_STATE);
                setActiveStep(0);
                callApi();
                addAlert('', message || t('success.convertSuccess'), 'success', '');

            } else {
                setInputErrors(errors);
                if (_.size(errors) > 0) {
                    let errorKeys = Object.keys(errors);
                    if (_.size(_.intersection(['redeemable_value', 'unit', 'value_type', 'from'], errorKeys)) > 0) {
                        handleBack();
                    }
                }
                addAlert('', message || t('error.contactSupport'), 'error', '');
            }

        }).catch(error => {
            setLoading(false);
            setInputErrors([]);
            addAlert('', JSON.stringify(error.message), 'error', '');
        });
    }

    const handleRadioChange = (event) => {
        setState({ ...state, type: event.target.value, unit: '', redeemableValue: '' });
    };

    const handleMaxButtonClick = () => {
        if (state?.from === 'active') {
            let cActiveUnit = userTradeProfile?.tradableValue / state?.currentPrice;
            setState({ ...state, unit: cActiveUnit, redeemableValue: userTradeProfile?.tradableValue });
        } else if (state?.from === 'passive') {
            let cRedeemValue = userTradeProfile?.passiveUnit * state?.currentPrice;
            setState({ ...state, unit: userTradeProfile?.passiveUnit, redeemableValue: cRedeemValue });
        } else {
            addAlert('', t('spend.maxButtonDisable'), 'error', '');
        }
    };

    const handleNext = () => {
        if (state?.type === 'unit') {
            if (state?.unit > 0) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            else {
                addAlert('', t('error.requiredFillAllSection'), 'error', '');
            }
        } else if (state?.type === 'redeemable_value') {
            if (state?.redeemableValue > 0) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            else {
                addAlert('', t('error.requiredFillAllSection'), 'error', '');
            }
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    const stepOne = () => {
        return (
            <Box>
                <Typography variant='h5' style={{ textAlign: 'center', color: '#D4A964', fontWeight: 700, textTransform: 'uppercase' }} >
                    <b>
                        {t('profile.MPconvert')}
                    </b>
                </Typography>
                <div>
                    <Box className="m-tb-30">
                        <div className=' gradient-gold-bg bor-20' style={{padding:2, width:'100%'}}>
                            <div className='flex-c-m w-full h-full pos-relative bg-base bor-20' style={{width:'100%',padding:'15px 40px 15px 40px', flexDirection: window.innerWidth <= 500 ? 'column' : 'row', }}>
                                {/* <div>
                                    <img
                                        src="/images/logos/logo.png"
                                        alt={`Default Merchant Logo - ${merchant?.code}`}
                                        style={{ maxWidth: '110px', maxHeight: '110px' }}
                                    />
                                </div> */}
                                <div className='s-full dis-flex flex-sa-l' style={{ flexDirection: 'column' }}>
                                    <div className='dis-flex flex-sa-l w-full' style={{ flexDirection: 'column', gap: '10px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                                {t('MPconvert.title')}
                                            </Typography>
                                            <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                                                {`${t('MPconvert.MPAV')}: `}
                                                {/* <b>{`$ ${currencyFormat(isNaN(userTradeProfile?.activeUnit) ? 0 : (userTradeProfile?.activeUnit * state?.currentPrice), state?.tradeDecimal) || 0}`}</b> */}
                                                <b>{`$ ${currencyFormat(isNaN(userTradeProfile?.tradableValue) ? 0 : (userTradeProfile?.tradableValue), state?.tradeDecimal) || 0}`}</b>
                                                {` ≈ ${isNaN(userTradeProfile?.tradableValue) ? 0 : Math.floor(userTradeProfile?.tradableValue / state?.currentPrice)} UNIT`}
                                            </Typography>
                                            <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                                                {`${t('MPconvert.MPPV')}: `}
                                                <b>{`$ ${currencyFormat(isNaN(userTradeProfile?.passiveUnit) ? 0 :( userTradeProfile?.passiveUnit * state?.currentPrice), state?.tradeDecimal) || 0}`}</b>
                                                {` ≈ ${isNaN(userTradeProfile?.passiveUnit) ? 0 : userTradeProfile?.passiveUnit} UNIT`}
                                            </Typography>
                                        </div>
                                        <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                                            {`${t('trade.currentPrice')}: $ ${state?.currentPrice}`}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
                <FormControl sx={{ width: '100%' }} variant="standard"  error={inputErrors && inputErrors['from'] ? true : false}>
                    <Select
                        id="from"
                        name="from"
                        value={state.from?state.from:""}
                        onChange={handleChange}
                        displayEmpty
                        MenuProps={MenuProps}
                        sx={{
                            '& .MuiSelect-select': {
                                border: 'none',
                                borderRadius: 10,
                                boxShadow: 'none',
                                color: theme.palette.darkBase.main,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                borderRadius: 10,
                                boxShadow: 'none',
                            },
                            '& .MuiSelect-icon': {
                                color: theme.palette.darkBase.main,
                                marginRight:"10px"
                            },
                        }}
                    >
                        <MenuItem value="" style={{ color: '#222' }} disabled>
                            {t('general.pleaseSelect')}
                        </MenuItem>
                        <MenuItem value="active" style={{ color: '#222' }} >
                            {t('MPconvert.MPA')}
                        </MenuItem>
                        <MenuItem value="passive" style={{ color: '#222' }} >
                            {t('MPconvert.MPP')}
                        </MenuItem>
                    </Select>
                    {
                        inputErrors && inputErrors['from'] &&
                        <FormHelperText style={{ color: 'red' }}>{inputErrors['from']}</FormHelperText>
                    }
                </FormControl>
                <FormControl>
                    <RadioGroup row value={state?.type} onChange={handleRadioChange}>
                        <FormControlLabel
                            value="unit"
                            control={<Radio />}
                            label={t('general.unit')}
                            sx={{
                                marginRight: 3,
                                '& .MuiTypography-root': {
                                    color: theme.palette.gray.main,
                                    fontWeight: 400,
                                    fontSize: 17
                                }
                            }}
                        />
                        <FormControlLabel
                            value="redeemable_value"
                            control={<Radio />}
                            label={t('general.value')}
                            sx={{
                                '& .MuiTypography-root': {
                                    color: theme.palette.gray.main,
                                    fontWeight: 400,
                                    fontSize: 17
                                }
                            }}
                        />
                    </RadioGroup>
                </FormControl>
                <Box>
                    <TextField
                        placeholder={state?.type === 'redeemable_value' ? t('spend.redeemableValue') : t('spend.passiveUnit')}
                        variant='standard'
                        fullWidth
                        required
                        name={state?.type === 'redeemable_value' ? "redeemableValue" : "unit"}
                        value={state?.type === 'redeemable_value' ? state?.redeemableValue || '' : state?.unit || ''}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button variant="contained" className='max-button' onClick={handleMaxButtonClick}>
                                        {t('general.max')}
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {
                        inputErrors && (inputErrors.unit || inputErrors.redeemable_value) ? (
                            <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>
                                {[inputErrors.unit, inputErrors.redeemable_value].filter(Boolean).join(', ')}
                            </Typography>
                        ) : null
                    }
                    {/* <Box display="flex" justifyContent="space-between" style={{ marginTop: "10px" }}>
                        <Typography style={{ fontSize: "13px", fontWeight: 400, color: theme.palette.darkGray.main, fontStyle: 'italic' }}>
                            {`≈  ${state?.type === 'unit' ? `$ ${currencyFormat(isNaN(state?.redeemableValue) ? 0 : state?.redeemableValue, state?.tradeDecimal)}` : `${currencyFormat(isNaN(state?.unit) ? 0 : state?.unit, 0)} ${t('general.unit')}`} `}
                        </Typography>
                        <Typography style={{ fontSize: "13px", fontWeight: 400, color: theme.palette.darkGray.main }}>
                            {state?.type === 'unit' ? t('trade.maxConvertPassiveUnit') : t('trade.maxConvertRedeemableValue')}: <b> {state?.type === 'unit' ? currencyFormat(isNaN(merchant?.merchant_trade_profile?.passive_unit) ? 0 : merchant?.merchant_trade_profile?.passive_unit, 0) : `$${currencyFormat(isNaN(merchant?.merchant_trade_profile?.redeemable_value) ? 0 : merchant?.merchant_trade_profile?.redeemable_value, state?.tradeDecimal)}`} </b>
                        </Typography>
                    </Box> */}
                </Box>
                <FormControl sx={{ width: '100%', marginTop:3 }} variant="standard"  error={inputErrors && inputErrors['country'] ? true : false}>
                    <Select
                        id="valueType"
                        name="valueType"
                        value={state.valueType?state.valueType:""}
                        onChange={handleChange}
                        displayEmpty
                        MenuProps={MenuProps}
                        sx={{
                            '& .MuiSelect-select': {
                                border: 'none',
                                borderRadius: 10,
                                boxShadow: 'none',
                                color: theme.palette.darkBase.main,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                borderRadius: 10,
                                boxShadow: 'none',
                            },
                            '& .MuiSelect-icon': {
                                color: theme.palette.darkBase.main,
                                marginRight:"10px"
                            },
                        }}
                    >
                        <MenuItem value="" style={{ color: '#222' }} disabled>
                            {t('general.pleaseSelect')}
                        </MenuItem>
                        {
                            _.size(walletSelection) > 0 ?
                                _.map(walletSelection, (data, key) => {
                                   
                                    return (
                                        <MenuItem value={data.id} style={{ color: '#222' }}>
                                            {data.name[i18n.language]}
                                        </MenuItem>
                                    );
                                })
                                :
                                null
                        }
                    </Select>
                    {
                        inputErrors && inputErrors['value_type'] &&
                        <FormHelperText style={{ color: 'red' }}>{inputErrors['value_type']}</FormHelperText>
                    }
                </FormControl>
                <Box className="flex-c-m" style={{ marginTop: 30 }}>
                    <Button variant="contained" className='big-button w-full' onClick={handleNext} >{t('button.convertNow')}</Button>
                </Box>
            </Box>
        )
    }

    const stepTwo = () => {
        return (
            <Box>
                <Typography variant='h6' style={{ textAlign: 'center', fontWeight: 700, textTransform: 'uppercase' }} >
                    <b>
                        {t('trade.convertDetail')}
                    </b>
                </Typography>
                {/* <div>
                    <Box className="m-tb-30">
                        <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                            <div>
                                <img
                                    src="/images/logos/logo.png"
                                    alt={`Default Merchant Logo - ${merchant?.code}`}
                                    style={{ maxWidth: '110px', maxHeight: '110px' }}
                                />
                            </div>
                            <div>
                                <Typography variant="body2" style={{ textTransform: 'uppercase', fontStyle: 'italic' }}>{` ${t('trade.merchant')} : ${t('MPconvert.title')}`}</Typography>
                            </div>
                        </div>
                    </Box>
                </div> */}
                {/* <div className=' gradient-gold-bg bor-20' style={{padding:2, width:'100%'}}> className='flex-c-m w-full h-full pos-relative bg-base bor-20'*/}
                <div className=' gradient-gold-bg' style={{ borderRadius: 20, padding: 2, marginTop: 20 }}>
                    <div className='flex-c-m w-full h-full pos-relative bg-base bor-20' style={{ flexDirection: 'column', padding:15}}>
                        <Typography variant="body1" style={{ color: '#adadad', fontWeight: 'bold', textAlign: 'center' }}>{t('trade.approximatelyConvertSummary')}</Typography>
                        <hr
                            className=' gradient-gold-bg'
                            style={{
                                height: 4,
                                display: 'inline-block',
                                border: 0,
                                width: '100%'
                            }}
                        />
                        <div className='flex-sb-m w-full'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" >{t('MPconvert.type')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{  fontStyle: 'italic' }}>
                                    {`${state?.from === 'active' ? t('MPconvert.MPA') : t('MPconvert.MPP')}`}
                                </Typography>
                            </div>
                        </div>
                        <div className='flex-sb-m w-full' style={{ marginTop: 10 }}>
                            <div className='flex-l-m'>
                                <Typography variant="body2" >{state?.type === 'redeembale_value' ? t('trade.convertValue') : t('trade.convertUnit')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{  fontStyle: 'italic' }}>
                                    {`${state?.type === 'redeemable_value' ? `$ ${currencyFormat(state?.redeemableValue, state?.tradeDecimal)}` : `${currencyFormat(state?.unit, 0) || 0} ${t('general.unit')}`}`}
                                </Typography>
                            </div>
                        </div>
                        <div className='flex-sb-m w-full'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" >{t('trade.convertRate')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                                    {state?.currentPrice}
                                </Typography>
                            </div>
                        </div>
                        <div className='flex-sb-m w-full' style={{ marginTop: 10 }}>
                            <div className='flex-l-t'>
                                <Typography variant="body2" style={{ fontWeight: 'bolder' }}>{t('trade.totalConvert')}</Typography>
                            </div>
                            <div className='dis-flex' style={{ flexDirection: 'column' }}>
                                <Typography variant="body2" style={{ fontWeight: 'bolder', textTransform: 'uppercase', textAlign: 'right' }}>
                                    {state?.type === 'redeemable_value' ? `$ ${currencyFormat(state?.redeemableValue, state?.tradeDecimal)}` : `${currencyFormat(state?.unit, 0)} ${t('general.unit')}`}
                                </Typography>
                                <Typography variant="caption" style={{textTransform: 'uppercase' }}>
                                    {`≈ ${state?.type === 'redeemable_value' ? `${currencyFormat(state?.unit, 0)} ${t('general.unit')}` : `$ ${currencyFormat(state?.redeemableValue, state?.tradeDecimal)}`}`}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <Box style={{ marginTop: 20 }}>
                    <TextField
                        variant="standard"
                        fullWidth
                        name="securityPassword"
                        placeholder={t(`user.securityPassword`)}
                        value={state?.securityPassword}
                        onChange={handleChange}
                        type={option?.securityPasswordShow ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => handlePasswordShow('securityPasswordShow')}>
                                        {option?.securityPasswordShow ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                        helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                        error={inputErrors && inputErrors.security_password ? true : false}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box className="flex-c-m" style={{ marginTop: 30, flexDirection: 'column', gap: 20 }}>
                    <Button variant="contained" className='big-button w-full' onClick={twoFASetting ? ()=>setDrawerOpen(true) : handleConvert} style={{ textTransform: 'uppercase' }}>{t('button.convertNow')}</Button>
                    <Button variant="contained" onClick={handleBack} style={{ background: 'transparent', textTransform: 'uppercase', border: `2px solid ${theme.palette.primary.main}`, }} fullWidth>
                        <Typography style={{ color: theme.palette.primary.main, fontWeight: 'bolder' }}>{t('button.back')}</Typography>
                    </Button>
                </Box>
            </Box>
        )
    }

    const stepperDisplay = (currStep) => {
        switch (currStep) {
            case 0:
                return stepOne();
            case 1:
                return stepTwo();
            default: 
                return  null;
        }
    }

    return (
        <>
            <div className='dashboard-container'>
                <div className='dashboard-w'>
                    <div className='pos-relative' style={{ zIndex: 2 }}>
                        {
                            loading ? null :
                                <>
                                    {stepperDisplay(activeStep)}
                                </>
                        }
                    </div>
                </div>
            </div>
            <TwoFA
                inputErrors={inputErrors?.authentication_code}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                value={state?.authentication_code || ''}
                handleChange={({ target }) => {
                    const numericInput = target.value.replace(/\D/g, '');
                    setState({ ...state, authentication_code: numericInput });
                }}
                onClick={handleConvert}
            />
        </>
    )
}

export default Convert;