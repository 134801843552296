import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { createTheme, ThemeProvider, Tabs, Tab, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Password from '@pages/Account/Password';
import SecurityPassword from '@pages/Account/SecurityPassword';
// import Frame from '@layouts/Frame';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const PasswordHome = () => {
    // const styles = useStyles();
    // const theme = useTheme();
    const { t } = useTranslation();


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div className='dashboard-container'>
            <ThemeProvider theme={tabStyle}>
                <div style={{ maxWidth: 550, margin: ' 0 auto', zIndex: 2 }} className='max-w-full pos-relative'>
                    <Tabs value={value} onChange={handleChangeTab} style={{ overflow: 'unset', boxShadow: 'none'}}>
                        <Tab label={t('title.password')} {...a11yProps(0)} style={{maxWidth:'50%'}} />
                        <Tab label={t('title.securityPassword')} {...a11yProps(1)} style={{maxWidth:'50%'}} />
                    </Tabs>
                </div>
            </ThemeProvider>

            <div className='dashboard-w' style={{ borderTopLeftRadius: 0, paddingTop: 25 }} >
                <div className='pos-relative' style={{zIndex:2}}>
                    <CustomTabPanel value={value} index={0} style={{ padding: 0 }}>
                        <Password />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1} style={{ padding: 0 }}>
                        <SecurityPassword />
                    </CustomTabPanel>
                </div>
                {/* <Frame /> */}
            </div>
        </div>
    )
}



// const useStyles = makeStyles(theme => ({
   
// }));

const tabStyle = createTheme({
    components: {
      // Name of the component
      MuiTab: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            minWidth:150,
            cursor:'pointer',
            color: '#F0F0F0',
            background:'transparent',
            borderRadius: '15px 15px 0 0',
            // boxShadow:'-3px -3px 4px 0px #FFF, 3px -2px 3px #0000000f',
            '&.Mui-selected':{
                background: 'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #bf863b, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5)',
                color: '#000 !important',
                // boxShadow:'-3px -3px 4px 0px #FFF, 3px -2px 3px #0000000f',
            }
          },
          
        },
      },
      MuiTabs:{
        styleOverrides: {
            indicator: {
                backgroundColor:'transparent',  
              },
              scroller:{
                overflow:'unset!important',
                paddingTop:20
              }
      }
    },

  }});


export default PasswordHome