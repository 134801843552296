import React from 'react';
import { useTheme } from '@mui/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/pagination';

const VideoSwiper = ({ videos }) => {
    const theme = useTheme();
    return (
        <Swiper
            className="mySwiper"
            spaceBetween={20}
            loop={true}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 4500,
                disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            style={{ "--swiper-pagination-color": "#fff", "--swiper-pagination-bullet-inactive-color": theme.palette.primary.main, "--swiper-pagination-bullet-inactive-opacity": "1" }}
        >
            {videos.map((video) => (
                <SwiperSlide key={video.id}>
                    <iframe
                        title={`dashboard_video_${video.id}`}
                        src={video.url}
                        width="100%"
                        height="300"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default VideoSwiper;