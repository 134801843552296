import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Tab, Tabs, Typography, LinearProgress, Link, ThemeProvider, createTheme, Button, DialogContent, IconButton, Dialog, DialogActions, Box } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';
import { storePaths , currencyFormat } from '@utils/Tools';

import WalletListing from '@pages/Merchant/WalletListing';
import QRListing from '@pages/Merchant/QRListing';
import { FiPlus } from "react-icons/fi";
import moment from 'moment';

const Wallet = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    let location = useLocation();

    // let { page } = useParams();
    const { accessToken } = useSelector(state => state.general);
    const { merchant } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    
    const [walletListing, setWalletListing] = useState([]);
    const [bonusListing, setBonusListing] = useState([]);
    const [activeTab, setActiveTab] = useState('wallet');
    const [totalBalance, setTotalBalance] = useState(0);
    // const [isBonusTabActive, setIsBonusTabActive] = useState(false);
    const [isBonusTabDisabled, setIsBonusTabDisabled] = useState(false);
    const [profitLimit, setProfitLimit] = useState({});
    const [state, setState] = useState({
        pageIndex: 'wallet',
        subPage: 'wallet',
    });
    const [apiDate, setApiDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));

    let { page } = useParams();
    useEffect(() => {
        if (page) {
            if (_.includes(['wallet', 'qr'], page)) {
                setState({
                    ...state,   
                    pageIndex: page === 'qr' ? 'qr' : 'wallet',
                    subPage: page,
                });
                setActiveTab(page);
            }
        }
    }, [page]);

    useEffect(() => {
        setLoading(true);
        if(merchant){
            getUrl(`/merchant-wallets`).then(response => {
                if (response.status) {
                    setWalletListing(response.data);
                    // Extract balance values from the wallet_balances array
                    const balanceArray = response.data.flatMap(item => {
                        return item.wallet_balances.map(wallet => parseFloat(wallet.balance) || 0);
                    });
    
                    // Calculate total balance
                    const totalBalance = balanceArray.reduce((sum, balance) => sum + balance, 0);
    
                    // Update the total balance in the state
                    setTotalBalance(totalBalance);

                    setApiDate(moment().format('YYYY-MM-DD HH:mm:ss'))
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }else{
            navigate(`/`);
        }
        setLoading(false);
    }, []);

    const handleTabChange = (event, newValue) => {
        // Use the new value to determine the active tab
        if (newValue === 'bonus') {
            // Check if the Bonus tab is disabled
            if (isBonusTabDisabled) {
                return;
            }

            // Enable bonusListing and set the bonus tab as active
            // setBonusListing([]);
        } else {
            // Disable bonusListing for other tabs and set the bonus tab as inactive
        }

        // Always update the active tab to handle toggling
        setActiveTab(newValue);
        navigate(`/merchant-wallet/${newValue === 'qr' ? 'qr' : 'wallet'}`, { state: { from: storePaths(location) } });
    };

    return (
        <>
            {accessToken && (
                <div className='dashboard-container'>
                    <>
                            <div style={{ width: 800, margin: ' 0 auto', zIndex: 2 }} className='max-w-full pos-relative'>
                                <Tabs value={activeTab} onChange={handleTabChange} style={{ overflow: 'unset', }}>
                                    <Tab label={t('wallet.merchantQR')} value="qr"  style={{maxWidth:'50%'}}/>
                                    <Tab label={t('wallet.merchantWallet')} value="wallet" style={{maxWidth:'50%'}} />
                                </Tabs>
                            </div>

                        <div className='dashboard-w' style={{ borderTopLeftRadius: 0, paddingTop: 20, width:800, zIndex: 1 }} >
                            <div className='pos-relative ' style={{ zIndex: 2 }}>
                                {state?.pageIndex === 'wallet' &&
                                    <div className='txt-center w-full p-b-30'>
                                        <div className={`head ${styles.walletBalanceLayout} txt-center p-t-40`}>
                                            <Typography variant='h4' className="txt-amount">
                                                {t('wallet.merchantWallet')}
                                            </Typography>
                                            <p className='txt-white fs-12'>
                                                {t('wallet.lastUpdate',{date:apiDate})}
                                            </p>
                                        </div>
                                    </div>}
                                <div className='pos-relative' style={{ zIndex: 2 }}>
                                    {/* Content for each tab */}
                                    <div className={`tab-content ${state?.pageIndex === 'wallet' ? '' : 'hidden-right'}`}>
                                        {state?.pageIndex === 'wallet' && <WalletListing listing={walletListing} />}
                                    </div>
                                    <div className={`tab-content ${state?.pageIndex === 'qr' ? '' : 'hidden-left'}`}>
                                        {state?.pageIndex === 'qr' && <QRListing/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
                
            )}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    walletBalanceLayout: {       
        // backgroundImage: `url('/images/wallets/accumulate_background.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        width: '100%',
        minHeight: 80,
        backgroundPosition:'center bottom'
    },
}));



export default Wallet;