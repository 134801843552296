import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _, { isNaN } from 'lodash';
import moment from 'moment';

import { Grid, Typography, Button, Dialog, DialogContent, Hidden, ThemeProvider, Stack, Pagination, createTheme, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Close } from '@mui/icons-material';

import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

import { getUrl } from '@utils/ApiAction';

import AllButton from '@components/AllButton';
import VscSquareButton from '@components/VscSquareButton';
import DateDisplay from '@components/DateDisplay';
import RedeemTransactionItem from '@components/TransactionItems/RedeemTransactionItem';

const RedeemTransaction = () => {
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    let navigate = useNavigate();
    const location = useLocation();

    const { id } = useSelector((state) => state.user);
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    const [tradeProfile, setTradeProfile] = useState({});
    const [redeemTransactionList, setRedeemTransactionList] = useState({});
    const [currentPrice, setCurrentPrice] = useState(0.000);
    const [totalMerchantValue, setTotalMerchantValue] = useState(0);
    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchDate, setSearchDate] = useState(false);
    
    const filters = [
        { title: t('transaction.filterOptions.all'), value: '' },
        { title: t('transaction.filterOptions.credit'), value: -1 },
        { title: t('transaction.filterOptions.debit'), value: 1 },
    ];

    const [selectedStatus, setSelectedStatus] = useState('');

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, page, selectedStatus, searchDate]);

    const callApi = () => {
        setLoading(true);
        let apiData = {
            per_page: 10,
            page: page,
            filtering: selectedStatus,
            start_date : startDate !== null ? (startDate).format('YYYY-MM-DD') : null,
            end_date: endDate !== null ? (endDate).format('YYYY-MM-DD') : null
        }

        getUrl(`/redeemable-transaction-list/${id}`, apiData).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error, data } = response;
                setTradeProfile(data.profile);
                setCurrentPrice(currencyFormat(data.current_price, 3));
                setTotalMerchantValue(parseFloat(data.total_merchant_value));

                let { current_page, last_page } = response.data.listing;
                setTotal(last_page);
                setCurrPage(current_page);

                if (status) {
                    setRedeemTransactionList(response.data.listing.data);
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });
    }

    let totalTransfer = tradeProfile?.active_unit + tradeProfile?.passive_unit || 0;
    let totalTransferApprox = currencyFormat((totalTransfer * currentPrice), 2) || 0;

    const handleClickOpen = (value) => {
        setOpenDialog(true);
        setSelectedTransaction(value);
    };

    const handleClickClose = () => {
        setOpenDialog(false);
    };

    const handleChangeStatusFilter = value => {
        setSelectedStatus(value);
        setPage(1);
        setRedeemTransactionList([]);
    };

    const onChangePage = (page) => {
        setPage(page);
    }

    return (
        <div className='dashboard-container' style={{ paddingBottom: 20 }}>
            <div className='flex-col-c-m w-full p-b-10'>
                <div className={`${styles.redeemBonusToken} gradient-gold-border`} style={{ width: mobileView ? '100%' : '80%' }}>
                    <div className={`${styles.redeemCurrentPrice} gradient-gold-border`} style={{ width: mobileView ? '85%' : '70%' }}>
                        <Typography variant='body1' className='txt-center text-gold-short'><b>{t('trade.redeemBonusToken')}</b></Typography>
                        <Typography variant='body2' className='txt-center'><i>{t('trade.currentPrice')}: ${parseFloat(currentPrice).toFixed(3)}</i></Typography>
                    </div>

                    {/* Redeemable */}
                    <Grid container className='w-full flex-m'>
                        <Grid item xs={6} md={4}>
                            <Typography variant='body1 text-gold-short'>{t('trade.redemmable')}</Typography>
                        </Grid>
                        <Hidden mdUp>
                            <Grid item xs={6} md={4}>
                                <Typography variant='body1' className='txt-right text-gold-short'><b>{totalTransfer} {t('trade.units')}</b></Typography>
                                <Typography variant='body1' className='txt-right text-gold-short'><b>≈ ${totalTransferApprox}</b></Typography>
                            </Grid>
                        </Hidden>
                        <Hidden mdDown>
                            <Grid item xs={6} md={4}>
                                <Typography variant='h6' className='txt-center text-gold-short'><b>{totalTransfer} {t('trade.units')}</b></Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='h6' className='txt-center text-gold-short'><b>${totalTransferApprox}</b></Typography>
                            </Grid>
                        </Hidden>
                    </Grid>

                    <Hidden mdDown>
                        <div style={{ position: 'absolute', right: '34%', bottom: '25%' }}>
                            <Typography variant='h6' className='txt-center'><b>≈</b></Typography>
                        </div>
                    </Hidden>

                    {/* Total Merchants Value */}
                    <Grid container className='w-full flex-m'>
                        <Grid item xs={6} md={4}>
                            <Typography variant='body1'>{t('trade.totalMerchantValue')}</Typography>
                        </Grid>
                        <Hidden mdUp>
                            <Grid item xs={6} md={4}>
                                <Typography variant='body1' className='txt-right'>≈ {Math.round(totalMerchantValue / currentPrice) || 0} {t('trade.units')}</Typography>
                                <Typography variant='body1' className='txt-right'>${isNaN(totalMerchantValue) ? 0 : currencyFormat(totalMerchantValue, 2)}</Typography>
                            </Grid>
                        </Hidden>
                        <Hidden mdDown>
                            <Grid item xs={6} md={4}>
                                <Typography variant='body1' className='txt-center'>${isNaN(totalMerchantValue) ? 0 : currencyFormat(totalMerchantValue, 2)}</Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography variant='body1' className='txt-center'>{Math.round(totalMerchantValue / currentPrice) || 0} {t('trade.units')}</Typography>
                            </Grid>
                        </Hidden>
                    </Grid>
                </div>

                <div className={styles.shadowDisplay} style={{ width: mobileView ? '85%' : '65%' }} />

                <Grid container spacing={2} style={{ display: 'flex', alignItems: mobileView ? 'flex-end' : 'center' }}>
                    <Grid item xs={12} sm={7} md={5}>
                        <Typography variant='body1' style={{ color: '#adadad' }}><b>{t('trade.redeemTransactionRecord')}</b></Typography>
                        {mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                    </Grid>
                    <Grid item xs={12} sm={5} md={7} className='txt-right' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {!mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                        <div style={{ paddingRight: 10 }}>
                            <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                        </div>
                        <AllButton filters={filters} handleChange={handleChangeStatusFilter} />
                    </Grid>
                </Grid>

                {/* Merchant Data */}
                <div className='w-full bor-15 gradient-gold-border p-all-20 m-t-20'>
                    {
                        _.size(redeemTransactionList) > 0 ?
                            _.map(redeemTransactionList, (data, idx) => {
                                let factor = data?.factor !== 1 ? '-' : '+';
                                return (
                                    <div key={idx} onClick={() => handleClickOpen(data)}>
                                        <RedeemTransactionItem data={data} mobileView={mobileView} factor={factor} currentPrice={currentPrice} displayOnly={true}/>
                                        { idx < _.size(redeemTransactionList) - 1 && <div className={styles.dividerDesign} />}
                                    </div>
                                )
                            })
                            : <Grid container className='flex-l-m p-tb-10'>
                                <Grid item xs={12}>
                                    <Typography variant='body1' className='txt-center'>{t('general.noData')}</Typography>
                                </Grid>
                            </Grid>
                    }

                    {
                        !mobileView && _.size(redeemTransactionList) > 0 &&
                        <ThemeProvider theme={paginationStyle} >
                            <Stack spacing={2} style={{ padding: '10px 10%' }}>
                                {loading ? null : <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />}
                            </Stack>
                        </ThemeProvider>
                    }
                </div>

                <Dialog open={openDialog} onClose={handleClickClose} className='w-full'>
                    <DialogContent style={{ padding: '10px 30px 40px' }}>
                        <div className='flex-r-m'>
                            <Button onClick={handleClickClose} style={{ minWidth: 28, aspectRatio: 1 / 1, padding: 5, borderRadius: 100}}>
                                <Close/>
                            </Button>
                        </div>

                        <div className='flex-col-c-m p-b-10'>
                            <img src={'/images/logos/logo.png'} className='w-full m-all-10' alt="merchant_logo" loading='lazy' style={{ width: 100, height: 100, aspectRatio: 1 / 1 }} />
                            <Typography variant='body2' className='txt-upper txt-center text-gold-short'>
                                <i>
                                    {t('trade.merchant')} :&nbsp;
                                    {
                                        selectedTransaction?.trade_redeemable?.merchant_id !== null
                                            ? <>{selectedTransaction?.trade_redeemable?.merchant?.name?.[i18n.language]} ({selectedTransaction?.trade_redeemable?.merchant?.code})</>
                                            : <>{t('trade.system')}</>
                                    }
                                </i>
                            </Typography>
                        </div>

                        <div className='flex-col-c-m p-t-20'>
                            <Typography variant='body2' className='txt-upper'><i>{t('trade.transferUnit')}</i></Typography>
                            <Typography variant='h5' className='txt-upper text-gold-short'>
                                <b>{selectedTransaction?.factor !== 1 ? '-' : '+'} {Math.round(parseFloat(selectedTransaction?.redeemable_value) / currentPrice) || 0} {t('trade.unit')} ($ {currencyFormat(parseFloat(selectedTransaction?.redeemable_value), 2)})</b>
                            </Typography>
                            <Typography variant='body2' className='txt-upper' >{t('trade.rate')}: {currentPrice}</Typography>
                        </div>

                        <Grid container className={`${styles.dialogDetailBoxDesign} gradient-gold-border`}>
                            {
                                // selectedTransaction?.trade_transaction_id !== null
                                //     ? <>
                                //         <Grid item xs={6}>
                                //             <Typography style={{ color: theme.palette.darkGray.main, fontWeight: 'bold' }}>{t('transfer.transferTo')}</Typography>
                                //         </Grid>
                                //         <Grid item xs={6}>
                                //             <Typography style={{ color: theme.palette.darkGray.main }} className='txt-right'>Unknown 1</Typography>
                                //         </Grid>
                                //     </>
                                //     : 
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body2' style={{ fontWeight: 'bold', lineHeight: 1.2 }}>{t('trade.transferType')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body2' style={{ lineHeight: 1.2 }} className={mobileView ? 'txt-left' : 'txt-right'}>{selectedTransaction?.transaction_code_display?.[i18n.language]}</Typography>
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12} sm={6} className='p-t-10'>
                                <Typography variant='body2' style={{ fontWeight: 'bold', lineHeight: 1.2 }}>{t('trade.dateTime')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={`p-t-${mobileView ? 0 : 10}`}>
                                <Typography variant='body2' style={{ lineHeight: 1.2 }} className={`${mobileView ? 'txt-left' : 'txt-right'} txt-upper`}>
                                    <i>{moment(selectedTransaction?.updated_at).format('DD MMM YYYY  ·  HH:mm:ss A')}</i>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} className='p-t-10'>
                                <Typography variant='body2' style={{ fontWeight: 'bold', lineHeight: 1.2 }}>{t('trade.transactionId')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} className={`p-t-${mobileView ? 0 : 10}`}>
                                <Typography variant='body2' style={{ lineHeight: 1.2 }} className={mobileView ? 'txt-left' : 'txt-right'}>{selectedTransaction?.trade_transaction?.ulid || '-'}</Typography>
                            </Grid>

                            {
                                _.includes(["user-spend", "merchant-spend-claim"], selectedTransaction?.code) &&
                                <>
                                    <Grid item xs={12} sm={6} className='p-t-10'>
                                        <Typography variant='body2' style={{ fontWeight: 'bold', lineHeight: 1.2 }}>{selectedTransaction?.details?.to ? t('receipt.merchant') : t('receipt.receivedFrom')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className={`p-t-${mobileView ? 0 : 10}`}>
                                        <Typography variant='body2' style={{ lineHeight: 1.2, wordBreak: 'break-all' }}>{selectedTransaction?.details?.to ? (selectedTransaction?.details?.to[i18n.language] ? selectedTransaction?.details?.to[i18n.language] : selectedTransaction?.details?.to?.en) : selectedTransaction?.details?.from}</Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>

                        <div className={`${styles.dialogDetailBoxDesign} gradient-gold-border`}>
                            <Typography variant='body2' style={{ fontWeight: 'bold' }}>{t('receipt.remark')}</Typography>
                            <Typography variant='body2'>{selectedTransaction?.details?.remark || '-'}</Typography>
                        </div>
                    </DialogContent>
                </Dialog>

                {
                    mobileView && _.size(redeemTransactionList) > 0 &&
                    <ThemeProvider theme={paginationStyle} >
                        <Stack spacing={2} style={{ padding: '10px 0px' }}>
                            {loading ? null : <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />}
                        </Stack>
                    </ThemeProvider>
                }
            </div>
        </div>
    );
}

const paginationStyle = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                ul: {
                    justifyContent: 'space-around',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#f0f0f0',
                },
            },
        }
    }
});

const useStyles = makeStyles((theme) => ({
    redeemBonusToken: {
        position: 'relative',
        padding: '50px 30px 20px',
        // background: theme.palette.secondary.main,
        borderRadius: 10,
        boxShadow: '4px 4px 4px 0px #00000040 inset, 4px 4px 4px 0px #FFFFFF0F',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 40,
    },
    redeemCurrentPrice: {
        position: 'absolute',
        top: -30,
        padding: 7,
        // background: theme.palette.primary.main,
        borderRadius: 15,
    },
    shadowDisplay: {
        height: 6,
        borderRadius: '50%',
        margin: '30px 0px',
        background: 'radial-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.01))',
        boxShadow: '0px 0px 10px 4px #00000078',
    },
    dialogDetailBoxDesign: {
        // boxShadow: '3px 3px 4px 0px #FFFFFF, 3px 3px 2px 0px #0000001A inset',
        borderRadius: 20,
        padding: '20px',
        marginTop: 20,
    },
    dividerDesign: {
        height: 4,
        width: '100%',
        background: theme.palette.secondary.main,
        marginTop: 5,
        marginBottom: 5,
        boxShadow: '0px 1px 2px 0px #000000DB inset, 0px 2px 3px 0px #FFFFFF12',
    },
}));

export default RedeemTransaction;