import React, { useState, useRef , useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import _, { isUndefined } from 'lodash';
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { FaQuoteRight } from "react-icons/fa";
import { Box, Button, IconButton } from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


import { PropTypes } from 'prop-types';


const SlideShow = ({ language, data, autoPlayInterval = 3000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
        goToNextSlide();
        }, autoPlayInterval);

        return () => clearInterval(timer); 
    }, [currentIndex, autoPlayInterval]);

    const goToNextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    };

    const goToPrevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
    };

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    return (
        <Box
        sx={{
            position: 'relative',
            width: 'auto',
            height: '600px',
            overflow: 'hidden',
            borderRadius: 2,
            boxShadow: 3,
            objectFit: "cover",
        }}
        >
            {
                _.map(data, (value, key) => {
                    let imageDetail = '/images/login_image.png';
                    if ( _.size(value?.content_images) > 0 ) {
                        let tempData = _.find(value?.content_images, { 'type': `notice_${language.language}` });
                        if (!isUndefined(tempData)) {
                            imageDetail = tempData?.file_name;
                        } else {
                            let defaultData = _.find(value?.content_images, { 'type': `notice_cn` });
                            if (defaultData) {
                                imageDetail = defaultData?.file_name;
                            }
                        }
                    }
                    return (
                        <Box
                            key={value.id}
                            component="img"
                            src={imageDetail}
                            alt={key}
                            sx={{
                                width: '100%',
                                // height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: `${(key - currentIndex) * 100}%`,
                                transition: 'left 0.5s ease',
                                
                            }}
                        />
                    )
                })
            }

            <IconButton
                onClick={goToPrevSlide}
                sx={{
                position: 'absolute',
                top: '50%',
                left: 16,
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                }}
            >
                <ArrowBackIosNewIcon />
            </IconButton>

            <IconButton
                onClick={goToNextSlide}
                sx={{
                position: 'absolute',
                top: '50%',
                right: 16,
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                }}
            >
                <ArrowForwardIosIcon />
            </IconButton>

            {/* <Box
                sx={{
                position: 'absolute',
                bottom: 16,
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                gap: 1,
                }}
            >
                {images.map((_, index) => (
                <Button
                    key={index}
                    onClick={() => goToSlide(index)}
                    sx={{
                    minWidth: '8px',
                    minHeight: '8px',
                    borderRadius: '50%',
                    padding: 0,
                    backgroundColor: index === currentIndex ? 'primary.main' : 'grey.400',
                    '&:hover': { backgroundColor: 'primary.dark' },
                    }}
                />
                ))}
            </Box> */}
        </Box>
    );
}

const NoticeItem = props => {
    const { info, popup = false , method , all , second } = props;
    const { t, i18n } = useTranslation();
    const parentRef = useRef();
    
    const getLangContent = () => {
        if (info?.content_type === "image") {
            if ( method === 'slide' && _.size(all) > 1 )
            {
                // method === 'slide' && _.size(all) > 1
                return SlideShow({ language : i18n , data : all , autoPlayInterval: second });
            }
            else
            {
                let imageDetail = '/images/login_image.png';
                if (_.size(info?.content_images) > 0) {
                    let tempData = _.find(info?.content_images, { 'type': `notice_${i18n.language}` });
                    if (!isUndefined(tempData)) {
                        imageDetail = tempData?.file_name;
                    } else {
                        let defaultData = _.find(info?.content_images, { 'type': `notice_cn` });
                        if (defaultData) {
                            imageDetail = defaultData?.file_name;
                        }
                    }
                }
    
                return (
                    <div>
                        <img src={imageDetail} alt="Notice" className='s-full ' style={{ objectFit: 'cover' }} />
                    </div>
                );
            }
        } else if (info?.content_type === "text") {
            // Accessing the iframe and logging its content
            // const iframe = parentRef.current?.getElementsByTagName('iframe');
            // if (_.size(iframe) > 0) {
            //     _.map(iframe, detail => {
            //         detail.style.width = '100%';
            //         detail.style.height = '240px';
            //     })
            // }

            if (info?.content[i18n.language] && info?.content[i18n.language] !== null) {
                return (
                    <div ref={parentRef} className='p-tb-10 txt-content'>
                        <div dangerouslySetInnerHTML={{ __html: info?.content[i18n.language]
                            ?.replace(/<img/g, '<img style="object-fit: contain; width: 100%; height: 100%;"')
                            ?.replace(/<iframe/g, '<iframe style="width: 100%;"')
                        }} />
                    </div>
                )
            } else {
                return (
                    <div ref={parentRef} className='p-tb-10 txt-content'>
                        <div dangerouslySetInnerHTML={{ __html: info?.content?.cn
                            ?.replace(/<img/g, '<img style="object-fit: contain; width: 100%; height: 100%;"')
                            ?.replace(/<iframe/g, '<iframe style="width: 100%;"')
                        }} />
                    </div>
                )
            }

        } else {
            return (
                <div>
                    <p className='txt-empty'>{t('general.noData')}</p>
                </div>
            )
        }

    }

    return getLangContent()
}

// DEFINE DEFAULT PROPS TYPE
NoticeItem.propTypes = {
    id: PropTypes.any,
};

// DEFINE DEFAULT PROPS VALUE
NoticeItem.defaultProps = {
    id: {},
};

export default NoticeItem;