import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _, { isNaN, isNil, } from 'lodash';

import jsQR from "jsqr";
import { Grid, Typography, Button, IconButton, TextField, InputAdornment, Hidden, FormControl, Select, OutlinedInput, MenuItem, FormHelperText } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiEye, FiEyeOff, FiEdit } from "react-icons/fi";
import { FaCheck } from "react-icons/fa";

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { storePaths, tradingAmountFormat, currencyFormat } from '@utils/Tools';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const INITIAL_STATE = {
    value: '',
    usedActiveUnit: 0,
    usedPassiveUnit: 0,
    usedTotalUnit: 0,
    // usedRedeemable: 0,
    usedSystemRedeemable: 0,
    securityPassword: '',
    showSecurityPassword: false,
    openCamera: true,
    type: '',
    wallet_ids: [],
};

export default function Spend() {
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    let location = useLocation();
    const isMountedRef = useRef(null);
    const { t, i18n } = useTranslation();
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const fileInputRef = useRef(null);
    const [activeStep, setActiveStep] = useState(0);
    const [state, setState] = useState(INITIAL_STATE);
    const [info, setInfo] = useState(null);
    const [inputErrors, setInputErrors] = useState({});
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [result, setResult] = useState(null);
    const [permissionStatus, setPermissionStatus] = useState('unknown');

    const params = new URLSearchParams(window.location.search);
    const mid = params.get('m');

    const steps = [
        t('spend.merchant'),
        t('spend.insertValue'),
        t('spend.makePayment'),
        t('spend.rateAdjustment'),
        t('spend.paymentSuccess'),
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getMerchantInfo = (value) => {
        if (!isNaN(value) && value > 0) {
            setLoading(true);
            getUrl(`/merchant-info`, { 'id': value }).then(response => {
                setLoading(false);
                let { status, message, error, data } = response;

                if (status) {
                    setSelectedMerchant(data);
                    setActiveStep(1);
                } else {
                    addAlert('', message || t('error.contactSupport'), 'error', '');
                    setState({ ...state, openCamera: true });
                    startCamera();
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const handleScan = () => {
        const video = videoRef.current;

        if (!video) {
            // Video element not yet initialized, exit function
            return;
        }

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if (!context) {
            console.error('Canvas context not available');
            return;
        }

        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
            let splitText = (code.data).split("?m=");
            let param = splitText[1];

            let decodedId = atob(param);
            if (decodedId > 0) {
                cancelAnimationFrame(handleScan);
                getMerchantInfo(decodedId);
                setState({ ...state, openCamera: false });
                stopCamera();
            }else {
                addAlert(t('spend.invalidLink'), 'error', '', '');
            }
        } else {
            // Repeat capture process
            requestAnimationFrame(handleScan);
            console.log('QR code not detected');
        }

    };

    // Access the camera and start the video stream
    const startCamera = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
                .then(stream => {
                    const video = videoRef.current;
                    video.srcObject = stream;
                    video.onloadedmetadata = () => {
                        video.play()
                            .then(() => {
                                console.log('Video started successfully.');
                                handleScan(); // Delay scanning for 1 second to ensure video has started
                            })
                            .catch(error => {
                                console.error('Error starting video playback:', error);
                            });
                    };
                })
                .catch(error => {
                    console.error('Error accessing the camera:', error);
                });
        } else {
            addAlert('', t('error.cameraNotSuport'), 'error', '');
        }
    };

    // Stop the video stream when the component unmounts
    const stopCamera = () => {
        const video = videoRef.current;
        if (video && video.srcObject) {
            const stream = video.srcObject;
            const tracks = stream.getTracks();

            tracks.forEach(track => {
                try {
                    track.stop();
                } catch (error) {
                    console.error('Error stopping track:', error);
                }
            });
            video.srcObject = null; // Reset video source object
        } else {
            console.warn('No video element or source object found.');
        }
    };

    useEffect(() => {
        const handleNavigation = () => {
            stopCamera();
        };

        window.addEventListener('camera', handleNavigation);

        return () => {
            window.removeEventListener('camera', handleNavigation);
        };
    }, []);

    useEffect(() => {
        switch (activeStep) {
            case 0:
                if (state?.openCamera) {
                    startCamera();
                }
                break;
            case 1:
                stopCamera();
                isMountedRef.current = true;
                setLoading(true);
                getUrl(`/spend-rule`).then(response => {
                    setLoading(false);
                    if (isMountedRef.current) {
                        let { status, message, error, data } = response;

                        if (status) {
                            setInfo(data);
                        } else {
                            if (error || message) {
                                if (_.size(error) > 0) {
                                    _.map(error, (value, key) => {
                                        message += "\n " + value[0];
                                    })
                                }
                                addAlert('', message || t('error.contactSupport'), 'error', '');
                            }
                        }
                    }

                }).catch(error => {
                    if (isMountedRef.current) {
                        setLoading(false);
                        addAlert('', JSON.stringify(error.message), 'error', '');
                    }
                });
                return () => { isMountedRef.current = false };

            default:
                stopCamera();
                return;
                break;
        }
    }, [activeStep, state?.openCamera]);

    useEffect(() => {
        if (!isNil(mid)) {
            const decodedId = atob(mid);
            setState(prevState => ({
                ...prevState,
                openCamera: false,
            }));
            stopCamera();
            getMerchantInfo(decodedId);
        }
    }, [mid]);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name) {
            case 'value':
                // Remove all non-numeric characters except the decimal point
                value = value.replace(/[^0-9.]/g, '');

                // Ensure only one decimal point is present
                const decimalCount = value.split('.').length - 1;
                if (decimalCount > 1) {
                    value = value.slice(0, value.lastIndexOf('.'));
                }

                // Limit decimal places to two
                const parts = value.split('.');
                let decimal = 2;
                if (state?.type === 'wallet') {
                    decimal = info?.user_balance[0]?.basic?.decimal
                }
                if (parts[1] && parts[1].length > decimal) {
                    value = `${parts[0]}.${parts[1].slice(0, decimal)}`;
                }
                setState({ ...state, [name]: value });
                break;
            case 'type':
                setState({ ...state, [name]: value, value: '', wallet_ids: [] });
                break;
            default:
                setState({ ...state, [name]: value });
                break;
        }
    };

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showSecurityPassword: !state.showSecurityPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleReset = () => {
        setActiveStep(0);
        setSelectedMerchant(null);
        setResult({});
        setState(INITIAL_STATE);
        navigate(`/spend`, { state: { from: storePaths(location) } });
    };

    const clearMerchant = () => {
        setSelectedMerchant(null);
        setActiveStep(0);
        startCamera();
        navigate(`/spend`, { state: { from: storePaths(location) } });
    }


    const estimatePayment = () => {
        if (state?.value > 0) {
            if (state?.type === "wallet") {
                let wallet = info?.user_balance[0];
                setState({ ...state, wallet_ids: [info?.user_balance[0]?.id] });
                if (parseFloat(state?.value) > parseFloat(wallet?.balance)) {
                    addAlert('', t('error.walletInsufficientBalance'), 'error', '');
                } else {
                    if (activeStep === 1) {
                        handleNext();
                    }
                }
            } else if (state?.type === "trade") {
                if (parseFloat(state?.value) <= parseFloat(info?.trade?.balance_redeemable?.system_redeemable)) {
                    //estimate redeemable value
                    let left = _.clone(state?.value);
                    // let usedRedeemable = 0;
                    let systemRedeemable = 0;
                    // if (info?.trade?.balance_redeemable?.redeemable > 0) {
                    //     usedRedeemable = Math.min(left, info?.trade?.balance_redeemable?.redeemable);
                    //     left = left - usedRedeemable;
                    // }

                    if (left > 0 && info?.trade?.balance_redeemable?.system_redeemable > 0) {
                        systemRedeemable = Math.min(left, info?.trade?.balance_redeemable?.system_redeemable);
                        left = left - info?.trade?.balance_redeemable?.system_redeemable;
                    }

                    //estimate unit
                    let sumUnit = Math.ceil(_.clone(state?.value) / info?.trade?.current_price);
                    let leftUnit = sumUnit;
                    let usedPassive = 0;
                    let usedActive = 0;
                    if (info?.trade?.balance_unit?.sum_passive > 0) {
                        usedPassive = Math.min(leftUnit, info?.trade?.balance_unit?.sum_passive);
                        leftUnit = leftUnit - usedPassive;
                    }

                    if (leftUnit > 0 && info?.trade?.balance_unit?.sum_active > 0) {
                        usedActive = Math.min(leftUnit, info?.trade?.balance_unit?.sum_active);
                        leftUnit = leftUnit - usedActive;
                    }

                    if (leftUnit === 0) {
                        setState(prevState => ({
                            ...prevState,
                            // usedRedeemable: usedRedeemable,
                            usedSystemRedeemable: systemRedeemable,
                            usedPassiveUnit: usedPassive,
                            usedActiveUnit: usedActive,
                            usedTotalUnit: sumUnit,
                        }));
                        if (activeStep === 1) {
                            handleNext();
                        }
                    } else {
                        addAlert('', t('error.unitInsufficientBalance'), 'error', '');
                    }

                } else {
                    addAlert('', t('error.valueInsufficientBalance'), 'error', '');
                }
            }
        } else {
            addAlert('', t('error.requiredFillAllSection'), 'error', '');
        }
    }

    const makePayment = () => {
        setLoading(true);
        setInputErrors({});
        let params = {
            value: state?.value,
            security_password: state?.securityPassword,
            merchant_id: selectedMerchant?.id,
            type: state?.type,
        }

        if (state?.type === "trade") {
            params['current_rate'] = info?.trade?.current_price;
        } else if (state?.type === "wallet") {
            params['wallet_ids'] = state?.wallet_ids;
        }

        postUrl(`/spend`, params).then(response => {
            setLoading(false);
            let { status, message, data, errors } = response;

            if (status) {
                setActiveStep(4);
                if(state?.type === 'wallet'){
                    setResult(data);
                }else if(state?.type === 'trade'){
                    setResult(data.data);
                }
                addAlert('', message || t('success.purchaseSuccess'), 'success', '');

            } else {
                if (errors?.current_rate) {
                    setActiveStep(3);
                    setInfo(previnfo => ({
                        ...previnfo,
                        trade:{
                            ...info?.trade,
                            current_price: data?.current_price,
                        },
                        old_current_price: info?.trade?.current_price,
                    }));
                    estimatePayment();
                } else {
                    addAlert('', message || t('error.contactSupport'), 'error', '');
                    setInputErrors(errors);
                }
            }

        }).catch(error => {
            setLoading(false);
            addAlert('', JSON.stringify(error.message), 'error', '');
        });
    }

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } });
    };

    const triggerFileInput = () => {
        fileInputRef.current.click(); // Programmatically trigger the hidden input
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const image = new Image();
                image.src = e.target.result;

                image.onload = function () {
                    // Create a canvas to extract the QR code
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    context.drawImage(image, 0, 0, image.width, image.height);

                    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                    const code = jsQR(imageData.data, canvas.width, canvas.height);

                    if (code) {
                        let splitText = (code.data).split("?m=");
                        let param = splitText[1];
                        let decodedId = atob(param);
                        if (decodedId > 0) {
                            getMerchantInfo(decodedId);
                            setState({ ...state, openCamera: false });
                            stopCamera();
                        }else {
                            addAlert(t('spend.invalidLink'), 'error', '', '');
                        }
                    } else {
                        addAlert(t('spend.noQRCodeFound'), 'error', '', '');
                    }
                };
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        checkCameraPermission();
    }, []);

    const checkCameraPermission = async () => {
        try {
            const userAgent  = navigator.userAgent;
            const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
            const isIE = /MSIE|Trident/.test(userAgent);

            if(isSafari || isIE){
                setPermissionStatus('granted');
                startCamera();

            }else{
                const permission = await navigator.permissions.query({ name: 'camera' });
                setPermissionStatus(permission.state);

                if (permission.state === 'granted') {
                    startCamera();
                }
    
                // Listen for permission changes
                permission.onchange = () => {
                    setPermissionStatus(permission.state);
                    if (permission.state === 'granted') {
                        startCamera();
                    }
                };
            }


        } catch (error) {
            // If an error occurs, permission is denied or not granted yet
            if (error.name === 'NotAllowedError') {
                setPermissionStatus('denied');
            } else {
                setPermissionStatus('error');
            }
        }
    };

    const stepOne = () => {
        return (
            <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                {console.log(permissionStatus)}
                {
                    permissionStatus === 'granted' &&
                    <div className='flex-c-m s-full pos-relative'>
                        <video ref={videoRef} style={{ borderRadius: 15 }} autoPlay playsInline muted className='s-full' />
                        <canvas ref={canvasRef} style={{ display: 'none' }} />
                    </div>
                }
                <div className='s-full m-t-20'>
                    <input type="file" accept="image/*" ref={fileInputRef} onChange={handleImageUpload} style={{ display: 'none' }} />
                    <Button fullWidth variant="contained" color="primary" size="large" onClick={triggerFileInput} >{t('button.uploadImage')}</Button>
                </div>
            </div>
        )
    };

    const stepTwo = () => {
        return (
            <div className='p-tb-30 p-lr-15'>
                <div className='w-full' style={{ top: 20, paddingBottom: 20 }}>
                    <Grid container spacing={2} className='flex-c-m'>
                        <Grid item xs={12}>
                            <Typography className="text-gold-short" >{t('plan.merchantName')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='flex-sb-m box-out' style={{ borderRadius: 10, padding: 20 }}>
                                <Hidden mdDown>
                                    <div style={{ width: 70, height: 70 }} className='flex-c-m'>
                                        <img src={'/images/logos/logo.png'} alt="plan_logo" loading='lazy' style={{ aspectRatio: 1 / 1, objectFit: 'contain' }} />
                                    </div>
                                </Hidden>
                                <div className='w-full p-l-20' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ color: theme.palette.white.main, fontWeight: 'bold', wordBreak: 'break-all' }}>{selectedMerchant?.name ? selectedMerchant?.name?.[i18n.language] : selectedMerchant?.name?.en}</Typography>
                                    <Typography style={{ color: theme.palette.white.main, fontStyle: 'italic', wordBreak: 'break-all' }}>{selectedMerchant?.email ? selectedMerchant?.email : ""}</Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', minHeight: 70, cursor: 'pointer' }} onClick={clearMerchant}>
                                    <div className='flex-c-m gradient-gold-btn' style={{ padding: 7, borderRadius: 5, top: 0 }}><FiEdit style={{ color: theme.palette.darkBase.main }} /></div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-l-m'>
                            <FormControl sx={{ width: '100%' }} variant="standard" className={styles.textFieldBox} error={inputErrors && inputErrors?.type ? true : false}>
                                <Select
                                    name="type"
                                    value={state?.type || ""}
                                    onChange={handleChange}
                                    displayEmpty
                                    input={<OutlinedInput sx={{ border: 'none' }} />}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <em>{t('spend.type')}</em>;
                                        }
                                        return info?.type[selected] ? (i18n.language === 'cn' ? info?.type[selected]?.cn : info?.type[selected]?.en) : selected;
                                    }}
                                    MenuProps={MenuProps}
                                    sx={{
                                        // '& .MuiSelect-select': {
                                        //     border: 'none',
                                        //     borderRadius: 15,
                                        //     boxShadow: 'none',
                                        //     color: theme.palette.darkBase.main,
                                        // },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                            borderRadius: 15,
                                            boxShadow: 'none',
                                        },
                                        // '& .MuiSelect-icon': {
                                        //     color: theme.palette.darkBase.main,
                                        //     marginRight: 1,
                                        // },
                                    }}
                                >
                                    {_.size(info?.type) > 0 ? (
                                        _.map(info?.type, (option, key) => (
                                            <MenuItem key={key} value={key} style={{ color: '#222' }}>
                                                {i18n.language === 'cn' ? option.cn : option.en}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" style={{ color: '#222' }}>
                                            {t('general.pleaseSelect')}
                                        </MenuItem>
                                    )}
                                </Select>
                                {
                                    inputErrors && inputErrors['country'] &&
                                    <FormHelperText style={{ color: 'red' }}>{inputErrors['country']}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        {
                            state?.type !== '' &&
                            <>
                                <Grid item xs={12} container className='flex-l-m'>
                                    <Typography className="text-gold-short" style={{ fontWeight: 'bold' }}>{t('spend.availableBalance')}</Typography>
                                </Grid>
                                {
                                    state?.type === 'wallet' && _.size(info?.user_balance) > 0 &&
                                    _.map(info?.user_balance, wallet => {
                                        return (
                                            <Grid item xs={12} container className='flex-sb-m' key={wallet?.id}>
                                                <div className='flex-l-m'>
                                                    <Typography variant="body2" style={{ color: theme.palette.white.main }}>{wallet?.basic?.name[i18n.language] ? wallet?.basic?.name[i18n.language] : wallet?.basic?.name?.en}</Typography>
                                                </div>
                                                <div>
                                                    <Typography variant="body2" style={{ color: theme.palette.white.main }}>{`$ ${currencyFormat(wallet?.balance || 0, wallet?.basic?.decimal)} `}</Typography>
                                                </div>
                                            </Grid>
                                        )
                                    })
                                }
                                {
                                    state?.type === 'trade' &&
                                    <>
                                        {/* merchant redeemable value */}
                                        {/* <Grid item xs={12} container className='flex-sb-m'>
                                            <div className='flex-l-m'>
                                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.redeemableValue')}</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{`$ ${tradingAmountFormat(info?.redeemable) || 0} `}</Typography>
                                            </div>
                                        </Grid> */}
                                        <Grid item xs={12} container className='flex-sb-m'>
                                            <div className='flex-l-m'>
                                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.systemRedeemableValue')}</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{`$ ${tradingAmountFormat(info?.trade?.balance_redeemable?.system_redeemable) || 0} `}</Typography>
                                            </div>
                                        </Grid>
                                        {/* <Grid item xs={12} container className='flex-sb-m'>
                                            <div className='flex-l-m'>
                                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.passiveUnit')}</Typography>
                                            </div>
                                            <div>
                                                <div className='flex-c-m'>
                                                    <Typography variant="body2" className="text-gold-short" style={{ fontStyle: 'italic' }}>{` ≈ $${tradingAmountFormat(parseFloat(info?.sum_passive * info?.current_price).toFixed(3))}`}</Typography>
                                                    <Typography variant="body2" style={{ color: theme.palette.white.main, marginLeft: 10 }}>{info?.sum_passive || 0}</Typography>
                                                </div>
                                            </div>
                                        </Grid> */}
                                        <Grid item xs={12} container className='flex-sb-m'>
                                            <div className='flex-l-m'>
                                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.activeUnit')}</Typography>
                                            </div>
                                            <div>
                                                <div className='flex-c-m'>
                                                    <Typography variant="body2" className="text-gold-short" style={{ fontStyle: 'italic' }}>{` ≈ $${tradingAmountFormat(parseFloat(parseInt(info?.trade?.balance_unit?.sum_active + info?.trade?.balance_unit?.sum_passive) * info?.trade?.current_price).toFixed(info?.trade?.decimal || 3))}`}</Typography>
                                                    <Typography variant="body2" style={{ color: theme.palette.white.main, marginLeft: 10 }}>{parseInt(info?.trade?.balance_unit?.sum_active + info?.trade?.balance_unit?.sum_passive) || 0}</Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                        {/* <Grid item xs={12} container className='flex-sb-m'>
                                            <div className='flex-l-m'>
                                                <Typography variant="body2" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{t('spend.approximatelyTotalValue')}</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="body2" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{`$${tradingAmountFormat(parseFloat((info?.trade?.balance_unit?.sum_active * info?.trade?.current_price) + (info?.trade?.balance_unit?.sum_passive * info?.trade?.current_price)).toFixed(info?.trade?.decimal || 3))}`}</Typography>
                                            </div>
                                        </Grid> */}

                                        <Grid item xs={12} container className='flex-sb-m' style={{ marginTop: 10 }} >
                                            <div className='flex-l-m'>
                                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.currentMarketPrice')}</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{`$ ${info?.trade?.current_price || 0} `}</Typography>
                                            </div>
                                        </Grid>
                                    </>
                                }
                            </>
                        }
                        {
                            state?.type !== '' &&
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        name='value'
                                        placeholder={t('spend.value')}
                                        value={state?.value}
                                        onChange={handleChange}
                                        required
                                        helperText={inputErrors && inputErrors.value ? inputErrors.value : ''}
                                        error={inputErrors && inputErrors.value ? true : false}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ disableUnderline: true }}
                                        className={styles.textFieldStyle}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        className='pos-absolute'
                                        fullWidth
                                        onClick={estimatePayment}
                                    >
                                        {t('button.continue')}
                                    </Button>
                                </Grid>
                            </>
                        }
                    </Grid>
                </div>
            </div>
        )
    };

    const stepThree = () => {
        return (
            <div className='p-tb-30 p-lr-15'>
                <div className='w-full' style={{ top: 20, paddingBottom: 20 }}>
                    <Grid container spacing={2} className='flex-c-m'>
                        <Grid item xs={12}>
                            <Typography className="text-gold-short" >{t('plan.merchantName')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='flex-sb-m box-out' style={{ borderRadius: 10, padding: 20 }}>
                                <Hidden mdDown>
                                    <div style={{ width: 70, height: 70 }} className='flex-c-m'>
                                        <img src={'/images/logos/logo.png'} alt="plan_logo" loading='lazy' style={{ aspectRatio: 1 / 1, objectFit: 'contain' }} />
                                    </div>
                                </Hidden>
                                <div className='w-full p-l-20' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography style={{ color: theme.palette.white.main, fontWeight: 'bold', wordBreak: 'break-all' }}>{selectedMerchant?.name ? selectedMerchant?.name?.[i18n.language] : selectedMerchant?.name?.en}</Typography>
                                    <Typography style={{ color: theme.palette.white.main, fontStyle: 'italic', wordBreak: 'break-all' }}>{selectedMerchant?.email ? selectedMerchant?.email : ""}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-l-m'>
                            <Typography className="text-gold-short" style={{ fontWeight: 'bold' }}>{t('spend.paymentBreakdown')}</Typography>
                        </Grid>
                        {
                            state?.type === "wallet" &&
                            <>
                                <Grid item xs={12} container className='flex-sb-m'>
                                    <div className='flex-l-m'>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main }}>{info?.user_balance[0]?.basic?.name[i18n.language] ? info?.user_balance[0]?.basic?.name[i18n.language] : info?.user_balance[0]?.basic?.name?.en}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main }}>{`$ ${currencyFormat(state?.value, info?.user_balance[0]?.basic?.decimal)} `}</Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} container className='flex-sb-m'>
                                    <div className='flex-l-m'>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{t('spend.totalPurchaseValue')}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{`$${currencyFormat(state?.value, info?.user_balance[0]?.basic?.decimal)}`}</Typography>
                                    </div>
                                </Grid>
                            </>
                        }
                        {
                            state?.type === "trade" &&
                            <>
                                {/* <Grid item xs={12} container className='flex-sb-m'>
                                    <div className='flex-l-m'>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.redeemableValue')}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main }}>{`$ ${tradingAmountFormat(parseFloat(state?.usedRedeemable).toFixed(3)) || 0} `}</Typography>
                                    </div>
                                </Grid> */}
                                <Grid item xs={12} container className='flex-sb-m'>
                                    <div className='flex-l-m'>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.systemRedeemableValue')}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main }}>{`$ ${tradingAmountFormat(parseFloat(state?.usedSystemRedeemable).toFixed(info?.trade?.decimal)) || 0} `}</Typography>
                                    </div>
                                </Grid>
                                {/* <Grid item xs={12} container className='flex-sb-m'>
                                    <div className='flex-l-m'>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.passiveUnit')}</Typography>
                                    </div>
                                    <div>
                                        <div className='flex-c-m'>
                                            <Typography variant="body2" className="text-gold-short" style={{ fontStyle: 'italic' }}>{`$${tradingAmountFormat(parseFloat(state?.usedPassiveUnit * info?.current_price).toFixed(3))}`}</Typography>
                                            <Typography variant="body2" style={{ color: theme.palette.white.main, marginLeft: 10 }}>{`≈ ${state?.usedPassiveUnit || 0}`}</Typography>
                                        </div>
                                    </div>
                                </Grid> */}
                                <Grid item xs={12} container className='flex-sb-m'>
                                    <div className='flex-l-m'>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.activeUnit')}</Typography>
                                    </div>
                                    <div>
                                        <div className='flex-c-m'>
                                            <Typography variant="body2" className="text-gold-short" style={{ fontStyle: 'italic' }}>{`$${tradingAmountFormat(parseFloat(state?.usedActiveUnit * info?.trade?.current_price).toFixed(info?.trade?.decimal))}`}</Typography>
                                            <Typography variant="body2" style={{ color: theme.palette.white.main, marginLeft: 10 }}>{`≈ ${state?.usedActiveUnit || 0}`}</Typography>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} container className='flex-sb-m' style={{ paddingTop: 0 }}>
                                    <div className='flex-l-m'>
                                        <Typography variant="caption" style={{ color: theme.palette.white.main, fontStyle: 'italic' }}>{t('spend.currentMarketPrice')}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="caption" style={{ color: theme.palette.white.main, fontStyle: 'italic' }}>{`$ ${info?.trade?.current_price || 0} `}</Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} container className='flex-sb-m'>
                                    <div className='flex-l-m'>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{t('spend.totalPurchaseValue')}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body2" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{`$${tradingAmountFormat(parseFloat(state?.value).toFixed(2))}`}</Typography>
                                    </div>
                                </Grid>
                            </>
                        }

                        <Grid item xs={12}>
                            <TextField
                                sx={{ '& .MuiInputBase-root': { borderRadius: '10px' } }}
                                variant="standard"
                                placeholder={t('user.securityPassword')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle securityPassword visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {state?.showSecurityPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                name='securityPassword'
                                type={state.showSecurityPassword ? 'text' : 'password'}
                                color='primary'
                                value={state.securityPassword}
                                onChange={handleChange}
                                className={styles.textFieldStyle}
                                helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                                error={inputErrors && inputErrors.security_password ? true : false}
                            />
                        </Grid>
                        <Grid container item xs={12} className='flex-c-m' >
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="outlined"
                                    className='pos-absolute'
                                    fullWidth
                                    onClick={handleBack}
                                    style={{
                                        boxShadow: '2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        '&:hover': {
                                            boxShadow: '2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        }
                                    }}
                                >
                                    {t('button.back')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="contained"
                                    className='medium-button-gradient pos-absolute'
                                    fullWidth
                                    onClick={makePayment}
                                >
                                    {t('button.purchaseNow')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    };

    const stepFour = () => {
        return (
            <div className='p-tb-30 p-lr-15'>
                <div className='w-full' style={{ top: 20, paddingBottom: 20 }}>
                    <Grid container spacing={2} className='flex-c-m'>
                        <Grid item xs={12}>
                            <Typography style={{ color: theme.palette.white.main, textAlign: 'center' }}>{t('spend.rateAdjustmentDesc')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='flex-sa-m box-out' style={{ borderRadius: 10, padding: 20 }}>
                                <div>
                                    <Typography variant="h6" style={{ color: theme.palette.white.main }}>{info?.old_current_price}</Typography>
                                </div>
                                <div>
                                    <Typography variant='subtitle1' className="text-gold-short">{t('spend.to')}</Typography>
                                </div>
                                <div><Typography variant="h6" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{info?.trade?.current_price}</Typography></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-l-m' style={{ marginTop: 10 }}>
                            <Typography className="text-gold-short" style={{ fontWeight: 'bold' }}>{t('spend.paymentBreakdown')}</Typography>
                        </Grid>
                        {/* <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.redeemableValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{`$ ${tradingAmountFormat(parseFloat(state?.usedRedeemable).toFixed(info?.trade?.decimal)) || 0} `}</Typography>
                            </div>
                        </Grid> */}
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.systemRedeemableValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{`$ ${tradingAmountFormat(parseFloat(state?.usedSystemRedeemable).toFixed(info?.trade?.decimal)) || 0} `}</Typography>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.passiveUnit')}</Typography>
                            </div>
                            <div>
                                <div className='flex-c-m'>
                                    <Typography variant="body2" className="text-gold-short" style={{ fontStyle: 'italic' }}>{`$${tradingAmountFormat(parseFloat(state?.usedPassiveUnit * info?.current_price).toFixed(3))}`}</Typography>
                                    <Typography variant="body2" style={{ color: theme.palette.white.main, marginLeft: 10 }}>{`≈ ${state?.usedPassiveUnit || 0}`}</Typography>
                                </div>
                            </div>
                        </Grid> */}
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.white.main }}>{t('spend.activeUnit')}</Typography>
                            </div>
                            <div>
                                <div className='flex-c-m'>
                                    <Typography variant="body2" className="text-gold-short" style={{ fontStyle: 'italic' }}>{`$${tradingAmountFormat(parseFloat(state?.usedActiveUnit * info?.trade?.current_price).toFixed(info?.trade?.decimal))}`}</Typography>
                                    <Typography variant="body2" style={{ color: theme.palette.white.main, marginLeft: 10 }}>{`≈ ${state?.usedActiveUnit || 0}`}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m' style={{ paddingTop: 0 }}>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.white.main, fontStyle: 'italic' }}>{t('spend.currentMarketPrice')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.white.main, fontStyle: 'italic' }}>{`$ ${info?.trade?.current_price || 0} `}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-sb-m'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{t('spend.totalPurchaseValue')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{`$${tradingAmountFormat(parseFloat(state?.value).toFixed(info?.trade?.decimal))}`}</Typography>
                            </div>
                        </Grid>

                        <Grid container item xs={12} className='flex-c-m' >
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="outlined"
                                    className='pos-absolute'
                                    fullWidth
                                    onClick={handleBack}
                                    style={{
                                        boxShadow: '2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        '&:hover': {
                                            boxShadow: '2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        }
                                    }}
                                >
                                    {t('button.back')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="contained"
                                    className='medium-button-gradient pos-absolute'
                                    fullWidth
                                    onClick={makePayment}
                                >
                                    {t('button.purchaseNow')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const stepFive = () => {
        return (
            <div className='p-tb-30 p-lr-15'>
                <div className='w-full' style={{ top: 20, paddingBottom: 20 }}>
                    <Grid container spacing={2} className='flex-c-m' style={{ textAlign: 'center' }}>
                        <Grid item xs={12}>
                            <img src={'/images/logos/logo.png'} alt="plan_logo" loading='lazy' style={{ width: 60, aspectRatio: 1 / 1 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ color: theme.palette.white.main, fontWeight: 'bold', wordBreak: 'break-all' }}>{selectedMerchant?.name ? selectedMerchant?.name?.[i18n.language] : selectedMerchant?.name?.en}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 5 }}>
                            <Typography style={{ color: theme.palette.white.main, fontStyle: 'italic', wordBreak: 'break-all' }}>{selectedMerchant?.email ? selectedMerchant?.email : ""}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='flex-c-m w-full box-out' style={{ padding: 15, borderRadius: 10, flexDirection: 'column', flexWrap: 'wrap' }}>
                                <div className='flex-sb-m w-full'>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.white.main }}>{t('trade.dateTime')}</Typography>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.white.main, fontStyle: 'italic' }}>{result?.trade_transaction_record?.trade_transaction?.created_at || result?.created_at || '-'}</Typography>
                                </div>
                                <div className='flex-sb-m w-full'>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.white.main }}>{t('spend.transactionId')}</Typography>
                                    <Typography variant="subtitle1" style={{ color: theme.palette.white.main, fontStyle: 'italic', wordBreak: 'break-all', textAlign: 'end', width: '45%' }}>{result?.trade_transaction_record?.trade_transaction?.ulid || result?.ulid || '-'}</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='flex-c-m w-full box-out' style={{ padding: 15, borderRadius: 10, flexDirection: 'column' }}>
                                <div className='w-full' style={{ textAlign: 'left' }}>
                                    <Typography className="text-gold-short" style={{ fontWeight: 'bold' }}>{t('spend.paymentBreakdown')}</Typography>
                                </div>
                                {
                                    state?.type === "wallet" &&
                                    <>
                                        <div className='flex-sb-m w-full p-tb-5'>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main }}>{info?.user_balance[0]?.basic?.name[i18n.language] ? info?.user_balance[0]?.basic?.name[i18n.language] : info?.user_balance[0]?.basic?.name?.en}</Typography>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main }}>{`$ ${currencyFormat(state?.value, info?.user_balance[0]?.basic?.decimal)}`}</Typography>
                                        </div>
                                        <div className='flex-sb-m w-full m-t-10'>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{t('spend.totalPurchaseValue')}</Typography>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{currencyFormat(result?.amount, info?.user_balance[0]?.basic?.decimal)}</Typography>
                                        </div>
                                    </>
                                }
                                {
                                    state?.type === "trade" &&
                                    <>
                                        <div className='flex-sb-m w-full p-tb-5'>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main }}>{t('spend.redeemableValue')}</Typography>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main }}>{`$ ${tradingAmountFormat(parseFloat(result?.trade_redeemable_transaction?.system_rv).toFixed(info?.trade?.decimal)) || 0}`}</Typography>
                                        </div>
                                        {/* <div className='flex-sb-m w-full p-tb-5'>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main }}>{t('spend.passiveUnit')}</Typography>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main }}>{tradingAmountFormat(result?.trade_transaction_record?.trade_transaction?.passive_unit) || 0}</Typography>
                                        </div> */}
                                        <div className='flex-sb-m w-full p-t-5'>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main }}>{t('spend.activeUnit')}</Typography>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main }}>{tradingAmountFormat(result?.trade_transaction_record?.trade_transaction?.active_unit) || 0}</Typography>
                                        </div>
                                        <div className='flex-sb-m w-full'>
                                            <Typography variant="caption" style={{ color: theme.palette.white.main, fontStyle: 'italic' }}>{t('spend.currentMarketPrice')}</Typography>
                                            <Typography variant="caption" style={{ color: theme.palette.white.main, fontStyle: 'italic' }}>{tradingAmountFormat(result?.trade_transaction_record?.trade_transaction?.trade_price) || 0}</Typography>
                                        </div>
                                        <div className='flex-sb-m w-full m-t-10'>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{t('spend.totalPurchaseValue')}</Typography>
                                            <Typography variant="subtitle1" style={{ color: theme.palette.white.main, fontWeight: 'bold' }}>{tradingAmountFormat(parseFloat(state?.value).toFixed(2)) || 0}</Typography>
                                        </div>
                                    </>
                                }
                            </div>
                        </Grid>
                        <Grid container item xs={12} className='flex-c-m' >
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="outlined"
                                    className='pos-absolute'
                                    fullWidth
                                    style={{
                                        boxShadow: '2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        '&:hover': {
                                            boxShadow: '2px 2px 3px 0px #0000001A,2px 2px 4px 0px #FFFFFF85 inset,-2px -2px 4px 0px #00000021 inset',
                                        }
                                    }}
                                    onClick={() => goToLink("/spend-history")}
                                >
                                    {t('button.history')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="contained"
                                    className='medium-button-gradient pos-absolute'
                                    fullWidth
                                    onClick={handleReset}
                                >
                                    {t('button.newPayment')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const stepperDisplay = (currStep) => {
        switch (currStep) {
            case 0:
                return stepOne();
            case 1:
                return stepTwo();
            case 2:
                return stepThree();
            case 3:
                return stepFour();
            case 4:
                return stepFive();
            default:
                return null;
        }
    }

    return (
        <>
            <div className='dashboard-container'>
                <div className='dashboard-w'>
                    {
                        activeStep === 4 &&
                        <div className='flex-c-m w-full' style={{ height: 50, marginBottom: 10 }}>
                            <FaCheck className={styles.checkIcon} />
                        </div>
                    }
                    <div className='flex-c-m'>
                        <Typography variant='h6' style={{ textAlign: 'center', color: theme.palette.primary.secondary, fontWeight: 700, fontSize: 25 }} >
                            <b>
                                {steps[activeStep]}
                            </b>
                        </Typography>
                    </div>
                    {stepperDisplay(activeStep)}
                </div>
            </div>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        // maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px'
    },
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    checkIcon: {
        color: "#47AB8D",
        width: 100,
        height: 50,
    },
}));