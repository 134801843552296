    import PropTypes from 'prop-types';
    import React from 'react';
    import { useTranslation } from 'react-i18next';
    import { Grid, Typography, useMediaQuery } from '@mui/material';
    import { useTheme } from '@mui/styles';
    import { currencyFormat } from '@utils/Tools';

    const MerchantItem = props => {
        const { merchantLogo, merchantCode, merchantName, totalValue, currentPrice, totalUnit } = props;
        const mobileView = useMediaQuery((theme) => theme.breakpoints.down('sm'));
        const theme = useTheme();
        const { t } = useTranslation();

        return (
            <Grid
                container
                alignItems="center"
                style={{
                    display: 'flex',
                    flexWrap: mobileView ? 'wrap' : 'nowrap',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: mobileView ? 'column' : 'row',
                }}
            >
                {/* Merchant logo */}
                {merchantLogo ? (
                    <Grid item>
                        <img
                            src={merchantLogo}
                            alt={`Merchant Logo - ${merchantName}`}
                            style={{ maxWidth: '110px', maxHeight: '110px', borderRadius: 8 }}
                        />
                    </Grid>
                ) : (
                    <Grid item>
                        <img
                            src="/images/logos/logo.png"
                            alt={`Default Merchant Logo - ${merchantName}`}
                            style={{ maxWidth: '110px', maxHeight: '110px' }}
                        />
                    </Grid>
                )}
                {/* Merchant name, total value, and current price */}
                <Grid item style={{ marginLeft: mobileView ? '0' : '16px', textAlign: mobileView ? 'center' : 'initial' }}>
                    {(merchantName && merchantCode) ? (
                        <Typography style={{ color: theme.palette.gray.main, fontSize: '27px', fontWeight: 700, lineHeight: '1.8rem' }}>
                            {merchantName} <br></br> <span style={{ fontSize: 20 }}>({merchantCode})</span>
                        </Typography>
                    ) : (
                        <Typography style={{ color: theme.palette.gray.main, fontSize: '27px', fontWeight: 700, lineHeight: '1.8rem' }}>
                            {t('trade.global')}
                        </Typography>
                    )}
                    <Typography style={{ fontSize: '21px', fontWeight: 700 }}>
                        <span style={{ color: theme.palette.gray.main, fontSize: '25px' }}>
                            ${totalValue || 0.00}
                        </span>
                        {' '}
                        <span style={{ color: theme.palette.darkGray.main, fontStyle: 'italic' }}>
                            ≈
                        </span>
                        {' '}
                        <span style={{ color: theme.palette.darkGray.main, fontStyle: 'italic' }}>
                            {totalUnit || 0} {t('general.unit').toUpperCase()}
                        </span>
                    </Typography>
                    <Typography style={{ color: theme.palette.darkGray.main, fontSize: '12px', fontWeight: 400, fontStyle: 'italic' }}>
                        {t('trade.currentPrice')}: ${(currentPrice.toFixed(3)) || 0}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    // DEFINE PROP TYPES
    MerchantItem.propTypes = {
        merchantLogo: PropTypes.string,
        merchantCode: PropTypes.string,
        merchantName: PropTypes.string,
        totalValue: PropTypes.string,
        currentPrice: PropTypes.number,
    };

    // DEFINE DEFAULT PROPS VALUE
    MerchantItem.defaultProps = {
        merchantLogo: '',
        merchantCode: '',
        merchantName: '',
        totalValue: 0,
        currentPrice: 0,
    };

    export default MerchantItem;