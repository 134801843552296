import { PropTypes } from 'prop-types';
import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { Typography, , Box, Select, MenuItem, FormControl } from '@mui/material';
import { currencyFormat } from '@utils/Tools';

import { Box, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

const BonusCard = props => {
    const {bonusCode, bonusAmount, walletDecimer, displayName, backgroundColorCode, imgFilterColor, textColor} = props;
    const styles = useStyles();
    // const theme = useTheme();
    // const { t, i18n } = useTranslation();

    // Determine wallet icon path based on the wallet code
    const getBonusIconPath = () => {
        switch (bonusCode) {
            case 'sponsor-bonus':
                return `/images/bonus_icon/sponsor${imgFilterColor}.png`;
            case 'roi-bonus':
                return `/images/bonus_icon/roi${imgFilterColor}.png`;
            case 'leadership-bonus':
                return `/images/bonus_icon/leadership${imgFilterColor}.png`;
            case 'matching-bonus':
            case 'matching-bonus-2':
                return `/images/bonus_icon/matching${imgFilterColor}.png`;
            case 'extra-sponsor-bonus':
                return `/images/bonus_icon/extra-sponsor${imgFilterColor}.png`;
            case 'subsidy-bonus':
            case 'extra-subsidy-bonus':
                return `/images/bonus_icon/subsidy${imgFilterColor}.png`;
            case 'pairing-bonus':
                return `/images/bonus_icon/pairing${imgFilterColor}.png`;
            default:
                return null;
        }
    };

    return (
        <Grid item xs={12} sm={12} md={12}>
            <Box>
                <div className={`box-out p-tb-20 flex-col-c-m pointer ${backgroundColorCode}`} style={{ maxHeight: 130, borderRadius: '60px 15px 60px 15px', width: 250}}>
                    <div style={{ maxWidth: 120 }} >
                        <img src={getBonusIconPath()} alt='wallet' style={{ height: 60, aspectRatio: 1 / 1, filter: imgFilterColor }} />
                    </div>
                    <div className={`clwhite p-lr-10 ${textColor}`} style={{ maxWidth: '90%', margin:'0 auto', marginTop:-5 }}>
                        <p className='txt-center'><b>$ {currencyFormat(bonusAmount ?? 0, walletDecimer ?? 2)}</b></p>
                        {/* <p className='txt-center'><b>$ {Number(bonusAmount).toFixed(parseInt(walletDecimer))}</b></p> */}
                        <p className='txt-upper fs-13 txt-truncate' >{displayName}</p>
                    </div>
                </div>
            </Box>
           
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
}));

// DEFINE DEFAULT PROPS TYPE
BonusCard.propTypes = {
    bonusCode: PropTypes.string,
    bonusAmount: PropTypes.number,
    walletDecimer: PropTypes.number,
    displayName: PropTypes.string,
    backgroundColorCode:PropTypes.string
};

// DEFINE DEFAULT PROPS VALUE
BonusCard.defaultProps = {
    bonusCode: 'sponsor-bonus',
    bonusAmount: 0,
    walletDecimer :2,
    displayName: 'sponsor-bonus',
    backgroundColorCode:'#c1c1c1'
};

export default BonusCard;