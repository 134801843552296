import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation , useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from "react-dates";
import _, { filter, isNaN } from 'lodash';
import moment from 'moment';

import { Grid, Typography, Button, Dialog, DialogContent, Hidden, ThemeProvider, Stack, Pagination, createTheme, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Close, Tune } from '@mui/icons-material';

import { currencyFormat , useQuery } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';

import AllButton from '@components/AllButton';
import VscSquareButton from '@components/VscSquareButton';
import DateDisplay from '@components/DateDisplay';
import MerchantTransactionComponent from '@components/MerchantTrade/MerchantTransactionComponent';

const MerchantTradeTransaction = () => {
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    let navigate = useNavigate();
    const location = useLocation();
    let type = useQuery().get('type');
    
    const { id } = useSelector((state) => state.user);
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { t, i18n } = useTranslation();
    
    const [buyTradeTransactionList, setBuyTradeTransactionList] = useState({});
    const [allBuyTradeList, setAllBuyTradeList] = useState({});
    const [totalReceiveUnit, setTotalReceiveUnit] = useState(0);
    const [status, setStatus] = useState({});
    const [statusFilter, setStatusFilter] = useState('all');
    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [startDate, setStartDate] = useState(null); 
    const [endDate, setEndDate] = useState(null);
    const [searchDate, setSearchDate] = useState(false);
    const [merchant, setMerchant] = useState({});
    const [merchantTradeTransaction, setMerchantTradeTransaction] = useState({});

    let totalPurchased = 0;
    let purchasedValue = 0;
    let receivedUnit = 0;
    let count = 0;
    
    const filters = [
        {title: t('transaction.filterOptions.all'), value: 'all'},
    ];

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, page, statusFilter, endDate]);

    const callApi = () => {
        if ( !(type === 'unit') && !(type === 'lock_value') && !(type === 'cap_limit') ) {
            navigate('/');
        }

        setLoading(true);
        let apiData = {
            per_page: 10,
            page: page,
            status: statusFilter,
            start_date : startDate !== null ? (startDate).format('YYYY-MM-DD') : null,
            end_date: endDate !== null ? (endDate).format('YYYY-MM-DD') : null,
            type: type ?? null,
        }

        getUrl(`/member-get-merchant-trade-transaction`, apiData).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                if (response.status) {
                    let { current_page, last_page } = response.listing;
                    setTotal(last_page);
                    setCurrPage(current_page);
                    setMerchantTradeTransaction(response.listing.data)
                    setMerchant(response.merchant);
                }
                else {
                    addAlert(JSON.stringify(response.message));
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });
    }
    
    if (_.size(status) > 0) {
        _.map(status, (list, key) => (
            filters.push({ title: list?.[i18n.language], value: key })
        ))
    }

    if (selectedTransaction) {
        let factor = Math.pow(10, 2);
        _.map(selectedTransaction, detail => {
            purchasedValue += parseFloat(detail?.purchased_price) * (detail?.active_unit + detail?.passive_unit);
            receivedUnit += detail?.active_unit + detail?.passive_unit;
        });
        purchasedValue = Math.ceil(purchasedValue * factor) / factor;
    }

    if (allBuyTradeList) {
        _.map(allBuyTradeList, data => {
            if (data?.[0]?.status === 20) {
                totalPurchased += parseFloat(data?.[0]?.plan_history?.price)
            }
        })
    }

    const handleClickOpen = (value) => {
        setOpenDialog(true);
        setSelectedTransaction(value);
    };

    const handleClickClose = () => {
        setOpenDialog(false);
    };

    const onHandleFilter = (value) => {
        setStatusFilter(value);
        setAnchorEl(null);
    }

    const onChangePage = (page) => {
        setPage(page);
    }

    const merchantBalance = () => {
        let balance  = 0;
        let typeName = '';
        if (type === 'unit') {
            balance = merchant?.merchant_profiles?.mp_merchant ?? 0;
            typeName = t('title.mp_merchant');
        } 
        else if (type === 'lock_value') {
            balance = merchant?.merchant_profiles?.lock_value ?? 0;
            typeName = t('title.lock_value');
        }  
        else if (type === 'cap_limit') {
            balance = merchant?.merchant_profiles?.cap_limit ?? 0;
            typeName = t('title.cap_limit');
        } 

        return (
            <div className={`${styles.redeemBonusToken} gradient-gold-border`} style={{ width: mobileView ? '100%' : '80%' }}>
                <div className={`${styles.redeemCurrentPrice} gradient-gold-border`} style={{ width: mobileView ? '85%' : '70%' }}>
                    <Typography variant='body1' className='txt-center text-gold-short'><b>{typeName}</b></Typography>
                    {/* <Typography variant='body1' className='txt-center text-gold-short'><b>{t('trade.buyTradeSummary')}</b></Typography> */}
                    {/* <Typography variant='body2' className='txt-center'><i>{t('trade.avgRate')}: {parseFloat((totalPurchased / totalReceiveUnit) || 0).toFixed(3)}</i></Typography> */}
                </div>
                
                <Grid container className='w-full flex-m'>
                    {/* Total Purchase */}
                    <Grid item xs={12}>
                        <Typography variant='h6' className='txt-center text-gold-short'><b>${currencyFormat(parseFloat(balance), 2)}</b></Typography>
                        <Typography variant='h6' className='txt-center' style={{ color: '#ADADAD' }}><b>{t('general.balance')}</b></Typography>
                    </Grid>
                    
                    {/* Total Unit Received */}
                    {/* <Grid item xs={6}>
                        <Typography variant='body1' className='txt-center text-gold-short'><b>{totalReceiveUnit} {t('trade.units')}</b></Typography>
                        <Typography variant='body2' className='txt-center' style={{ color: '#ADADAD' }}><b>{t('trade.totalUnitReceived')}</b></Typography>
                    </Grid> */}
                </Grid>
            </div>
        )
    }

    const listingTitle = () => {
        let typeName = '';
        if (type === 'unit') {
            typeName = t('title.mp_merchant');
        } 
        else if (type === 'lock_value') {
            typeName = t('title.lock_value');
        }  
        else if (type === 'cap_limit') {
            typeName = t('title.cap_limit');
        } 

        return (
            <Typography variant='h6' className='text-gold-short' style={{width:'fit-content'}}><b>{typeName}</b></Typography>
        )
    }

    return (
        <div className='dashboard-container' style={{ paddingBottom: 20 }}>
            <div className='flex-col-c-m w-full p-b-10'>
                { merchantBalance() }
                
                {/* <div className='txt-left p-t-5' style={{ width: mobileView ? '100%' : '80%' }}>
                    <p className='txt-content p-t-5'>* {t('trade.notIncluded')}</p>
                </div> */}

                <div className={styles.shadowDisplay} style={{ width: mobileView ? '85%' : '65%' }} />

                <Grid container style={{ display: 'flex', alignItems: mobileView ? 'flex-end' : 'center' }}>
                    <Hidden mdUp>
                        <Grid item xs={12} sm={7} className='p-b-10'>
                            { listingTitle() }
                            {/* <Typography variant='body2' className='text-gold-short' style={{ lineHeight: 1.5 }}><b>{t('trade.buyTradeHistory')}</b></Typography> */}
                            <DateDisplay startDate={startDate} endDate={endDate} />
                        </Grid>
                        <Grid item xs={12} sm={5} className='txt-right' style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {/* Date Filter */}
                            <div style={{ paddingRight: 10 }}>
                                <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                            </div>
                            <AllButton filters={filters} handleChange={onHandleFilter} />
                        </Grid>
                    </Hidden>
                    <Hidden mdDown>
                        <Grid item xs={4} md={5}>
                            { listingTitle() }
                        </Grid>
                        <Grid item xs={8} md={7} className='txt-right' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <DateDisplay startDate={startDate} endDate={endDate} />
                            <div style={{ paddingRight: 10 }}>
                                <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                            </div>
                            <AllButton filters={filters} handleChange={onHandleFilter} />
                        </Grid>
                    </Hidden>
                </Grid>

                {/* Merchant Data */}
                <div className='w-full gradient-gold-border m-t-20 p-all-20'>
                    {
                        _.size(merchantTradeTransaction) > 0 ?
                            _.map(merchantTradeTransaction, (data, idx) => {
                                let units = 0;
                                // _.map(data, detail => ( units += parseInt(detail?.active_unit + detail?.passive_unit)));
                                // count += 1;

                                return (
                                    <div key={count} onClick={() => {if (data?.[0]?.status === 20) { handleClickOpen(data); }}}>
                                        <MerchantTransactionComponent data={data} mobileView={mobileView} units={units} displayOnly={true} type={type}/>
                                        { count < _.size(buyTradeTransactionList) && <div className={styles.dividerDesign} />}
                                    </div>
                                )
                            })
                            : <Grid container className='flex-l-m p-tb-10'>
                                <Grid item xs={12}>
                                    <Typography variant='body1' className='txt-center'>{t('general.noData')}</Typography>
                                </Grid>
                            </Grid>
                    }

                    {
                        !mobileView && _.size(buyTradeTransactionList) > 0 &&
                        <ThemeProvider theme={paginationStyle} >
                            <Stack spacing={2} style={{ padding: '10px 10%' }}>
                                {loading ? null : <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />}
                            </Stack>
                        </ThemeProvider>
                    }
                </div>

                <Dialog open={openDialog} onClose={handleClickClose} className='w-full' maxWidth='sm'>
                    <DialogContent style={{ padding: '10px 30px 40px' }}>
                        <div className='flex-r-m'>
                            <Button onClick={handleClickClose} style={{ minWidth: 26, aspectRatio: 1/1, padding: 5, borderRadius: 100,  }}>
                                <Close/>
                            </Button>
                        </div>

                        <div className='flex-col-c-m p-b-10'>
                            <Typography variant='body1' className='txt-upper txt-center text-gold-short'><b>{t('trade.purchaseDetail')}</b></Typography>
                            <img src={'/images/logos/logo-white.png'} className='w-full m-all-10' alt="merchant_logo" loading='lazy' style={{ width: 100, height: 100, aspectRatio: 1 / 1 }} />
                        </div>

                        <div className='flex-col-c-m'>
                            <Typography variant='body2' className='txt-upper txt-white'><i>{t('trade.purchaseValue')}</i></Typography>
                            <Typography variant='h5' className='txt-upper text-gold-short'>
                                <b>${currencyFormat(purchasedValue, 2)}</b>
                            </Typography>
                        </div>

                        <div className={`${styles.dialogDetailBoxDesign} gradient-gold-border`} style={{ padding: `40px ${mobileView ? '20px' : '40px'} 20px` }}>
                            <div className={`${styles.dialogDetailBoxTitleDesign} gradient-gold-border`}>
                                <Typography variant='body1' className='txt-center text-gold-short'><b>{t('trade.purchaseDetail')}</b></Typography>
                            </div>

                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography variant='body2' className='txt-left'><b>{t('spend.value')}</b></Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant='body2' className='txt-center'><b>{t('general.rate')}</b></Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant='body2' className='txt-right'><b><i>{t('trade.unit')}</i></b></Typography>
                                </Grid>

                                <Grid item xs={12} className='flex-c'>
                                    <div className='divider-list-black' />
                                </Grid>

                                {
                                    _.map(selectedTransaction, (list, idx) => {
                                        let factor = Math.pow(10, 2);
                                        let value = Math.ceil((parseFloat(list?.purchased_price) * (list?.active_unit + list?.passive_unit)) * factor) / factor;
                                        return (
                                            <Grid item key={idx} xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body2' className='txt-left'>${currencyFormat(value, 2)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body2' className='txt-center'>{list?.purchased_price}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant='body2' className='txt-right'><i>{list?.active_unit + list?.passive_unit}</i></Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }

                                <Grid item xs={8} className='p-t-10'>
                                    <Typography variant='body2' className='text-gold txt-left' noWrap><b>{t('trade.totalUnitReceived')}</b></Typography>
                                </Grid>
                                <Grid item xs={4} className='p-t-10 flex-r-m'>
                                    <Typography variant='body2' className='text-gold-short txt-right' style={{width:'fit-content'}}><b>{receivedUnit}</b></Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                </Dialog>

                {
                    mobileView && _.size(buyTradeTransactionList) > 0 &&
                    <ThemeProvider theme={paginationStyle} >
                        <Stack spacing={2} style={{ padding: '10px 0px' }}>
                            { loading ? null : <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} /> }
                        </Stack>
                    </ThemeProvider>
                }
            </div>
        </div>
    );
}

const paginationStyle = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                ul: {
                    justifyContent: 'space-around',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#f0f0f0',
                },
            },
        }
    }
});

const useStyles = makeStyles((theme) => ({
    redeemBonusToken: {
        position: 'relative',
        padding: '40px 30px 20px',
        // background: theme.palette.secondary.main,
        borderRadius: 10,
        boxShadow: '4px 4px 4px 0px #00000040 inset, 4px 4px 4px 0px #FFFFFF0F',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20,
    },
    redeemCurrentPrice: {
        position: 'absolute',
        top: -20,
        padding: 7,
        // background: theme.palette.primary.main,
        borderRadius: 15,
    },
    shadowDisplay: {
        height: 6, 
        borderRadius: '50%', 
        margin: '30px 0px', 
        background: 'radial-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.01))', 
        boxShadow: '0px 0px 10px 4px #00000078',
    },
    dateFilterButton: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        aspectRatio: 1/1, 
        minWidth: 40,
        maxHeight: 40, 
        borderRadius: 10, 
        background: theme.palette.primary.main,
    },
    dialogDetailBoxDesign: {
        position: 'relative',
        // boxShadow: '3px 3px 4px 0px #FFFFFF, 3px 3px 2px 0px #0000001A inset',
        borderRadius: 20,
        marginTop: 40,
        display: 'flex',
        justifyContent: 'center'
    },
    dialogDetailBoxTitleDesign: {
        width: '80%',
        height: 41,
        // background: '#F0F0F0',
        position: 'absolute',
        top: -20,
        // boxShadow: '-3px -3px 4px 0px #FFFFFF, 3px 3px 5px 0px #0000001A',
        borderRadius: 20,
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dialogDetailDividerDesign: {
        // boxShadow: '0px 1px 2px 0px #0000001F inset, 0px 2px 3px 0px #FFFFFF',
        width: '100%',
        height: 4,
        margin: '10px 0px',
    },
    dividerDesign: {
        height: 4,
        width: '100%',
        background: theme.palette.secondary.main,
        marginTop: 5,
        marginBottom: 5,
        boxShadow: '0px 1px 2px 0px #000000DB inset, 0px 2px 3px 0px #FFFFFF12',
    },
}));

export default MerchantTradeTransaction;