import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { IconButton } from '@mui/material';
import { BiChevronLeft } from "react-icons/bi";

import { changeLanguage } from '@actions';

export default function AuthTitleBar() {
    const { t, i18n } = useTranslation();
    let location = useLocation();
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);
    let navigate = useNavigate();
    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    return (
        <div className='flex-sb-m pos-absolute w-full p-lr-50' style={{ top: 20, left: 0, zIndex: 10 }}>
            {
                location.pathname == '/login'
                    ?
                    <img src="images/logos/logo-white.png" style={{ height: 60, margin: 0 }} alt="logo" loading='lazy' />
                    :
                    <IconButton onClick={() => navigate('/login')} style={{ fontSize: 16 }}>
                        <BiChevronLeft className="img-style" style={{ color: '#fff' }} />
                        <p className='fs-16 txt-upper' style={{ fontWeight: 500, color: '#fff' }}>{t('button.backToLogin')}</p>
                    </IconButton>
            }
            {
                language === 'en' ?
                    <IconButton style={{ margin: 0 }} onClick={() => changeSystemLanguage('cn')}>
                        <p className='fs-16 txt-upper' style={{ fontWeight: 500, color: '#fff' }}>中文</p>
                    </IconButton>
                    :
                    <IconButton style={{ margin: 0 }} onClick={() => changeSystemLanguage('en')}>
                        <p className='fs-16 txt-upper' style={{ fontWeight: 500, color: '#fff' }}>EN</p>
                    </IconButton>
            }
        </div>
    );
}