import _ from 'lodash';
import React, { useState } from 'react';

import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const AllButton = props => {
    const { filters, handleChange } = props;
    const styles = useStyles();
    const [sortBy, setSortBy] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onHandleFilter = (value, index) => {
        setSortBy(index);
        setAnchorEl(null);
        handleChange(value);
    }

    return (
        <div>
            <Button variant='contained' className={styles.sortByButton} onClick={handleClick}>
                <p>{filters?.[sortBy]?.title}</p>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    _.map(filters, (filter, idx) => (
                        <MenuItem key={idx} onClick={() => onHandleFilter(filter.value, idx)}>{filter?.title}</MenuItem>
                    ))
                }
            </Menu>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    sortByButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 65,
        height: 40,
        borderRadius: 10,
    },
}));

export default AllButton;