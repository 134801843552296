import React, { useState , useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PropTypes } from 'prop-types';

import { Button, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';

const TwoFA = ( props ) => {
    const { 
        inputErrors , 
        open , 
        onClose , 
        value , 
        handleChange , 
        onClick ,
        name,
        placeholder
    } = props;

    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Dialog open={open} onClose={onClose} style={{width:600, maxWidth:'90%', margin:'0 auto'}}>
            <DialogTitle id="form-dialog-title"  className='text-gold-short'>{t('profile.enterTwoFactorAuth')}</DialogTitle>
            <DialogContent style={{width: '100%'}}>
                <TextField
                    helperText={inputErrors && inputErrors ? inputErrors : ''}
                    error={inputErrors && inputErrors ? true : false}
                    placeholder={placeholder ? placeholder : t('user.authenticationCode')}
                    variant="standard"
                    type="number"
                    fullWidth
                    autoFocus
                    value={value || ''}
                    name={name ? name : "authenticationCode"}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} >
                    {t('button.cancel')}
                </Button>
                <Button onClick={onClick}>
                    {t('button.submit')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        minWidth: 300,
    },
    // button: {
    //     backgroundColor: theme.palette.primary.main,
    //     color: theme.palette.common.white,
    //     '&:hover': {
    //         backgroundColor: theme.palette.primary.main,
    //     },
    // },
}));

TwoFA.propTypes = {
    Name: PropTypes.string,
    walletLogo: PropTypes.string,
    walletValue: PropTypes.string,
    walletDecimal: PropTypes.number,
    background: PropTypes.string,
    labelColor: PropTypes.string,
};

TwoFA.defaultProps = {
    walletName: '',
    walletLogo: 'images/logos/logo.png',
    walletValue: '0',
    walletDecimal: 0,
    background: 'linear-gradient(109deg, #82512D 3.41%, #D1913C 98.09%)',
    labelColor: '#ffffff',
};

export default TwoFA;