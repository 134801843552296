import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box, Button, Drawer, TextField, Typography, IconButton, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormHelperText , Dialog , DialogContent } from '@mui/material';

import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { currencyFormat } from '@utils/Tools';

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import WalletItem from '@components/WalletItem';
import TwoFA from '@components/TwoFA';
import { FaCheck } from "react-icons/fa";

const Transfer = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    const location = useLocation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [completed, setCompleted] = useState(false);
    const [state, setState] = useState({
        from_wallet_id: '',
        to_wallet_id: 0,
        wallet_id: '',
        username: '',
        amount: '',
        remark: '',
        security_password: '',
        authentication_code: '',
        showPassword: false,
    });
    const { username } = useSelector(state => state.user);
    const [wallet, setWallet] = useState({});
    const [walletBalance, setWalletBalance] = useState('');
    const [walletListing, setWalletListing] = useState({});
    const [searchParams] = useSearchParams();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [inputErrors, setInputErrors] = useState();
    // const [transaction, setTransaction] = useState([]);
    const [twoFASetting, setTwoFASetting] = useState(false);
    const formType = location.pathname === `/convert` ? "convert" : "transfer"; 

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({
        from_user: {
            username: 'test',
        },
        to_user: {
            username: 'test',
        },
        date: '',
        amount: 0,
        remark: '',
    });
    
    useEffect(() => {
        setLoading(true);
        callApi();
        
    }, [searchParams]);
    
    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }
    
    const callApi = () => {
        if (typeof searchParams.get("w") !== 'string') {
            setLoading(false);
            navigate(`/`);
            return;
        }
        
        try {
            let wallet_id = parseInt(atob(searchParams.get("w")));
        
            if (isNaN(wallet_id)) {
                setLoading(false);
                navigate(`/`);
                return;
            }
        
            setState({
                ...state,
                wallet_id: wallet_id,
            });
        
            let apiData = {
                wallet_id: wallet_id,
            };
        
            getUrl( 'get-setting-value' ).then(response => {
                setLoading(false)
                if(response.status){
                    setTwoFASetting(response.twofa)
                }
            }).catch(error => {
                setLoading(false)
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            })
            ;

            getUrl(`/member-transfer-balance`, apiData)
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    setWalletBalance(response.data.wallet_balance);
                    setWallet(response.data.wallet);
                    setWalletListing(response.wallet_listing);
                } else {
                    navigate(`/`);
                }
            })
            .catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
                navigate(`/`);
            });
        } catch (error) {
            setLoading(false);
            navigate(`/`);
        }
    };      

    const closeDrawer = () => {
        setDrawerOpen(false);
        // Reset the state to clear the data
        setState({
            ...state,
            username: '',
            amount: '',
            remark: '',
            security_password: '',
            authentication_code: '',
            showPassword: false,
        });
        // Clear input errors
        setInputErrors(null);
    };   

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const insertSecurityPwd = () => {
        if ((state.username || state.to_wallet_id) && state.amount && state.remark && state.security_password) {
            setDrawerOpen(true);
        } else {
            if(formType === 'convert'){
                addAlert('', t('transfer.insertConvertAmountRemark'), 'error', '');
            }else{
                addAlert('', t('transfer.insertAmountRemark'), 'error', '');
            }

            // Close the drawer
            setDrawerOpen(false);
        }
    };
    
    const confirmTransfer = () => {
        setLoading(true);
        setInputErrors();
        // let params = {}
        // {
        //     formType === 'convert'?
        //     params = {
        //         username: username,
        //         from_wallet_id: state?.wallet_id,
        //         to_wallet_id: state?.to_wallet_id,
        //         amount: state?.amount,
        //         remark: state?.remark,
        //         security_password: state?.security_password,
        //         authentication_code: state?.authentication_code,
        //     }
        //     :
        //     params = {
        //         username: state?.username,
        //         from_wallet_id: state?.wallet_id,
        //         to_wallet_id: state?.wallet_id,
        //         amount: state?.amount,
        //         remark: state?.remark,
        //         security_password: state?.security_password,
        //         authentication_code: state?.authentication_code,
        //     }
        // }
        
        let params = {
            username: state?.username,
            from_wallet_id: state?.wallet_id,
            to_wallet_id: state?.to_wallet_id,
            amount: state?.amount,
            remark: state?.remark,
            security_password: state?.security_password,
            authentication_code: state?.authentication_code,
            formType : formType,
        };
        
        if (formType === 'convert') {
            params = {
                ...params,
                username: username,
                to_wallet_id: state?.to_wallet_id,
            };
        } else {
            params = {
                ...params,
                from_wallet_id: state?.wallet_id,
                to_wallet_id: state?.wallet_id,
            };
        }

        
        postUrl(`/wallets/transfer`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setCompleted(true);
                // setTransaction(response.data);
                callApi();
                closeDrawer();
                setDialogData({
                    from_user: response.from_user,
                    to_user: response.to_user,
                    date: response.date,
                    amount: response.amount,
                    remark: response.remark,
                });
                setDialogOpen(true)
                addAlert('', t('success.transferSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
    
                // Check for specific errors and close the drawer accordingly
                if (
                    (!state.username || !state.amount || !state.remark || !state.security_password) ||
                    (response.errors && (response.errors.username || response.errors.amount || response.errors.remark || response.errors.security_password))
                ) {
                    setDrawerOpen(false);
                }                   
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    };    

    const dialogDisplay = () => {
        return (
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogContent style={{ minWidth: 350 , textAlign: 'center' }}>
                    <div className='w-full flex-r-m'>
                        <Button onClick={() => setDialogOpen(false)} style={{ minWidth: 26, aspectRatio: 1 / 1, padding: 5, borderRadius: 100, }}>
                            <CloseIcon />
                        </Button>
                    </div>

                    <FaCheck className='txt-theme fs-40'/>
                    <p className='text-gold-short fs-30'>{formType === 'convert' ? t('title.convert') : t('title.transfer')}</p>
                    <p className='txt-subtitle'>{t('receipt.subtitle')}</p>
                   
                    <div style={{ display:'flex', justifyContent: 'space-between', margin: '40px 0 20px '}}>
                        <Typography variant='body2'>{t('transfer.transferFrom')}</Typography>

                        <Typography variant='body2'>{dialogData?.from_user?.username}</Typography>
                    </div>

                    <div style={{ display:'flex', justifyContent: 'space-between', margin: '0 0 20px '}}>
                        <Typography variant='body2'>{t('transfer.transferTo')}</Typography>
                        <Typography variant='body2'>{dialogData?.to_user?.username}</Typography>
                    
                    </div>

                    <div className='divider-list-black'></div>

                    <div style={{ display:'flex', justifyContent: 'space-between', margin: '0 0 20px '}}>
                        <Typography variant='body2'>{t('receipt.amount')}</Typography>
                        <Typography variant='body2'>{dialogData?.amount}</Typography>
                    
                    </div>

                    <div style={{ display:'flex', justifyContent: 'space-between', margin: '0 0 20px '}}>
                        <Typography variant='body2'>{t('receipt.remark')}</Typography>
                        <Typography variant='body2'>{dialogData?.remark}</Typography>
                    
                    </div>

                    <div style={{ display:'flex', justifyContent: 'space-between', margin: '0 0 20px '}}>
                        <Typography variant='body2'>{t('receipt.dateTime')}</Typography>
                        <Typography variant='body2'>{dialogData?.date}</Typography>
                    
                    </div>

                </DialogContent>
            </Dialog>
        );
    }
    return (
        <>
            <div className='dashboard-container'>
                <div className='dashboard-w'>
                    <div className='pos-relative' style={{zIndex:2}}>
                        <Box>
                            <Typography variant='h6' className='txt-center text-gold-short'>
                                {
                                    formType === 'convert' ?
                                        <b>
                                            {t('title.convert')} {wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language] : wallet?.name?.en}
                                        </b>
                                        :
                                        <b>
                                            {t('title.transfer')} {wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language] : wallet?.name?.en}
                                        </b>
                                }
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                <Box className="m-tb-30" style={{ width: '320px' }}>
                                    <WalletItem
                                        walletValue={walletBalance}
                                        walletDecimal={wallet?.decimal}
                                        walletCode={wallet?.code}
                                        walletName={wallet?.name ? (wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language] : wallet?.name?.cn) : ''}
                                    />
                                </Box>
                            </div>
                            <Box style={{ margin: "20px 0px" }}>
                                <TextField
                                    placeholder={t('transfer.amount')}
                                    variant='standard'
                                    fullWidth
                                    autoComplete='amount'
                                    name='amount'
                                    value={state?.amount}
                                    onChange={({ target }) => setState({ ...state, amount: target.value })}
                                />
                                {
                                    inputErrors && inputErrors.amount
                                        ?
                                        <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.amount}</Typography>
                                        :
                                        null
                                }
                            </Box>
                            {
                                formType === 'convert' ?
                                    <Box sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }}>
                                        <FormControl fullWidth error={inputErrors && inputErrors.to_wallet_id ? true : false}>
                                            <Select
                                                value={state?.to_wallet_id || 0}
                                                name="to_wallet_id"
                                                onChange={handleChange}
                                                displayEmpty
                                            >
                                                <MenuItem key={0} value={0} disabled>--- {`${t('general.pleaseSelect')} ${t(`transfer.walletId`)} `} --- </MenuItem>
                                                {
                                                    _.map(walletListing, (data, key) => {
                                                        return <MenuItem key={key} value={data.id}>{i18n.language === 'cn' ? data?.name[i18n.language] : data?.name?.en}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                            {
                                                inputErrors && inputErrors?.to_wallet_id &&
                                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.to_wallet_id}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Box>
                                    :
                                    <Box style={{ margin: "20px 0px" }}>
                                        <TextField
                                            placeholder={t('transfer.transferTo')}
                                            variant='standard'
                                            fullWidth
                                            autoComplete='username'
                                            name='username'
                                            value={state?.username}
                                            onChange={({ target }) => setState({ ...state, username: target.value.toLowerCase() })}
                                        />
                                        {
                                            inputErrors && inputErrors.username
                                                ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.username}</Typography>
                                                : inputErrors?.allow_self
                                                    ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.allow_self}</Typography>
                                                    : inputErrors?.allow_others
                                                        ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.allow_others}</Typography>
                                                        : null
                                        }
                                    </Box>
                            }
                           
                            <Box style={{ margin: "20px 0px" }}>
                                <TextField
                                    placeholder={t('transfer.remarkNote')}
                                    variant='standard'
                                    fullWidth
                                    autoComplete='remark'
                                    name='remark'
                                    value={state?.remark}
                                    onChange={({ target }) => setState({ ...state, remark: target.value })}
                                />
                                {
                                    inputErrors && inputErrors.remark
                                        ?
                                        <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.remark}</Typography>
                                        :
                                        null
                                }
                            </Box>
                            <Box style={{ margin: "20px 0px" }}>
                                <TextField
                                    placeholder={t('transfer.securityPassword')}
                                    variant='standard'
                                    fullWidth
                                    type={state.showPassword ? 'text' : 'password'}
                                    autoComplete='securityPassword'
                                    name='securityPassword'
                                    value={state?.security_password}
                                    onChange={({ target }) => setState({ ...state, security_password: target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {state.showPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {
                                    inputErrors && inputErrors.security_password
                                        ?
                                        <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.security_password}</Typography>
                                        :
                                        null
                                }
                            </Box>

                            <Box className="flex-c-m">
                                <Button variant="contained" className='big-button' fullWidth onClick={twoFASetting ? insertSecurityPwd : confirmTransfer}>{formType === 'convert' ? t('transfer.confirmConvert'):t('transfer.confirmTransfer')}</Button>
                            </Box>
                        </Box>

                        <TwoFA
                            inputErrors={inputErrors?.authentication_code}
                            open={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                            value={state?.authentication_code || ''}
                            handleChange={({ target }) => {
                                const numericInput = target.value.replace(/\D/g, '');
                                setState({ ...state, authentication_code: numericInput });
                            }}
                            onClick={confirmTransfer}
                        />

                        {/* <Drawer
                anchor={"bottom"}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onOpen={() => setDrawerOpen(true)}
                disableScrollLock={true}
                classes={{ paper: styles.paperAnchorBottom }}
            >
                <Box className="m-lr-30 m-t-30">
                    <div className='dis-flex flex-m flex-sb-m p-b-20'>
                        <Typography className='w-full' style={{ color: 'rgba(82, 82, 82, 0.80)' }}><b>{t('transfer.enterTwoFApassword')}</b></Typography>
                    </div>

                    <div className='p-b-50'>
                        <TextField
                            error={inputErrors && inputErrors.authentication_code ? true : false}
                            helperText={inputErrors?.authentication_code || ''}
                            placeholder={t('transfer.twoFactorAuth')}
                            variant="standard"
                            fullWidth
                            value={state?.authentication_code}
                            onChange={({ target }) => {
                                const numericInput = target.value.replace(/\D/g, '');
                                setState({ ...state, authentication_code: numericInput });
                            }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                inputMode: 'numeric', // Set input mode to numeric
                                pattern: '[0-9]*',     // Set a pattern to enforce numeric input
                            }}
                        />
                    </div>
                    
                    <div className='flex-c-m'>
                        <Button variant="contained" fullWidth className='big-button' style={{ marginBottom: 30 }} onClick={confirmTransfer}>
                            {t('transfer.confirmTransfer')}
                        </Button>
                    </div>
                </Box>
            </Drawer> */}
                    </div>
                </div>
            </div>
            { dialogDisplay() }
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    colorBrown: {
        color: '#82512D'
    },
    input: {
        borderRadius: '10px',
        background: '#EEE',
        boxShadow: '-3px -3px 4px 0px #FFF inset, 2px 2px 3px 0px rgba(0, 0, 0, 0.10) inset',
    },
    button: {
        borderRadius: '30px 0px',
        background: '#82512D',
        boxShadow: '3px 3px 6px 0px rgba(255, 255, 255, 0.43) inset, 2px 2px 4px 0px rgba(181, 126, 87, 0.65)',
        '&:hover': {
            background: '#946439',
        },
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        // maxWidth: 444,
        overflow: 'hidden',
        margin:'0 auto',
        borderRadius:'30px 30px 0px 0px'
    },
    colorGrey: {
        color: "#565656"
    },
    rectangle: {
        background: '#F1F1F1',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px #FFF',
        height: '4px',
        margin: '5px 0px 15px 0px'
    },
}));

export default Transfer;