import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Button, IconButton, Link, Hidden, Container, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { changeLanguage } from '@actions';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

export default function Maintenance() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const { addAlert, setLoading } = useNotificationLoading();
    const [serverTime, setServerTime] = useState({
        time: '',
        date: '',
    });
    const [maintenance, setMaintenance] = useState({
        force: '',
        start: '',
        end: '',
    });
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.general);
    const isMountedRef = useRef(null);
    let navigate = useNavigate();

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`/maintenance`).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                if (!response.maintenance_mode) {
                    navigate('/');
                }
                setMaintenance({
                    force: response.force_maintenance,
                    start: response.maintenance_start,
                    end: response.maintenance_end,
                });
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime({
                time: moment().format("HH:mm:ss"),
                date: moment().format("ddd, MMM DD"),
            })
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    return (
        <div>
            <Container className='p-t-20'>
                <div className='flex-sb-m'>
                    <div>
                        <img src='images/logos/logo.png' alt='logo' style={{ width: 50 }} loading='lazy' />
                    </div>
                    {
                        language === 'en' ?
                            <IconButton onClick={() => changeSystemLanguage('cn')}>
                                <p className='fs-16 txt-upper' style={{ fontWeight: 500 }}>中文</p>
                            </IconButton>
                            :
                            <IconButton onClick={() => changeSystemLanguage('en')}>
                                <p className='fs-16 txt-upper' style={{ fontWeight: 500 }}>EN</p>
                            </IconButton>
                    }
                </div>
            </Container>
        <div className='flex-c-m' style={{ minHeight: '100vh', marginTop:-80}}>
     
            <div className='flex-c-m maintenance-container'>
                <Hidden mdUp>
                    <div className='p-tb-20 txt-center p-lr-30 clwhite' style={{fontWeight:500}}>
                        <p className='txt-upper fs-14'>{t('dashboard.serverTime')}</p>
                        <div>
                            <p className='fs-25 lh-10' style={{ fontWeight: 700, color:'#ADADAD' }}>{serverTime.time}</p>
                            <p>{serverTime.date}</p>
                        </div>
                    </div>
                </Hidden>
                <div className='p-lr-20' style={{width:500, maxWidth:'40vw'}}>
                    <img src='images/maintenance/maintenance2.png' alt='maintenance' className='w-full' loading='lazy' />
                </div>
                <div style={{maxWidth: 444, margin:'0 auto'}}>
                <Hidden mdDown>
                    <div className='clwhite' style={{fontWeight:500}}>
                        <p style={{color:'#ADADAD'}}>{t('dashboard.serverTime')}</p>
                        <p className='txt-upper'>{serverTime.date}<span className='p-lr-20'>·</span>{serverTime.time}</p>
                    </div>
                </Hidden>
                    <div className='clwhite'>
                        <p className='fs-25 lh-10 p-tb-10' style={{ fontWeight: 700 }}>{t('maintenance.title')}</p>
                            {
                                !maintenance.force
                                    ?
                                    <Typography className='p-t-3' style={{ fontWeight: 500 }}>{t('maintenance.dailyMaintenance') + maintenance.start + ' - ' + maintenance.end}</Typography>
                                    :
                                    null
                            }
                        <Typography className='p-t-3' style={{ fontWeight: 500 }}>{t('maintenance.message')}</Typography>
                    </div>

                    <div>
                        <Link component={RouterLink} to={`/`} className='max-w-full flex-c' >
                            <Button variant="contained" className='big-button' style={{ marginTop: 20 }} fullWidth>
                                {t('button.login')}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

}));