import React, { useEffect, useState } from 'react';
import { DayPickerRangeController } from "react-dates";
import { useTranslation } from 'react-i18next';

import { Button, Dialog, Tooltip, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { MdOutlineDateRange, MdReplay } from "react-icons/md";

import 'react-dates/initialize';
import "react-dates/lib/css/_datepicker.css";

const VscSquareButton = props => {
    const { startDate, endDate, setStartDate, setEndDate, searchDate, setSearchDate } = props;
    const styles = useStyles();
    const { t } = useTranslation();
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [calanderOpen, setCalanderOpen] = useState(false);
    const [state, setState] = useState({
        startDate: startDate,
        endDate: endDate,
    });
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        if ((startDate === null && endDate === null) || (startDate !== null && endDate !== null)) {
            setFocusedInput('startDate');
            setCalanderOpen(false);
            setSearchDate(!searchDate);
        }
        // eslint-disable-next-line
    }, [endDate]);

    const clearSearch = () => {
        setStartDate(null);
        setEndDate(null);
        setState({
            startDate: null,
            endDate: null,
        });
    }

    const calanderData = () => {
        return (
            <DayPickerRangeController
                startDate={startDate}
                endDate={endDate}
                onDatesChange={({ startDate, endDate }) => {
                    let tempEndDate = endDate;
                    if (state.startDate && state.endDate) {
                        tempEndDate = null;
                    }
                    setStartDate(startDate);
                    setEndDate(tempEndDate);
                    setState({
                        startDate: startDate,
                        endDate: tempEndDate,
                    });
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput === null ? 'startDate' : focusedInput)}
                numberOfMonths={1}
            />
        )
    }

    return (
        <div style={{ display: "flex", position: 'relative' }}>
            <Tooltip title={t('button.filter')}>
                <Button
                    variant="contained"
                    className={styles.squareButton}
                    onClick={() => setCalanderOpen(!calanderOpen)}
                >
                    <MdOutlineDateRange />
                </Button>
            </Tooltip>
            <Tooltip title={t('table.clear')}>
                <Button
                    variant="contained"
                    className={styles.squareButton}
                    onClick={() => clearSearch()}
                >
                    <MdReplay />
                </Button>
            </Tooltip>
            {
                calanderOpen && !mobileView &&
                <div style={{ position: 'absolute', right: 0, top: 50 }}>
                    {calanderData()}
                </div>
            }
            {
                mobileView &&
                <Dialog
                    open={calanderOpen}
                    onClose={() => setCalanderOpen(false)}
                    disableScrollLock={true}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    }}
                >
                    {calanderData()}
                </Dialog>
            }
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    squareButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // color: theme.palette.white.main,
        borderRadius: '10px',
        minWidth: '40px',
        height: '40px',
        marginLeft: 10,
        aspectRatio: 1 / 1,
        padding: "0px 0px",
        fontSize:25
    }
}));

export default VscSquareButton;