import React, { memo, useState , useEffect } from 'react';
import _ from 'lodash';
import { removeLoginAccess } from '@utils/ApiAction';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme,makeStyles } from '@mui/styles';
import { useLocation, useNavigate, matchRoutes } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Avatar, Tooltip, Hidden, useMediaQuery, Button } from '@mui/material';
import { BiChevronLeft } from "react-icons/bi";
import { GoGlobe } from "react-icons/go";
import { AiOutlineScan } from "react-icons/ai";
import { FiLogIn , FiLogOut } from "react-icons/fi"
import { changeLanguage } from '@actions';
import { public_restricted_routes, public_routes, private_routes } from '@configs/Router';
import { reducePaths } from '@utils/Tools';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';

const HIDE_BAR = ['/reset-password', '/landing', '/verifyPage/:id/:hash', '/login', '/register', '/forgot-password','/login-redirect','/third-party-checkout/:key'];
const WITH_LOGO = ['/', '/dashboard'];
const WITH_TRANSPARENT = ['/payment/:id'];
const HIDE_SCANNER_LOGO = ['/spend'];
const SIDE_BAR_DISPLAY = ['/', '/account/recruit', '/account/referral-qr', '/account/passwordHome', '/account/2fa', '/account/crypto-address', '/account/notices', '/funds/:page', '/community/:page', '/plans', '/plan-histories', '/trade', '/help-center', '/spend' ,'redeem-code-histories'];

const TitleBar = memo((props) => {
    const { showTitle } = props;
    const theme = useTheme();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ changeColor, setChangeColor ] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const { accessToken, language } = useSelector(state => state.general);
    const open = Boolean(anchorEl);
    const { username, profile_image, max_rank, email, mobile } = useSelector(state => state.user);
    const maxRankName = max_rank ? max_rank.name[i18n.language] : null;
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    
    let location = useLocation();
    let navigate = useNavigate();
    let from = location.state?.from?.pathname;
    let current_path = location?.pathname;
    let path = location?.pathname.split('/');
    let checkPrivatePath = matchRoutes(private_routes, current_path)?.[0]?.['route'];
    let checkPublicPath = matchRoutes(public_routes, current_path)?.[0]?.['route'];
    let checkPublicRestrictedPath = matchRoutes(public_restricted_routes, current_path)?.[0]?.['route'];

    const getRouterFormal = () => {
        const routePath = matchRoutes(public_restricted_routes, current_path)?.[0]?.route?.path || matchRoutes(public_routes, current_path)?.[0]?.route?.path || matchRoutes(private_routes, current_path)?.[0]?.route?.path;
        return routePath || null;
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleScannerClick = (event) => {
        navigate('/spend')
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
        // Perform any actions when the mouse enters the div
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        // Perform any actions when the mouse leaves the div
    };

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleClose();
    };

    const getTitle = () => {
        let public_restricted_detail = _.find(public_restricted_routes ,{'path': checkPublicRestrictedPath?.path});
        let public_detail = _.find(public_routes ,{'path': checkPublicPath?.path});
        let private_detail = _.find(private_routes ,{'path': checkPrivatePath?.path});
        let current_title = null;

        if(_.size(public_restricted_detail) > 0){
            current_title = t(public_restricted_detail?.title);
        } else if(_.size(public_detail) > 0){
            current_title = t(public_detail?.title);
        } else  if(_.size(private_detail) > 0){
            current_title = t(private_detail?.title);
        }

        return current_title;
    }

    const goBack = () => {
        if (_.size(location?.state?.from) > 0) {
            let path = _.size(location?.state?.from) > 0 ? _.last(location?.state?.from) : '/';
            let pathListing= reducePaths(location);
            let urlLink = path?.pathname;
            if(path?.search !== ''){
                urlLink = urlLink + path?.search;
            }
            navigate(urlLink, { state: { from: pathListing } });
        } else {
            navigate('/');
        }
    }

    const logout = () => {
        removeLoginAccess();
        navigate('/login');
    };

    const login = () => {
        navigate('/login');
    };

    const changeBackgroundColor = () => {
        if (window?.scrollY > 0) {
            setChangeColor(true);
        } else {
            setChangeColor(false);
        }
    }
    window.addEventListener('scroll', changeBackgroundColor);
    const handleLoginClick = async () => {
        setLoading(true);
        const response = await postUrl('/direct-login');
        if (response.status) {    
            console.log(response.status);
            if ( response.data.key !== null) {
                let navigateLink = process.env.REACT_APP_9M_MALL_MEMBER_SIDE_URL + 'login-callback?Src=9MAcademy&H=' + response.data.key + '&Frm=redirect';
                setLoading(false);
                window.location.replace(navigateLink);
            } else {
                addAlert('', t('error.userNotFound'), 'error', '');
            } 
        } else {
            setLoading(false);
            setInputErrors(response.errors);
            addAlert('', response.message, 'error', '');
        }
    }

    // CHATBOT
    // useEffect(() => {
    //     const head = document.querySelector("head");
    //     const script = document.createElement("script");
    //     window.$crisp=[];window.CRISP_WEBSITE_ID="fc7faa08-1785-4ae1-b0f6-a32f59fec9e9";
    //     script.setAttribute("src", "https://client.crisp.chat/l.js");
    //     head.appendChild(script);

    //     var css = '#crisp-chatbox span.cc-imbb.cc-qfnu {  background-color: #404b7d82 !important; backdrop-filter: blur(5px); -webkit-backdrop-filter: blur(5px)!important; box-shadow: 3px 3px 3px 0 #00000042 !important }';
        
    //     var style = document.createElement('style');
    //     head.appendChild(style);

    //     style.type = 'text/css';
    //     if (style.styleSheet) {
    //         // This is required for IE8 and below.
    //         style.styleSheet.cssText = css;
    //     } else {
    //         style.appendChild(document.createTextNode(css));
    //     }

    //     return () => {
    //         head.removeChild(script);
    //     };
    // }, []);

    useEffect(() => {
        if(i18n.language !== language){
            i18n.changeLanguage(language);
        }
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     if (accessToken) {
    //         if(email === null){
    //             navigate('/account/profile');
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, [accessToken]);

    return (
        <>
            {
                // !_.includes(HIDE_BAR, location?.pathname)
                !_.includes(HIDE_BAR, getRouterFormal())
                    ?
                    <AppBar position='fixed'
                        sx={{
                            right: 'auto',
                            backgroundColor: theme.palette.darkBase.main,
                        }}
                        style={{
                            width: '-webkit-fill-available',
                        }}
                    >
                        <Toolbar className='flex-sb-m' style={{ padding: mobileView ? '20px 30px 10px' : '20px 50px 10px' }}>
                            {
                                showTitle && _.includes(SIDE_BAR_DISPLAY, getRouterFormal()) ?
                                    <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }} className='text-gold-short'>
                                        {getTitle()}
                                    </Typography>
                                    : _.includes(WITH_LOGO, getRouterFormal()) ?
                                        <div>
                                            <img src='/images/logos/logo.png' alt='logo' style={{ width: 50 }} />
                                        </div>
                                        : <IconButton onClick={goBack} style={{ fontSize: 16 }}>
                                            <BiChevronLeft style={{ color: (_.includes(WITH_TRANSPARENT, checkPrivatePath?.['path']) || _.includes(WITH_TRANSPARENT, checkPublicPath?.['path']) || _.includes(WITH_TRANSPARENT, checkPublicRestrictedPath?.['path'])) && !changeColor ? '#fff' : '#F0F0F0' }} />
                                            <Typography variant='body1'>{t('button.back')}</Typography>
                                        </IconButton>
                            }
                            {
                                // accessToken ?
                                //     <Tooltip title={t('button.logout')} arrow><IconButton onClick={logout} ><FiLogOut className="img-style" style={{ color: _.includes(WITH_TRANSPARENT, checkPath?.['path']) && !changeColor ? '#fff' : theme.palette.primary.main }} /></IconButton></Tooltip>
                                //     :
                                //     <Tooltip title={t('button.login')} arrow><IconButton onClick={login} ><FiLogIn className="img-style" style={{ color: _.includes(WITH_TRANSPARENT, checkPath?.['path']) && !changeColor ? '#fff' : theme.palette.primary.main }} /></IconButton></Tooltip>

                            }
                            {/* <Tooltip title={t('general.language')} arrow>
                                <IconButton onClick={handleClick} >
                                    <GoGlobe className="img-style" style={{ color: _.includes(WITH_TRANSPARENT, checkPath?.['path']) && !changeColor ? '#fff' : theme.palette.primary.main }} />
                                </IconButton>
                            </Tooltip> */}
                            {
                                mobileView ?
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {/* <div className='fs-16 lh-12 p-r-10' style={{ color: '#F0F0F0' }}>
                                                <p className='fs-16 txt-right' style={{ wordBreak: "break-all" }}><b>{username}</b></p>
                                                <p className='txt-right'>{maxRankName || t('user.guest')}</p>
                                            </div> */}
                                            {/* <div className={styles.outerThemeCircleDesign} style ={{marginRight:10}} onClick={handleLoginClick}>
                                                <Avatar className={styles.avatarStyle}>
                                                    <StoreMallDirectoryIcon style={{ color: theme.palette.secondary.main }} className='txt-upper' variant='h5'>{username ? username.substring(0, 1) : '-'}</StoreMallDirectoryIcon>
                                                </Avatar>
                                            </div> */}
                                            {/* <div className={styles.mstoreMobileDesign}>
                                                <img src='/images/logos/logo.png' alt='Logo' style={{ marginRight: 20 }} />
                                            </div> */}
                                            <div >
                                                <Typography variant="h6" component="div" style={{ fontWeight: 'bold', lineHeight:1.1 }} className='text-gold-short'>
                                                    <b>{username}</b>
                                                </Typography>
                                                <p className='txt-theme txt-right lh-11'>{maxRankName || t('user.guest')}</p>
                                            </div>
                                            <div
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                                onClick={handleClick}
                                                style={{marginLeft:10}}
                                            >
                                              <Button variant="contained"
                                                    style={{
                                                        width: 40,
                                                        height: 40,
                                                        borderRadius: 10,
                                                        fontWeight: 700,
                                                        minWidth:0,
                                                        minHeight:0,
                                                    }}
                                                    className='gradient-gold-btn'
                                                >
                                                <GoGlobe
                                                    className="img-style"
                                                    style={{
                                                        color: (_.includes(WITH_TRANSPARENT, checkPrivatePath?.['path']) || _.includes(WITH_TRANSPARENT, checkPublicPath?.['path']) || _.includes(WITH_TRANSPARENT, checkPublicRestrictedPath?.['path'])) && !changeColor
                                                            ? '#0F0F0F'
                                                            : '#0F0F0F'
                                                    }}
                                                />
                                                </Button>
                                            </div>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    onClick={handleClose}
                                                    slotProps={{
                                                        elevation: 0,
                                                        sx: {
                                                            overflow: 'visible',
                                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                            mt: 1.5,
                                                            '&:before': {
                                                                content: '""',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 14,
                                                                width: 10,
                                                                height: 10,
                                                                transform: 'translateY(-50%) rotate(45deg)',
                                                                zIndex: 0,
                                                            },
                                                        },
                                                    }}
                                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                >
                                                    <MenuItem onClick={() => changeSystemLanguage('en')} >{t('general.en')}</MenuItem>
                                                    <MenuItem onClick={() => changeSystemLanguage('cn')}  >{t('general.cn')}</MenuItem>
                                                </Menu>
                                            
                                           {/*  <div>
                                                {
                                                    profile_image ?
                                                        <Avatar className={styles.avatarStyle} src={profile_image?.file_name} />
                                                        :
                                                        <div className={styles.outerThemeCircleDesign}>
                                                            <Avatar className={styles.avatarStyle}>
                                                                <Typography style={{ color: theme.palette.secondary.main }} className='txt-upper' variant='h5'>{username ? username.substring(0, 1) : '-'}</Typography>
                                                            </Avatar>
                                                        </div>
                                                }
                                            </div> */}
                                            <Hidden mdDown>
                                                <div className='fs-45 lh-12 p-r-10 flex-c-m' style={{ color: '#F0F0F0', marginLeft: 10 }} onClick={handleScannerClick}>
                                                    <AiOutlineScan
                                                        style={{
                                                            color: 'gold'
                                                        }}
                                                    />
                                                </div>
                                            </Hidden>
                                        </div>
                                    </>
                                :
                                    <>
                                        <div className='flex-row' style={{ alignItems: 'center' }}>
                                            <div className='fs-16 lh-12 p-r-10' >
                                                <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }} className='text-gold-short'>
                                                    <b>{username}</b>
                                                </Typography>
                                            </div>
                                            
                                            <div className='fs-16 lh-12 p-r-10' >
                                                <p className='fs-16 txt-right' style={{ wordBreak: "break-all" }}></p>
                                            </div>
                                            {/* 9M Mall */}
                                            <Button
                                                variant="contained"
                                                style={{
                                                    borderRadius: 10,
                                                    textTransform: 'uppercase',
                                                    fontWeight: 700,
                                                    marginRight: 10,
                                                    height: 44,
                                                }}
                                                // onClick={handleLoginClick}
                                                href={process.env.REACT_APP_9M_MALL_MEMBER_SIDE_URL}
                                                className='gradient-gold-btn'
                                            >
                                                {/* <img src='/images/logos/logo.png' alt='Logo' style={{ marginRight: 10 }} className='img-style' /> */}
                                                {t('button.emall')}
                                            </Button> 

                                            {/* SCANNER */}
                                            {
                                                !_.includes(HIDE_SCANNER_LOGO, getRouterFormal()) &&
                                                <div
                                                    onMouseEnter={handleMouseEnter}
                                                    onMouseLeave={handleMouseLeave}
                                                    onClick={handleScannerClick}
                                                >
                                                    <Button variant="contained"
                                                    style={{
                                                        height: 44,
                                                        minWidth: 44,
                                                        aspectRatio: 1 / 1,
                                                        marginRight: 10,
                                                        borderRadius: 10,
                                                    }}
                                                    className='gradient-gold-btn'
                                                >
                                                    <AiOutlineScan
                                                        className="img-style-scanner"
                                                        style={{
                                                            color: (_.includes(WITH_TRANSPARENT, checkPrivatePath?.['path']) || _.includes(WITH_TRANSPARENT, checkPublicPath?.['path']) || _.includes(WITH_TRANSPARENT, checkPublicRestrictedPath?.['path'])) && !changeColor
                                                                ? '#0F0F0F'
                                                                : '#0F0F0F'
                                                        }}
                                                    />
                                                </Button>
                                                </div>
                                            } 

                                            {/* LANGUAGE */}
                                            <div
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                                onClick={handleClick}
                                            >
                                                <Button variant="contained"
                                                    style={{
                                                        width: 123,
                                                        height: 44,
                                                        borderRadius: 10,
                                                        fontWeight: 700,
                                                    }}
                                                    className='gradient-gold-btn'
                                                >
                                                <GoGlobe
                                                    className="img-style"
                                                    style={{
                                                        marginRight: 10,
                                                        color: (_.includes(WITH_TRANSPARENT, checkPrivatePath?.['path']) || _.includes(WITH_TRANSPARENT, checkPublicPath?.['path']) || _.includes(WITH_TRANSPARENT, checkPublicRestrictedPath?.['path'])) && !changeColor
                                                            ? '#0F0F0F'
                                                            : '#0F0F0F'
                                                    }}
                                                />
                                                {i18n.language === 'en' ? t('general.en') : t('general.cn')}
                                                </Button>
                                            </div>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                onClick={handleClose}
                                                slotProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        mt: 1.5,
                                                        '&:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <MenuItem onClick={() => changeSystemLanguage('en')} >{t('general.en')}</MenuItem>
                                                <MenuItem onClick={() => changeSystemLanguage('cn')}  >{t('general.cn')}</MenuItem>
                                            </Menu>
                                        </div>
                                    </>
                            }
                        </Toolbar>
                    </AppBar>
                    :
                    null
            }
        </>
    )
});

TitleBar.defaultProps = {
    showTitle: true,
};

const useStyles = makeStyles(theme => ({
    outerThemeCircleDesign: {
        border: `1px solid #F0F0F0`, 
        borderRadius: 49, 
        width: 45, 
        height: 45, 
        aspectRatio: 1 / 1, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    avatarStyle: {
        height: 40,
        width: 40,
        flexShrink: 0,
        background: '#F0F0F0'
    },
    mstoreMobileDesign: {
        width: 45, 
        height: 45, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    }
}));

export default TitleBar;