import { resetCodeState, resetProfile } from '../actions';
import { API } from '../configs/AxiosConfig';
import { store } from '../configs/Store';

export const removeLoginAccess = () => {
    store.dispatch(resetCodeState());
    store.dispatch(resetProfile());
}

const setHeader = () => {
    const accessToken = store.getState().general.accessToken;
    const language = store.getState().general.language;
    if(accessToken){
        API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }else{
        delete API.defaults.headers.common['Authorization'];
    }
    API.defaults.headers.common['Language'] = language === 'en' ? 'en' : 'cn';
    API.defaults.headers.common['Site'] = 'Member';
    return true;
}

export const getUrl = async (url, param) => {
    setHeader();
    let result = [];
    await API.get(url, {params: param})
    .then((response) => {
        result = response;
    }).catch((error) => {
        return Promise.reject(error.message);
    });
    return result;
}

export const postUrl = async (url, postData) => {
    setHeader();
    let result = [];
    await API.post(url, postData)
    .then((response) => {
        result = response;
    }).catch((error) => {
        return Promise.reject(error.message);
    });
    return result;
}

export const putUrl = async (url, putData) => {
    setHeader();
    let result = [];
    await API.put(url, putData)
    .then((response) => {
        result = response;
    }).catch((error) => {
        return Promise.reject(error.message);
    });
    return result;
}

export const deleteUrl = async (url) => {
    setHeader();
    let result = [];
    await API.delete(url)
    .then((response) => {
        result = response;
    }).catch((error) => {
        return Promise.reject(error.message);
    });
    return result;
}