import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { currencyFormat } from '@utils/Tools';

const DialogTradeDetails = (props) => {
    const { reservedHistroy = [] } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const totalSum = reservedHistroy.reduce((sum, item) => sum + (Number(item.price) || 0), 0);

    return (
        <div className='w-full'>
            <Grid
                container
                className='p-lr-20 p-tb-30 flex-c-m m-t-30 gradient-gold-border'
                style={{
                    borderRadius: 8,
                    position: 'relative',
                    maxHeight: '80vh',
                    overflow: 'auto'
                }}
            >
                <p className='fs-18 txt-center text-gold-short'><b>{t('title.reservedValueHistory')}</b></p>

                <div className='bgblack divider-list-black m-tb-10 w-full'/>

                <div className={classes.contentContainer}>
                    {reservedHistroy.length === 0 ? (
                        <Grid
                            container
                            className={classes.noDataContainer}
                            style={{ textAlign: 'center', padding: '20px'}}
                        >
                            <Grid item xs={12}>
                                <Typography style={{ color: '#787878', fontWeight: 400, fontSize: 20 }}>
                                    {t('No data available')}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        reservedHistroy.map((item, index) => (
                            <Grid
                                container
                                key={index}
                            >
                                <Grid item xs={6}>
                                    <Typography style={{fontWeight: 400, fontSize: 17 }}>
                                        {formatDate(item.created_at)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <Typography style={{fontWeight: 400, fontSize: 17, fontStyle: 'italic' }}>
                                        ${currencyFormat(Number(item.price) || 0, 2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))
                    )}

                    {reservedHistroy.length > 0 && (
                        <>
                        <div className='bgblack divider-list-black m-tb-10 w-full'/>
                        <Grid container>
                            <Grid item xs={6}>
                                <p className='fs-20 text-gold-short' style={{fontWeight: 700}}>
                                    {t('Total')}
                                </p>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <p className='fs-20 text-gold-short' style={{fontWeight: 700}}>
                                    ${currencyFormat(totalSum, 2)}
                                </p>
                            </Grid>
                        </Grid>
                        </>
                    )}
                </div>
            </Grid>
        </div>
    );
}

export default DialogTradeDetails;

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        overflowY: 'auto',
        flexGrow: 1,
        maxHeight: '60vh',
    },
    totalItem: {
        padding: '10px 0',
        marginTop: '10px',
        fontWeight: 'bold',
    },
}));
