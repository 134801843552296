import _ from 'lodash';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BonusItem from '@components/BonusItem';

const BonusListing = props => {
    const { listing } = props;
    const { t, i18n } = useTranslation();

    const bgColors = [
        'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box',
        'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box',
        'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box',
        'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box',
    ];

    return (
        <Grid container spacing={2} style={{ padding: '20px 0', margin:0, width:'100%' }}>
            {_.size(listing) > 0 ? (
                _.map(listing, (data, key) => {
                let setBgIndex = key % 4;
                let bonusBgColor = bgColors[setBgIndex];
                return (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={key} style={{ margin:'13px 0', width: '100%', padding:0 }}>
                    <BonusItem
                        bonusName={data?.bonus_type}
                        bonusCode={data?.bonus_type}
                        bonusValue={parseFloat(data?.total_amount)}
                        bonusDecimal={2}
                        bonusType={data?.name?.[i18n.language] ? data?.name?.[i18n.language] : data?.name?.cn}
                        background={bonusBgColor}
                        labelColor={setBgIndex === 0 ? '#000000' : '#ffffff'}
                        urlLink={`/bonus?b=${btoa(data?.id)}`}
                    />
                    </Grid>
                );
                })
            ) : (
                <div className='flex-c-m w-full flex-col'>
                        <div>
                            <img src={'/images/general/filter-search-empty-data.png'} width={250} alt="No data" loading='lazy' />
                        </div>
                        <p className='txt-empty'>{t('general.noBonusData')}</p>
                    </div>
            )}
        </Grid>

    );
}

export default BonusListing;