import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

const SpecialTransferItem = props => {
    const {passiveLogo, passiveCode, passiveName, totalValue, currentPrice, totalUnit } = props;
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Grid
            container
            alignItems="center"
            style={{
                display: 'flex',
                flexWrap: mobileView ? 'wrap' : 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: mobileView ? 'column' : 'row',
            }}
        >
            <Grid item style={{ borderRadius: 8, padding: 10 }}>
                <img
                    src="/images/trade/passive_unit_transfer.png"
                    alt={`Default Passive Logo`}
                    style={{ maxWidth: '110px', maxHeight: '110px' }}
                />
            </Grid>
            {/* passive name, total value, and current price */}
            <Grid item style={{ marginLeft: mobileView ? '0' : '16px', textAlign: mobileView ? 'center' : 'initial' }}>
                <Typography style={{ color: theme.palette.gray.main, fontSize: '27px', fontWeight: 700, lineHeight: '1.8rem' }}>
                    {t('spend.passiveUnit')}
                </Typography>
                <Typography style={{ fontSize: '21px', fontWeight: 700 }}>
                    <span style={{ color: theme.palette.gray.main, fontSize: '25px' }}>
                        {totalUnit || 0} {t('general.unit').toUpperCase()}
                        
                    </span>
                    {' '}
                    <span style={{ color: theme.palette.darkGray.main, fontStyle: 'italic' }}>
                        ≈
                    </span>
                    {' '}
                    <span style={{ color: theme.palette.darkGray.main, fontStyle: 'italic' }}>
                    ${totalValue || 0.00}
                    </span>
                </Typography>
                <Typography style={{ color: theme.palette.darkGray.main, fontSize: '12px', fontWeight: 400, fontStyle: 'italic' }}>
                    {t('trade.currentPrice')}: ${(currentPrice.toFixed(3)) || 0}
                </Typography>
            </Grid>
        </Grid>
    );
};

// DEFINE PROP TYPES
SpecialTransferItem.propTypes = {
    totalUnit: PropTypes.number,
    totalValue: PropTypes.string,
    currentPrice: PropTypes.number,
};

// DEFINE DEFAULT PROPS VALUE
SpecialTransferItem.defaultProps = {
    totalUnit: 0,
    totalValue: 0.00,
    currentPrice: 0.000,
};

export default SpecialTransferItem;