import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUrl, deleteUrl, putUrl } from '@utils/ApiAction';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';

import { Typography, Button, Box, Grid, IconButton } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { FiPlus, FiMoreVertical } from "react-icons/fi";



import { storePaths } from '@utils/Tools';

const Address = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [addressInfo, setAddressInfo] = useState([]);
    const { addAlert, setLoading } = useNotificationLoading();
    
    useEffect(() => {
        callApi();
    }, []);

    const callApi = () => {
        setLoading(true);
        getUrl(`/user/addressList`).then(response => {
            setLoading(false);
            if (response.status) {
                setAddressInfo(response.data);
            } else {
                addAlert('', t('error.contactSupport'), 'error', '');
            }
        })
        // eslint-disable-next-line
    };

    const handleEdit = (address) => {
        navigate(`/account/address/details/${address.id}`, { state: { from: storePaths(location) } });
    }

    const handleAddAddress = () => {
        navigate(`/account/address/details`, { state: { from: storePaths(location) } });
    }

    // soft delete address
    // const handleDelete = (address) => {
    //     setLoading(true);

    //     deleteUrl(`/user/address/${address}`).then(response => {
    //         setLoading(false);
    //         if (response.status) {
    //             addAlert('', t('success.deleteSuccess'), 'success', '');
    //             callApi();
    //         } else {
    //             addAlert('', response?.message || t('error.deleteError'), 'error', '');
    //         }
    //     }).catch(error => {
    //         setLoading(false);
    //         addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //     });
    // };
    const handleDelete = (addressId) => {
        setLoading(true);
    
        deleteUrl(`/user/address/${addressId}`).then(response => {
            setLoading(false);
    
            if (response.status) {
                addAlert('', t('success.deleteSuccess'), 'success', '');
    
                const newDefaultAddress = addressInfo.find(address => address.default === 1);

                if (newDefaultAddress) {
                    findNewDefaultAddress(addressId);
                }
    
                callApi();
            } else {
                addAlert('', response?.message || t('error.deleteError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    };
    
    const findNewDefaultAddress = (deletedAddressId) => {
        const newDefaultAddress = addressInfo.find(address => address.id !== deletedAddressId);
    
        if (newDefaultAddress) {
            putUrl(`/user/set-default/${newDefaultAddress.id}`).then(response => {
                if (response.status) {
                    setAddressInfo(prevAddressInfo => ({
                        ...prevAddressInfo,
                        addresses: prevAddressInfo.map(address => ({
                            ...address,
                            default: address.id === newDefaultAddress.id ? 1 : 0,
                        })),
                    }));
                } else {
                    addAlert('', response?.message || t('error.setDefaultError'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    };    

    return (
        <div className='dashboard-container '>

            <div className='flex-sb-m p-b-30'>
                <Typography variant='h6' style={{ fontWeight: 'bold', lineHeight: 1.1 }} >{t('title.address')}</Typography>
                <Button
                    size="large"
                    variant="contained"
                    className="big-button"
                    onClick={() => handleAddAddress()}
                >
                    <FiPlus className='m-r-5 fs-21'/>
                    {t('button.addAddress')}
                </Button>
            </div>

            <Grid container spacing={3}>
                {Array.isArray(addressInfo) && addressInfo.length > 0 ? (
                    addressInfo.map(address => (
                        <Grid item xs={12} lg={6} key={address.id}>
                            <Box key={address.id} className={`${address.default === 1 ? 'box-selected' : ' box-default'} p-all-20  pos-relative  btn-animate`} style={{overflow:'hidden'}}>
                                <div className='flex-sb' style={{alignItems:'flex-start'}}>
                                    <div className='w-full p-r-15'>
                                            <p className={address.default === 1 ? 'clwhite' : 'txt-theme'}><b>
                                                {address.user_name && address.user_mobile ? (
                                                    <>
                                                        {address.user_name} | {address.user_mobile}
                                                    </>
                                                ) : (
                                                    address.user_name || address.user_mobile
                                                )}
                                            </b>
                                            </p>
                                            <div className={address.default === 1 ? 'txt-lbrown' : 'txt-lgray'} >
                                                <p>{address.address_name}</p>
                                                <p>{address.full_address}</p>
                                            </div>
                                    </div>
                                    
                                    <div className='flex-r-m' style={{minWidth:'30%'}}>
                                        {/* {address.default === 1 &&
                                            <div>
                                                <Typography variant="body2">
                                                    {t('user.default')}
                                                </Typography>
                                            </div>
                                        } */}
                                        <div className={address.default === 1 ? 'btn-ver-white' : 'btn-ver-brown'}>
                                        <FiMoreVertical className={`${address.default === 1 ? 'txt-theme' : 'clwhite'} fs-21`}/>
                                        </div>
                                    
                                    </div>
                                </div>
                                    <div className='w-full h-full pos-absolute flex-c-m clwhite menu-animate'>
                                        <div className='flex-sa-m w-full '>
                                            <div className='txt-center'>
                                                <div className='btn-icon-round' onClick={() => handleEdit(address)}>
                                                    <EditIcon />
                                                </div>
                                                <p>{t('button.edit')}</p>
                                            </div>
                                            <div className='txt-center'>
                                                <div className='btn-icon-round' onClick={() => handleDelete(address.id)}>
                                                    <DeleteIcon />
                                                </div>
                                                <p>{t('button.delete')}</p>
                                            </div>
                                        </div>
                                    </div>
                            </Box>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 400 }}>
                            <div style={{ width: 200 }}>
                                <img src={'/images/general/no-address.png'} className='s-full' alt="No data" loading='lazy' />
                            </div>
                            <div style={{ textAlign: 'center', paddingTop: 20, color: 'black' }}>
                                <Typography variant="body1">(t{'general.noAddressData'})</Typography>
                            </div>
                        </div>
                    </Grid>
                )}
            </Grid>

        </div>
    )
}

const useStyles = makeStyles(theme => ({
    titleBox: {
        backgroundImage: `url('../images/profile/mountain_bg.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        textAlign: "center",
        paddingBottom: 60
    },
    textFieldBox: {
        marginBottom: 20
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        // maxWidth: 444,
        overflow: 'hidden',
        margin:'0 auto',
        borderRadius:'30px 30px 0px 0px'
    },
    // addressBox: {
    //     borderRadius: 10,
    //     padding:20,
    //     boxShadow: '-3px -3px 4px 0px #FFF inset, 2px 2px 3px 0px rgba(0, 0, 0, 0.10) inset',
    //     backgroundColor:'#eee'
    // },
    defaultBox: {
        border: '1px solid '+theme.palette.primary.main,
        borderRadius: 6,
        padding: "4px 14px",
        color: theme.palette.primary.main,
    }
}));

export default Address