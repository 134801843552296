import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import { Typography, Grid, Box, ThemeProvider, Stack, Pagination, Hidden, createTheme, useMediaQuery, Button } from '@mui/material';
import { makeStyles, useTheme, styled } from '@mui/styles';
import { Lock, LockOpen, Add } from '@mui/icons-material';

import { getUrl } from '@utils/ApiAction';
import { storePaths, currencyFormat } from '@utils/Tools';
import PropTypes from 'prop-types';
import useNotificationLoading from '@utils/useNotificationLoading';
import PlanHistoryItem from '@components/PlanHistoryItem';
import ChartData from '@components/ChartData';
import AllButton from '@components/AllButton';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Grid item xs={12} lg={6} className='w-full'
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </Grid>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const RedeemCodeHistory = () => {
    const { accessToken } = useSelector((state) => state.general);
    const { id } = useSelector((state) => state.user);
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    // const scrollableDivRef = useRef(null);
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const scrollableDivRef = document.getElementById('main');
    let navigate = useNavigate();
    let location = useLocation();

    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [planHistories, setPlanHistories] = useState([]);
    const [allPlanHistories, setAllPlanHistories] = useState({});
    const [statusList, setStatusList] = useState({});
    const [chartData, setChartData] = useState([]);
    const [activePlans, setActivePlans] = useState({});
    const [isHovered, setIsHovered] = useState(false);
    const [statusFilter, setStatusFilter] = useState('all');
    // const [lockView, setLockView] = useState([false, false]);

    const filters = [
        {title: t('button.all'), value: 'all'},
        {title: t('button.completed'), value: 'completed'},
        {title: t('button.pending'), value: 'pending'},
        {title: t('button.cancelled'), value: 'cancelled'},
        {title: t('button.expired'), value: 'expired'},
    ];

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [page, t, statusFilter]);


    const handleScroll = () => {
        const scrollTop = scrollableDivRef.scrollTop;
        const scrollHeight = scrollableDivRef.scrollHeight;
        const clientHeight = scrollableDivRef.clientHeight;

        if (Math.ceil(scrollHeight - scrollTop) <= (clientHeight + 10)) {
            setPage(page + 1);
        }
    }

    const callApi = () => {
        setLoading(true);
        isMountedRef.current = true;
        let apiData = {
            status: statusFilter,
            per_page: 10,
            page: page,
        };

        getUrl(`/active-plans-histories-with-code/${id}`, apiData).then((response) => {
            setLoading(false);
            if (response && isMountedRef.current) {
                let { data, current_page, last_page } = response.data.listing;
                setTotal(last_page);
                setCurrPage(current_page);
                setPlanHistories(data);
                setStatusList(response.data.status_list);

                if (statusFilter === 'all') {
                    setAllPlanHistories(response.data.all_plan_histories);
                }

                // Infinite Scroll
                if (mobileView && scrollableDivRef) {
                    if (scrollableDivRef && page < last_page) {
                        scrollableDivRef.addEventListener('scroll', handleScroll);
                    }
                    if (page > 1) {
                        setPlanHistories(_.concat(planHistories, data));
                    } else {
                        setPlanHistories(data);
                    }
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        return () => { 
            isMountedRef.current = false; 
            scrollableDivRef.current.removeEventListener('scroll', handleScroll);
        }
    }

    const handleMouseEnter = () => {
        setIsHovered(true);
        // Perform any actions when the mouse enters the div
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        // Perform any actions when the mouse leaves the div
    };

    const onChangePage = (page) => {
        setPage(page);
    }

    const handleChangeStatusFilter = value => {
        setStatusFilter(value);
        setPage(1);
        // setPlanHistories({});
    };

    // Function to toggle the value of lockView[key]
    // const toggleLock = (key) => {
    //     setLockView(prevState => ({ ...prevState, [key]: !prevState[key] }));
    // };

    return (
        <>
            <div className='dashboard-container'>
                <div>
                   <div className='w-full flex-sa-m'>
                        <Hidden mdDown>
                            <div className='max-w-full' style={{ lineHeight: 0, width:'20%', minWidth:100 }}><img src='/images/design/redeem-l.png' alt="design" className='w-full' /></div>
                        </Hidden>
                        <div className='max-w-full' style={{ width: 600, margin: '0 auto' }}>
                            <div className='gradient-gold-bg flex-sb-m w-full bor-15 p-tb-8 p-lr-20 pos-relative' style={{ boxShadow: '#00000061 0px 6px 3px 0px', zIndex: 1 }}>
                                <div className='flex-m'>
                                    <div style={{ width: 40 }}>
                                        <img src='/images/logos/logo-black.png' alt="xiami" className='w-full' />
                                    </div>
                                    <div className='p-l-10'>
                                        <p className='lh-12'><b>{t('plan.redeemCodeUseLink')}</b></p>
                                        <Hidden smDown><p className='lh-12 fs-14 p-t-5'>{t('plan.clickToRedeem')} !</p></Hidden>
                                        {/* <p>You have <b>5</b> code ready to redeem.</p> */}
                                    </div>
                                </div>
                                <a href={process.env.REACT_APP_REDEEM_URL} target="_blank" rel="noopener noreferrer">
                                    <div className='bg-base p-tb-10 p-lr-15 pointer translateX' style={{ borderRadius: '30px 8px 30px 8px' }}>
                                        <p className='txt-center txt-theme'>Redeem</p>
                                    </div>
                                </a>
                            </div>
                            <div style={{ border: '2px solid #eed189', borderRadius: '0 0 15px 15px', borderTop: 'none', lineHeight: 0, height: 11, width: '95%', margin: '0 auto' }}></div>
                        </div>
                        <Hidden mdDown>
                            <div className='max-w-full' style={{ lineHeight: 0, width:'20%', minWidth:100  }}><img src='images/design/redeem-r.png' alt="design" className='w-full' /></div>
                        </Hidden>
                    </div>
                    
                <div className='w-full flex-sb-m p-t-50'>
                    <p className='text-gold'>
                        {t('planHistory.title')}
                    </p>

                    {/* filter the status */}
                    <AllButton filters={filters} handleChange={handleChangeStatusFilter} />
                </div>

                <Grid container spacing={4} style={{ paddingTop: 20,}}>
                    {
                        planHistories.length > 0 ? (
                            planHistories.map((history, key) => (
                                <Grid item xs={12} lg={6} key={history.id}>
                                    <Box>
                                        <PlanHistoryItem
                                            history={history}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </Box>
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12} className={styles.endFontStyle} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src="images/general/filter-search-empty-data.png" alt="" style={{ marginBottom: '10px', width: 145 }} loading='lazy' />
                                <p className='txt-empty'>
                                    {t('table.noTransactionYet')}
                                </p>
                            </Grid>
                        )
                    }

                    <Hidden lgDown>
                        {
                            planHistories.length > 0 &&
                            <Grid item xs={12} className='p-t-10'>
                                <ThemeProvider theme={paginationStyle}>
                                    <Stack spacing={2}>
                                        {
                                            loading
                                                ? null
                                                : (<Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />)
                                        }
                                    </Stack>
                                </ThemeProvider>
                            </Grid>
                        }
                    </Hidden>

                    {
                        planHistories.length > 0 && mobileView && (
                            <Grid item xs={12} className={styles.endFontStyle}>
                                <Typography style={{ color: '#F0F0F0' }}>
                                    {currPage === total ? t('table.endOfTheList') : t('table.loadingList') }
                                </Typography>
                            </Grid>
                        )
                    }
                </Grid>
               </div>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    walletBalanceLayout: {
        display: 'flex',
        alignItems: 'flex-end',
        // backgroundImage: `url('images/wallets/accumulate_background.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        width: '100%',
        minHeight: 40,
        justifyContent: 'space-between',
    },
    colorGray: {
        color: theme.palette.lightGray.main
    },
    endFontStyle: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
}));

const paginationStyle = createTheme({
    components: {
        // Name of the component
        MuiPagination: {
            styleOverrides: {
                // Name of the slot
                ul: {
                    // Some CSS
                    justifyContent: 'flex-end',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#eed189',
                },
            },
        },
    }
});

const CustomDiv = styled('div')({
    paddingTop: 30,
    '@media (min-width: 900px)': {
        paddingTop: 80,
    },
});

export default RedeemCodeHistory;