import _ from 'lodash';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Avatar, Button, Dialog, DialogContent, Grid, TextField, Typography, Tab, Tabs, Box, Hidden, FormControl, Select, MenuItem } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FaChevronDown } from "react-icons/fa";

import { storeMaxRank, storeProfile } from '@actions';
import { getUrl, putUrl } from '@utils/ApiAction';
import { storePaths } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

export default function UserBasicInfoCard({ isGenealogyPage }) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let location = useLocation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { username, profile_image, max_rank, email, mobile } = useSelector(state => state.user);
    const maxRankName = max_rank ? max_rank.name[i18n.language] : null;
    const [dialogData, setDialogData] = useState({
        open: false,
        mobile: '',
    })
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    const [qrDialogOpen, setQrDialogOpen] = useState(false);
    const [position, setPosition] = useState('auto');

    useEffect(() => {
        getUrl(`/user/get-max-rank`).then(response => {
            if (response.status) {
                dispatch(storeMaxRank(response.maxRank));
            }
        });
        // eslint-disable-next-line
    }, []);

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } })
    };

    const handleDialogOpen = (event) => {
        event.stopPropagation();
        setDialogData({
            open: true,
            mobile: ''
        });
    }

    const handleDialogClose = () => {
        setInputErrors();
        setDialogData({
            open: false,
            mobile: ''
        });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let apiData = {
            email: email,
            mobile: dialogData.mobile,
        }
        putUrl(`/user`, apiData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                addAlert('', t('success.editSuccess'), 'success', '');
                handleDialogClose();
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleQrDialogOpen = () => {
        setQrDialogOpen(true);
    }

    const handleQrDialogClose = () => {
        setQrDialogOpen(false);
    }

    let positionData = [
        {label: t('general.auto'), value: "auto"},
        // {label: t('general.left'), value: "1"},
        // {label: t('general.right'), value: "2"},
    ]

    return (
        <>
            <div 
                className={`flex-m ${isGenealogyPage ? 's-full' : 'w-full m-b-30'} p-tb-20 p-lr-20 gradient-gold-border pos-relative`}>
                <div>
                    {
                        profile_image ?
                            <div className={`${styles.avatarOutlineStyle} flex-c-m`} >
                                <Avatar className={`${styles.avatarStyle} gradient-gold-bg`} src={profile_image?.file_name} />
                            </div>
                            :
                            <div className={`${styles.avatarOutlineStyle} flex-c-m`} >
                                <Avatar className={`${styles.avatarStyle} gradient-gold-bg`}>
                                    <Typography variant='h4' className='txt-upper' style={{ color: theme.palette.secondary.main }}>{username ? username.substring(0, 1) : '-'}</Typography>
                                </Avatar>
                            </div>
                    }
                </div>
                <div className='fs-16 lh-12 p-l-25'>
                    <p className='fs-21 text-gold-short' style={{ wordBreak: "break-all" }}><b>{username}</b></p>
                    <Typography>{maxRankName || t('user.guest')}</Typography>
                    {/* <Typography style={{ wordBreak: "break-all" }}>{email}</Typography> */}
                    {
                        mobile
                            ?
                            // <p style={{ wordBreak: "break-all", color: '#F0F0F0' }}>{mobile}</p>
                            null
                            :
                            (
                                isGenealogyPage 
                                ? 
                                null 
                                : 
                                <Button 
                                    variant="contained" 
                                    className='small-button' 
                                    style={{ 
                                        marginTop: 5 , 
                                    }}
                                    onClick={handleDialogOpen}
                                >
                                    { t('profile.completeProfile') }
                                </Button>
                            )
                    }
                </div>
                {/* <div className='pos-absolute' style={{ right: 0, top: 0, cursor: "pointer" }}>
                    <div onClick={handleQrDialogOpen}>
                        <img src='/images/dashboard/qr.png' alt="qrcode" style={{ width: 70 }} />
                    </div>
                </div> */}
            </div>

            <Dialog open={dialogData.open} onClose={handleDialogClose}>
                <DialogContent style={{background: '#F0F0F0'}}>
                    <div style={{ margin: 10 }} className='p-t-50 p-b-40 p-lr-20'>
                        <div className='txt-center lh-11'>
                            <Typography 
                                variant='h5' 
                                style={{
                                    color: theme.palette.secondary.main,
                                }}
                            >
                                <b>
                                    {t('profile.completeProfile')}
                                </b>
                            </Typography>
                            <Typography variant='body1' style={{ color: "gray" }}>
                                {t('profile.completeProfileNote')}
                            </Typography>
                        </div>
                        <div className='flex-c-m p-t-30'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        name='mobile'
                                        placeholder={t('user.mobile')}
                                        onChange={(event) => setDialogData({ ...dialogData, mobile: event.target.value })}
                                        required
                                        helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                        error={inputErrors && inputErrors.mobile ? true : false}
                                        InputLabelProps={{ shrink: true }}
                                        value={dialogData.mobile || ''}
                                        disabled={mobile ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} style={{cursor: "pointer" }}>
                                    <Button 
                                        variant="contained" 
                                        onClick={submitData} 
                                        className='big-button' 
                                        fullWidth 
                                        disabled={!dialogData.mobile}
                                    >
                                        {t('button.update')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={qrDialogOpen} onClose={handleQrDialogClose} >
                <DialogContent style={{ backgroundColor: '#f0f0f0' }}>
                    <div className='p-tb-20 p-lr-10'>
                        {/* <Hidden mdDown>
                            <Tabs
                                value={position}
                                onChange={(event, newValue) => setPosition(newValue)}
                                variant="fullWidth"
                                style={{ 
                                    margin: "15px 0px",
                                    zIndex: 1, 
                                    position: 'relative',
                                    backgroundColor: '#E7E7E7'
                                }}

                            >
                                    {
                                        _.map(positionData, (detail, key) => {
                                            return (
                                                <Tab key={key} label={detail.label} value={detail.value} />
                                            )
                                        })
                                    }
                            </Tabs>
                        </Hidden> */}

                        {/* <Hidden mdUp>
                            <div style={{ padding: 0, width: '100%' }}>
                                <FormControl fullWidth className='dis-flex flex-m'>
                                    <Select
                                        variant='standard'
                                        value={position}
                                        MenuProps={{ classes: { paper: styles.listRoot } }}
                                        onChange={(event) => setPosition(event.target.value)}
                                        className={`txt-upper txt-center ${styles.selectBox}`}
                                        style={{ background: 'transparent' }}
                                        disableUnderline
                                        IconComponent={() => (
                                            <FaChevronDown className={styles.dropdownIcon} />
                                        )}
                                    >
                                        {
                                            _.map(positionData, (detail, key) => (
                                                <MenuItem key={key} value={detail.value} className='txt-upper' style={{ color: theme.palette.primary.main, fontSize: 14 }}>{detail.label}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </Hidden> */}
                        
                        {/* Qr Code */}
                        <div style={{ margin: 10 }}>
                            {/* QRCode */}
                            <Box style={{ marginBottom: 20 }}>
                                <div className='flex-c'>
                                    <div className='gradient-gold-border p-all-10 bor-15'>
                                        <QRCode
                                            size='178'
                                            value={`${window.location.origin}/register?r=${btoa(username)}`}
                                            renderAs="svg"
                                            fgColor={'#fff'}
                                            bgColor={'transparent'}
                                        />
                                    </div>
                                </div>
                            </Box>
                            <div className='flex-col-c'>
                                {/* Username */}
                                <div className='lh-12 p-b-10'>
                                    <p className='fs-21 text-gold-short'><b>{username}</b></p>
                                    <p className='txt-content txt-center'>{t('user.rank')}{maxRankName || t('user.guest')}</p>
                                </div>
                                {/* Rank */}
                                <div className='txt-content txt-center'>
                                    <p>{email}</p>
                                    <p>{mobile}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    avatarStyle: {
        width: '100%',
        height: '100%',
        flexShrink: 0,
        // background: '#eed189'
    },
    avatarOutlineStyle: {
        borderRadius: 48,
        border: '2px solid',
        borderColor: '#eed189',
        padding: 3,
        width: 75,
        height: 75
    },
    dialogBackground: {
        backgroundImage: `url('../images/profile/mountain_bg.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        textAlign: "center",
        margin: "40px 0px",
        padding: "0px 30px"
    },
    listRoot: {
        backgroundColor: '#fff',
    },
    selectBox: {
        width: '80%',
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: 14,
        height: 40,
        boxShadow: 'none',
        padding: 0,
    },
    dropdownIcon: {
        pointerEvents: 'none',
        position: 'absolute',
        right: 0
    },
}));